import { useEffect, useState } from 'react';
import React from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedPaymentMethod } from 'actions';
import lodash from 'lodash';
import SelectPaymentMethod from './SelectPaymentMethod';

const styles = theme => ({
  mainDiv: {
    display: 'flex',
    marginBottom: "2px"
  },
  chip: {
    fontSize: 10,
    height: "25px",
    marginTop: '7px'
  },
  buttonContent: {
    display: "flex",
    fontSize: "16px",
    justifyContent: "center",
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  labelText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "12px",
    color: theme.palette.secondary.dark,
    marginLeft: "5px",
    width: "80px",
  },
});

function PaymentMethod(props) {
  const dispatch = useDispatch();
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const paymentMethods = selectedFiatCurrency?.payment_methods ? JSON.parse(JSON.stringify(selectedFiatCurrency?.payment_methods)) : [];
  const isSmallDevice = useSelector(state => state.isSmallDevice);
  const [selectedMethod, setSelectedMethod] = useState(selectedPaymentMethod.cfpm_id);
  const hideBottomPaddingGrid = props.hideBottomPaddingGrid;
  const moreCountBottomMargin = hideBottomPaddingGrid ? "0" : "21px";

  useEffect(() => {
    if (!selectedPaymentMethod?.cfpm_id) {
      dispatch(setSelectedPaymentMethod(paymentMethods[0]));
    }
  }, [dispatch, paymentMethods, selectedPaymentMethod, selectedPaymentMethod.payment_methods]);

  const handleSelection = (selectedMethod) => {
    setSelectedMethod(selectedMethod);
    const method = lodash.find(paymentMethods, function (item) {
      return item.cfpm_id === selectedMethod;
    });
    dispatch(setSelectedPaymentMethod(method));
  }

  let topPaymentMethods = [paymentMethods[0]];
  let moreCount = 0;
  if (paymentMethods?.length > 3) {
    topPaymentMethods.push(paymentMethods.slice(1, 2)[0]);
    topPaymentMethods.push(paymentMethods.slice(2, 3)[0]);
    moreCount = paymentMethods.length - 3;
  } else {
    topPaymentMethods = JSON.parse(JSON.stringify(paymentMethods));
  }

  if (isSmallDevice && topPaymentMethods.length === 3) {
    topPaymentMethods.splice(-1);
    moreCount++;
  }

  if (selectedMethod) {
    const method = lodash.find(topPaymentMethods, function (item) {
      return item.cfpm_id === selectedMethod;
    });
    if (!method) {
      const methodToPush = lodash.find(paymentMethods, function (item) {
        return item.cfpm_id === selectedMethod;
      });
      topPaymentMethods.splice(-1);
      topPaymentMethods.push(methodToPush);
    }
  }

  return (
    <Box>
      <Grid container className={props.classes.mainDiv} spacing={3}>
        {topPaymentMethods.map((method) => (
          <React.Fragment key={method.cfpm_id}>
            <Grid item xs={3} sm={3} md={3} lg={3} key={1}>
              <Button key={method.cfpm_id} color="primary" label={method.name} style={{ borderRadius: "8px", borderColor: "#cfd4d6", backgroundColor: selectedMethod !== method.cfpm_id ? "#fff" : "#E5EBFA" }} variant={"outlined"} value={method.cfpm_id} onClick={() => {
                handleSelection(method.cfpm_id);
              }}>
                <span className={props.classes.buttonContent}>
                  {method.image ? <img alt={method.name} src={method.image} width="20px" height="20px" /> : null}
                  <Typography variant="subtitle2" className={props.classes.labelText} noWrap>{method.name}</Typography>
                </span>
              </Button>
            </Grid>
            <Grid item xs={isSmallDevice ? 2 : 1} sm={1} md={1} lg={1} key={2}></Grid>
          </React.Fragment>
        ))}
      </Grid>
      {
        moreCount > 0 ?
          <Grid item key={3} style={{ marginBottom: moreCountBottomMargin }}><SelectPaymentMethod heading={`${moreCount} More Payment ${moreCount === 1 ? `Method` : `Methods`}`} possibleValues={paymentMethods} selectedValue={selectedMethod} handleSelection={handleSelection} /></Grid>
          : (hideBottomPaddingGrid ? null : <Grid style={{ height: "40px" }}></Grid>)
      }
    </Box>
  )

}

export default withStyles(styles)(PaymentMethod);
