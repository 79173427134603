import { withStyles } from '@material-ui/styles';
import { Box, Button, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomFormButton from 'widgets/CustomFormButton';
import FormHeader from 'widgets/FormHeader';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PaymentService from 'lib/services/PaymentService';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';

const styles = theme => ({
  mainDiv: {
    padding: '1em',
    paddingTop: '1em',
    paddingBottom: '0em',
    width: '100%',
  },
 
  emptyOrderText: {
    fontSize: "18px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.main
  },
  emptyOrderTextGrid: {
    justifyContent: "center",
    marginTop: "45%",
    display: "flex"
  },
  emptyOrderButtonGrid: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    width: "100%",
    margin: "auto"
  },
  card: {
    width: "100%"
  },
  paymentMethodBox: {
    fontWeight: "bold",
    padding: "1em",
  },
  supportMessage: {
    display: "flex",
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  paymentButton: {
    marginTop: "10em",
    width: "100%",
  }
});


function ChooseFiatPaymentMethod(props) {

  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [paymentMethodListFetched, setPaymentMethodListFetched] = useState(false);
  const orderId = useSelector(state => state.orderId);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);

  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e.target.value);
  }

  useEffect(() => {
    if (paymentMethodList && paymentMethodList.length) {
      return;
    }

    PaymentService.getPaymentMethods(orderId)
      .then((data) => {
        const transferMethods = data?.response?.transferMethods || [];
        if (transferMethods?.length) {
          setPaymentMethodList(transferMethods);
        }
        setPaymentMethodListFetched(true);
      })
      .catch((err) => {
        setPaymentMethodListFetched(true);
      })
  });

  const handleAddNewClick = () => {
    props.history.push("/ptcreditcardadd");
  };

  const handleBackButton = () => {
    props.history.goBack();
  }

  const handlePaymentClick = () => {
    if (httpCallInProgress) {
      return;
    }
    if (selectedPaymentMethod) {
      setHttpCallInProgress(true);
      props.history.push("/ptcreditcardcharge", {paymentMethodId: selectedPaymentMethod});
    }
  }

  const getCreditCardType = (type) => {
    let returnText = type;
    if (type === `MC`) {
      returnText = `Mastercard`;
    } else if (type === `VI`) {
      returnText = `Visa`;
    }

    return returnText;
  }

  return (
    <Box className={props.classes.mainDiv}>
      <Grid container direction="column" style={{height: "33em"}}>
        <Grid item>
          <FormHeader customHandleBackButton={handleBackButton} titleText={`Credit/Debit Cards`} history={props.history} />
        </Grid>
        <Grid item style={{ height: "20px" }}>

        </Grid>
        <Grid item>
          <Box className={props.classes.paymentMethodBox} overflow="auto">
            {paymentMethodList && paymentMethodList.length ?
              <Grid container direction="column">
                <Grid item>
                  <FormControl>
                    <Typography style={{ fontSize: "16px", fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",}}>Choose from existing cards or add a new one</Typography>
                    <RadioGroup
                      sx={{ marginTop: "30px" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={selectedPaymentMethod || ``}
                      onChange={handlePaymentMethodChange}
                    >
                      {paymentMethodList.map((method) => (
                        <FormControlLabel style={{marginBottom: "10px"}} value={method.id} key={method.id} control={<Radio />} label={<Card variant="outlined" style={{ borderRadius: "10px" }}>
                          <CardContent style={{ width: "330px" }}>
                            <Typography variant="caption" style={{ fontSize: "16px", fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif", }}>
                              {`${getCreditCardType(method.creditCardType)} ending in ${method.creditCardLast4}`}
                            </Typography>
                          </CardContent>
                        </Card>} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <Grid item className={props.classes.emptyOrderButtonGrid}>
                    <Button variant='outlined' onClick={handleAddNewClick}>Add a new card</Button>
                  </Grid>
                </Grid>
              </Grid>
              :
              paymentMethodListFetched ?
                <Grid container direction="column">
                  <Grid container style={{ justifyContent: "center" }}>
                    <Typography className={props.classes.supportMessage}>
                      Your saved cards will appear here.
                    </Typography>
                    <Typography className={props.classes.supportMessage} color="textSecondary">
                    </Typography>
                  </Grid>
                  {paymentMethodList && paymentMethodList.length ? <Grid item className={props.classes.emptyOrderTextGrid}>
                    <Typography className={props.classes.emptyOrderText} color="textSecondary">
                      Let's add a new card
                    </Typography>
                  </Grid> : null}
                  <Grid item className={props.classes.emptyOrderButtonGrid}>
                    <CustomFormButton onClick={handleAddNewClick} customButtonStyle={{ height: "100%", borderRadius: "10px", color: "black", backgroundColor: "#E5EBFA" }} buttonText="Add a new card" />
                  </Grid>
                </Grid>
                :
                <div></div>
            }
          </Box>
        </Grid>
      </Grid>
      {paymentMethodList && paymentMethodList.length ?
        <Box>
          {httpCallInProgress ? <CustomLinearProgressBar /> : null}
          <CustomFormButton onClick={handlePaymentClick} disabled={!selectedPaymentMethod} buttonText="Pay Now" />
        </Box> : null}
    </Box>
  )
}

export default withStyles(styles)(ChooseFiatPaymentMethod);
