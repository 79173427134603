import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import lodash from 'lodash';
import { useSelector } from 'react-redux';
import ApiConstant from 'lib/ApiConstant';
import OrderService from 'lib/services/OrderService';
import paymentCheckAnimationV2 from 'assets/animations/payment_check_v2.json';
import paymentSuccessAnimation from 'assets/animations/success.json';
import convertAnimation from 'assets/animations/convert.json';
import paymentRejectedAnimation from 'assets/animations/failed.json';
import CustomLottie from 'widgets/CustomLottie';
import CustomFormButton from 'widgets/CustomFormButton';
import PaymentService from 'lib/services/PaymentService';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';

const styles = theme => ({
  header: {
    display: 'flex',
    height: '70px',
    backgroundColor: '#fbfcf8',
    marginLeft: '-1.5em',
    marginRight: '-1.5em',
    marginTop: '-1em',
  },
  text: {
    fontSize: 13
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    marginTop: "15%",
  },
  mainText: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
    color: theme.palette.secondary.main
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "20px"
  }
});

function ConvertFiatToCrypto(props) {
  const [mainText, setMainText] = useState("We are checking your payment status. Please stay on this screen after attempting payment.");
  const [showConversionButton, setShowConversionButton] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("PENDING");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const apiCheckoutFlow = useSelector(state => state.apiCheckoutFlow);
  const priceDetails = useSelector(state => state.priceDetails);

  const orderId = useSelector(state => state.orderId);

  useEffect(() => {
    let intervalId = setInterval(function checkStatusIfTabInFocus() {
    OrderService.getOrderById(orderId)
      .then((response) => {
        const orderStatus = lodash.get(response, 'status');
        if ([`PAYMENT_SUCCESS`].indexOf(orderStatus) > -1 && response.convertIntoCrypto) {
          setMainText(`Payment processed successfully for ${response.fiat_currency?.symbol}${Number(response.fiat_currency?.amount)?.toFixed()}. You can now continue to complete your purchase of ${Number(priceDetails.cryptoCurrencyAmount)} ${response.crypto_currency?.code}.`);
          setPaymentStatus('ASK_FOR_FULFILMENT');
          setShowConversionButton(true);
          clearInterval(intervalId);
        } else if ([`COMPLETED`, `PROCESSING_CRYPTO`, `RELEASING_CRYPTO`, `PAYMENT_SUCCESS`].indexOf(orderStatus) > -1) {
          setMainText("Payment received successfully. We are initiating crypto transfer. " + (apiCheckoutFlow ? "You can close this window now." : "You can check the status of your order in the My Orders section."));
          setPaymentStatus('SUCCESS');
          clearInterval(intervalId);
        } 
        else if (orderStatus === `FAILED`) {
          setMainText("Your payment could not be completed.");
          setPaymentStatus('FAILED');
          setShowConversionButton(false);
          clearInterval(intervalId);
        }
      })
      .catch((e) => {
        clearInterval(intervalId);
        setMainText("We are still checking the status of your transaction in the backend. Please don't retry the payment yet.");
        setPaymentStatus('FAILED');
        setShowConversionButton(false);
      });
    }, ApiConstant.getOrder.pollingIntervalInMs);
  }, [apiCheckoutFlow, orderId, priceDetails.cryptoCurrencyAmount, props.history])


  function getPaymentLottie() {
    if (paymentStatus === `ASK_FOR_FULFILMENT`) {
      return (<CustomLottie
        style={{marginBottom: "30px"}}
        lottie={convertAnimation}
        loop={false}
        color={"black"}
        speed={2.0}
        width={80}
        height={80}
      />);
    } else if(paymentStatus === `SUCCESS`) {
      return (<CustomLottie
        lottie={paymentSuccessAnimation}
        loop={false}
        speed={2.0}
        width={100}
        height={100}
      />);
    } else if (paymentStatus === `FAILED`) {
      return (<CustomLottie
        lottie={paymentRejectedAnimation}
        loop={false}
        width={100}
        hieght={100}
      />);
    } else {
      return (<CustomLottie
        lottie={paymentCheckAnimationV2}
        loop={true}
        width={100}
        hieght={100}
      />);
    }
  }

  const handleOrderListClick = () => {
    props.history.replace("/orderlist", { callingPage: "CONVERT_FIAT_TO_CRYPTO" });
  };

  const handleConvertButtonClick = () => {
    setHttpCallInProgress(true);
    PaymentService.createCryptoPayment({order_id: orderId})
    .then(() => PaymentService.fulfilOrder({orderId}))
    .then((data) => {
      if (data) {
        props.history.push('/checkpayment', {
          mainTextFromCallingPage: `Processing your transaction. Please wait...`,
          successText: `Crypto transfer initiated. Your crypto should arrive in your wallet soon.`
        });
      }
    })
    .catch((e) => {
      setHttpCallInProgress(true);
    })
  }

  function getPaymentStatusAction() {
    if ((paymentStatus === `SUCCESS` || paymentStatus === `FAILED`) && !apiCheckoutFlow) {
      return (<CustomFormButton onClick={handleOrderListClick} buttonText="My Orders" />);
    } else {
      return (<div></div>);
    }
  }

  function getRedirectLink() {
    if (showConversionButton && (paymentStatus !== `SUCCESS` || paymentStatus !== `FAILED`)) {
      return (
        <Grid className={props.classes.mainText} style={{ marginTop: "35%" }}>
          <Grid item>
            <Typography variant="caption">

            </Typography>
            <Grid style={{ height: "10px" }}></Grid>
            {httpCallInProgress ? <CustomLinearProgressBar /> : null}
            <CustomFormButton onClick={handleConvertButtonClick} buttonText="Complete transaction" disabled={httpCallInProgress}/>
          </Grid>
        </Grid>
      );
    } else {
      return (<div></div>);
    }
  }

  return (
    <Box style={mainDivStyle}>
      <Grid container direction="column" className={props.classes.mainGrid}>
        <Grid item className={props.classes.lottie}>
          {getPaymentLottie()}
        </Grid>
        <Grid item>
          <span className={props.classes.mainText} style={ paymentStatus === `ASK_FOR_FULFILMENT` ? {color: "#000000", marginTop: "30px"} : {}}>{mainText}</span>
        </Grid>
        <Grid item className={props.classes.actionButton}>
          {getPaymentStatusAction()}
        </Grid>
        <Grid item className={props.classes.actionButton}>
          {getRedirectLink()}
        </Grid>
      </Grid>
    </Box>
  )
}

const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '1em',
}

export default withStyles(styles)(ConvertFiatToCrypto);
