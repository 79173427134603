import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import lodash from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import CurrenyService from '../../../lib/services/CurrencyService';
import { setCryptoAmountToSell, setFiatAmountForSell, setMinimumAmountError, setPriceDetailsForSell, setPriceParamChanged } from '../../../actions';

const styles = theme => ({
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
  },
});

function PriceFetch(props) {
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrencyForSell);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrencyToSell);
  const cryptoAmount = useSelector(state => state.cryptoAmountToSell);
  const fiatAmount = useSelector(state => state.fiatAmountForSell);
  const priceParamChanged = useSelector(state => state.priceParamChanged);
  const dispatch = useDispatch();

  useEffect(
    () => {
      const delayDebouncePriceFetch = setTimeout(() => {
        if (priceParamChanged === "CRYPTO_AMOUNT" || priceParamChanged === "CRYPTO_SELECT") {
          dispatch(setPriceDetailsForSell({}));
          dispatch(setMinimumAmountError({
            isError: false,
            errorText: "",
            minimumAmount: ""
          }));
          dispatch(setFiatAmountForSell(null));
          dispatch(setPriceParamChanged(""));
          return;
        }
        if (priceParamChanged === "FIAT_AMOUNT" || priceParamChanged === "FIAT_SELECT") {
          dispatch(setPriceDetailsForSell({}));
          dispatch(setMinimumAmountError({
            isError: false,
            errorText: "",
            minimumAmount: ""
          }));
          dispatch(setCryptoAmountToSell(null));
          dispatch(setPriceParamChanged(""));
          return;
        }
        if (!selectedFiatCurrency.fiat_code || !selectedCryptoCurrency.code || (cryptoAmount && fiatAmount) || (!fiatAmount && !cryptoAmount)) {
          return;
        }

        dispatch(setPriceDetailsForSell({}));
        dispatch(setMinimumAmountError({
          isError: false,
          errorText: "",
          minimumAmount: ""
        }));

        if (props.setHttpCallInProgressFromComponentAsTrue) {
          props.setHttpCallInProgressFromComponentAsTrue();
        }

        let body = {
          "fiat_currency_code": String(selectedFiatCurrency.fiat_code),
          "crypto_currency_code": String(selectedCryptoCurrency.code),
          "direction": "SELL_CRYPTO",
        }

        if (cryptoAmount) {
          body.cryptoAmount = cryptoAmount;
        } else {
          body.fiatAmount = fiatAmount;
        }

        if (selectedCryptoCurrency.network) {
          body.cryptoCurrencyNetwork = selectedCryptoCurrency.network;
        }

        CurrenyService.getPricingDetails(body)
          .then((data) => {
            if (data && data.status === `success` && data.response) {
              dispatch(setPriceDetailsForSell({
                "priceInFiatCurrency": data.response.price_in_fiat_currency,
                "cryptoCurrencyAmount": data.response.crypto_currency_amount,
                "fiatAmount": data.response.fiat_currency_amount,
                "paymentMethodFee": data.response.payment_method_fees,
                "minimumAmount": data.response.minimumAmount,
              }));
              if (!body.fiatAmount) {
                dispatch(setFiatAmountForSell(data.response.fiat_currency_amount));
              }
              if (!body.cryptoAmount) {
                dispatch(setCryptoAmountToSell(data.response.crypto_currency_amount));
              }
              dispatch(setMinimumAmountError({
                isError: false,
                errorText: "",
                minimumAmount: ""
              }));
              props.setHttpCallInProgressFromComponentAsFalse();
            }
          })
          .catch((err) => {
            const errorMessage = lodash.get(err, 'response.data.message', null);
            const minimumAmount = lodash.get(err, 'response.data.minimumAmount', null);
            let minimumAmountPrefix = `The minimum amount you can sell for`;
            if (errorMessage && errorMessage.startsWith(minimumAmountPrefix)) {
              dispatch(setMinimumAmountError({
                isError: true,
                errorText: minimumAmountPrefix,
                minimumAmount: minimumAmount
              }));
            } else {
              dispatch(setMinimumAmountError({
                isError: true,
                errorText: errorMessage,
                minimumAmount: minimumAmount || "",
              }));
            }
          });
      }, 1000)

      return () => clearTimeout(delayDebouncePriceFetch)
  }, [dispatch, selectedFiatCurrency.fiat_code, selectedCryptoCurrency.code, selectedCryptoCurrency.network, fiatAmount, cryptoAmount, priceParamChanged]);

return (
  <Box></Box>
)
}

export default withStyles(styles)(PriceFetch);


