import { withStyles } from '@material-ui/styles';
import { Box, Link, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import OrderService from 'lib/services/OrderService';
import { useLocation } from 'react-router-dom';
import FormHeader from 'widgets/FormHeader';

const styles = theme => ({
  mainDiv: {
    padding: '1em',
    paddingTop: '1em',
    paddingBottom: '0em',
    width: '100%',
  },
  listItem: {
    padding: '0.2em',
  },
  listItemText: {
  },
  footer: {
    marginTop: "20px"
  },
  emptyOrderText: {
    fontSize: "18px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.main
  },
  emptyOrderTextGrid: {
    justifyContent: "center",
    marginTop: "45%",
    display: "flex"
  },
  emptyOrderButtonGrid: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    width: "30%",
    margin: "auto"
  },
  card: {
    width: "100%"
  },
  title: {
    fontSize: "14px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  cardGrid: {
  },
  subText: {
    fontSize: "small",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    textDecoration: "underline"
  },
  detailHeading: {
    fontSize: "14x",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  detailValue: {
    fontSize: "14px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  detailValueGrid: {
    justifyContent: "flex-end",
    display: "flex"
  },
  statusTextGrid: {
    overflowWrap: "anywhere"
  },
  orderListBox: {
    height: "27em",
    fontWeight: "bold"
  },
  linkText: {
    fontSize: "14px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    textDecoration: "underline",
  },
  txnHashHeading: {
    fontSize: "14px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  supportMessage: {
    display: "flex",
    fontSize: "14px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  statusReasonText: {
    fontSize: "18px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  statusReasonTextGrid: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex"
  },
  orderPropertyGrid: {
    marginTop: "20px",
  }
});


function OrderDetail(props) {
  const search = useLocation().search;
  const [orderData, setOrderData] = useState();
  const [orderDataFetched, setOrderDataFetched] = useState(false);

  useEffect(() => {

    if (orderData) {
      return;
    }



    const stellarToken = new URLSearchParams(search).get('token');
    const stellarTransactionId = new URLSearchParams(search).get('transaction_id');

    OrderService.getStellarCustomWalletTransaction({ stellarToken, stellarTransactionId })
      .then((data) => {
        if (data && data.status === `success` && data.response?.id) {
          setOrderData(data.response);
        }
        setOrderDataFetched(true);
      })
      .catch((err) => {
        setOrderDataFetched(true);
      })
  }, [orderData, search]);

  return (
    <Box className={props.classes.mainDiv}>
      <Grid container direction="column">
        <Grid item>
          <FormHeader hideBackButton={true} hideMenuButton={true} titleText={`Order Detail`} history={props.history} />
        </Grid>
        <Grid item style={{ height: "20px" }}></Grid>
        <Grid item>
          <Box className={props.classes.orderListBox} overflow="auto">
            {orderData ?
              <Grid container direction="column">
                <Grid container className={props.classes.statusReasonTextGrid}>
                  <Grid item>
                    <Typography className={props.classes.statusReasonText}>
                      {orderData.statusReason}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Card className={props.classes.card} variant="elevation" style={{ border: "0.5px solid lightgrey", backgroundColor: getBackgroundColorBasedOnStatus(orderData.status), marginTop: "2em" }}>
                    <CardContent>
                      <Grid container direction="column" className={props.classes.cardGrid}>
                        <Grid container>
                          <Grid item xs={4} sm={4}>
                            <Typography className={props.classes.title}>
                              <b>Order ID:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sm={8}>
                            <Typography className={props.classes.detailValue} color="textSecondary" style={{ float: 'right',overflowWrap: "anywhere", }}>
                              <b>{orderData.id}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={props.classes.orderPropertyGrid}>
                          <Grid item xs={4} sm={4}>
                            <Typography className={props.classes.title}>
                              <b>Status:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sm={8}>
                            <Typography className={props.classes.detailValue} color="textSecondary" style={{ float: 'right' }}>
                              {orderData.status?.replace("_", " ")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={props.classes.orderPropertyGrid}>
                          <Grid item xs={4} sm={4}>
                            <Typography className={props.classes.title}>
                              <b>{orderData.fiat_currency.code} Amount:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sm={8}>
                            <Typography className={props.classes.detailValue} color="textSecondary" style={{ float: 'right' }}>
                              {orderData.fiat_currency.symbol}{Number(orderData.fiat_currency.amount).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={props.classes.orderPropertyGrid}>
                          <Grid item xs={6} sm={6}>
                            <Typography className={props.classes.title}>
                              <b>Crypto Currency:</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <Typography className={props.classes.detailValue} color="textSecondary" style={{ float: 'right' }}>
                              {orderData.crypto_currency.code} {orderData.crypto_currency.network?.toUpperCase()}
                            </Typography>
                          </Grid>
                        </Grid>
                        {(orderData.crypto_currency && orderData.crypto_currency.amount) ?
                          <Grid container className={props.classes.orderPropertyGrid}>
                            <Grid item xs={6} sm={6}>
                              <Typography className={props.classes.title}>
                                <b>{orderData.crypto_currency.code.toUpperCase()} Amount</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} className={props.classes.detailValueGrid}>
                              <Typography className={props.classes.detailValue} color="textSecondary">
                                {Number(Number(orderData.crypto_currency.amount).toFixed(10))} {orderData.crypto_currency.code}
                              </Typography>
                            </Grid>
                          </Grid>
                          : null}
                      </Grid>
                      {getOrderTransactionLink(orderData) ?
                        <Grid container className={props.classes.orderPropertyGrid}>
                          <Grid item className={props.classes.statusTextGrid}>
                            <Link href={orderData.crypto_currency.transaction.link} target="_blank" rel="noreferrer" className={props.classes.linkText} >
                              Check transaction on blockchain
                            </Link>
                          </Grid>
                        </Grid>
                        : (getOrderHash(orderData) ?
                          <Grid container className={props.classes.orderPropertyGrid}>
                            <Grid item className={props.classes.statusTextGrid}>
                              <Typography className={props.classes.title}>
                                <b>{orderData.crypto_currency.code.toUpperCase()} Transaction Hash:</b>
                              </Typography>
                              <Typography className={props.classes.detailValue} color="textSecondary">
                                {getOrderHash(orderData)}
                              </Typography>
                            </Grid>
                          </Grid> : null)}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item style={{height: "20px"}}></Grid>
                <Grid item>
                  <Typography className={props.classes.supportMessage}>
                    Need support with your order?
                  </Typography>
                  <Typography className={props.classes.supportMessage} color="textSecondary">
                    Please write to us at team@payfura.com along with the Order ID.
                    We would be happy to assist you.
                  </Typography>
                </Grid>
              </Grid>
              :
              orderDataFetched ?
                <Grid container direction="column">
                  <Grid item className={props.classes.emptyOrderTextGrid}>
                    <Typography className={props.classes.emptyOrderText} color="textSecondary">
                      Could not find order data. Something went wrong.
                    </Typography>
                  </Grid>
                </Grid>
                :
                <div></div>
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )

  function getOrderHash(order) {
    return orderData.crypto_currency && orderData.crypto_currency.transaction && orderData.crypto_currency.transaction.hash;
  }

  function getOrderTransactionLink(order) {
    return orderData.crypto_currency && orderData.crypto_currency.transaction && orderData.crypto_currency.transaction.hash && orderData.crypto_currency.transaction.link;
  }
  
  function getBackgroundColorBasedOnStatus(orderStatus) {
    let bgColor = "#f8f8f8";
    switch(orderStatus) {
      case "FAILED":
        bgColor = "#f7dbdb";
        break;
      case "COMPLETED":
      case "PAYMENT_SUCCESS":
      case "PROCESSING_CRYPTO":
      case "RELEASING_CRYPTO":
        bgColor = "#f6ffef";
        break;
      default:
    }

    return bgColor;
  }
}

export default withStyles(styles)(OrderDetail);
