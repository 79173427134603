const customWalletPartnerReducer = (state = null, action) => {
  switch (action.type) {
      case 'SET_CUSTOM_WALLET_PARTNER':
          return action.payload;

      default:
          return state;
  }
}


export default customWalletPartnerReducer;
