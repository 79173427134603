import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid, Box, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Util from '../lib/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomLinearProgressBar from './CustomLinearProgressBar';
import { useTheme } from '@material-ui/core/styles';
import CustomTooltip from './CustomTooltip';

const styles = theme => ({
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main
  },
  tooltip: {
    backgroundColor: "black",
    rippleBackgroundColor: "red"
  }
});

function PriceDetail(props) {
  const theme = useTheme();
  const priceDetails = useSelector(state => state.priceDetails);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const minimumAmountError = useSelector(state => state.minimumAmountError);

  return (
    <Box style={{ marginBotton: '1em' }}>
      {!(priceDetails && Object.keys(priceDetails).length) ? getLoader() :
        <Grid container direction="column">
          {priceDetails.priceInFiatCurrency ? <Grid container>
            <Grid item xs={8}>
              <Typography className={props.classes.text}>{Util.capitalizeFirstLetter(selectedCryptoCurrency.name)} Price</Typography>
            </Grid>
            <Grid item xs={4} className={props.classes.textGrid}>
              <Typography component="h4" className={props.classes.text}>{selectedFiatCurrency.fiat_symbol}{priceDetails.priceInFiatCurrency.toLocaleString('en', {maximumFractionDigits: 14})}</Typography>
            </Grid>
          </Grid> : null }
          {/* Show pricing processing fee only when fiat price has been loaded */}
          {priceDetails.priceInFiatCurrency ? <Grid container style={{ marginTop: '1em' }}>
            <Grid item xs={8}>
              <Grid container>
                <Grid item>
                  <Typography component="h4" className={props.classes.text}>Processing Fee</Typography>
                </Grid>
                <Grid item>
                  <CustomTooltip title={"Transaction fee charged by banks for processing payment"}>
                    <IconButton size="small">
                      <InfoOutlinedIcon size="small" style={{ fontSize: "14px", color: theme.palette.secondary.dark }} />
                    </IconButton>
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={props.classes.textGrid}>
              <Grid><Typography component="h4" className={props.classes.text}>{selectedFiatCurrency.fiat_symbol}{(priceDetails.paymentMethodFee && priceDetails.paymentMethodFee.toLocaleString('en')) || '0'}</Typography></Grid>
            </Grid>
          </Grid> : null }
          {Number(priceDetails.witdrawalFeeInFiatCurrency) ? <Grid container style={{ marginTop: '1em' }}>
            <Grid item xs={8}>
              <Grid container>
                <Grid item>
                  <Typography component="h4" className={props.classes.text}>Network Fee</Typography>
                </Grid>
                <Grid item>
                  <CustomTooltip title={"Total withdrawal fee based on approximate gas fee needed on network to execute this transaction"}>
                    <IconButton size="small">
                      <InfoOutlinedIcon size="small" style={{ fontSize: "14px", color: theme.palette.secondary.dark }} />
                    </IconButton>
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={props.classes.textGrid}>
              <Grid><Typography component="h4" className={props.classes.text}>{selectedFiatCurrency.fiat_symbol}{priceDetails.witdrawalFeeInFiatCurrency && priceDetails.witdrawalFeeInFiatCurrency.toLocaleString('en')}</Typography></Grid>
            </Grid>
          </Grid> : null }
        </Grid>
      }
    </Box>
  )

  function getLoader() {
    if (minimumAmountError && minimumAmountError.isError) {
      return <div></div>;
    }
    return (
      <Grid container>
        <Grid item xs={4} sm={4}></Grid>
        <Grid item xs={4} sm={4}>
          <CustomLinearProgressBar />
        </Grid>
        <Grid item xs={4} sm={4}></Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PriceDetail);
