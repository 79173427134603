import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import KycService from 'lib/services/KycService';
import lodash from 'lodash';
import FormHeader from 'widgets/FormHeader';
import UserService from 'lib/services/UserService';

const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2em'
  },
  CustomFormButton: {
    marginTop: '12em'
  },
  kycDiv: {
    width: '100%',
    height: '100%'
  },
  mainDiv: {
    width: '100%'
  }
});

function KycForm(props) {

  const [errorText, setErrorText] = useState("");
  const userProfile = useSelector(state => state.userProfile);
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const proofOfAddressContext = lodash.get(props, 'location.state.proofOfAddressContext');

  useEffect(() => {
    function renderSumsub(sdkToken) {
      let snsWebSdkInstance = window.snsWebSdk?.init(sdkToken,
        (data) => {
          Promise.resolve(
            sdkToken
          )
        })
        .withConf({
          lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
          email: userProfile.email,
          phone: `${userProfile.isdCode}${userProfile.phoneNumber}`,
          uiConf: {
            // customCss: "https://url.com/styles.css"
            // URL to css file in case you need change it dynamically from the code
            // the similar setting at Customizations tab will rewrite customCss
            // you may also use to pass string with plain styles `customCssStr:`
          },
          onError: (error) => {
            console.error('WebSDK onError', error)
          },
          onMessage: (type, payload) => {
            if (type === `idCheck.applicantStatus` && payload.reviewResult && payload.reviewResult.reviewAnswer === 'GREEN' && payload.reviewStatus === `completed`) {
              props.history.push('/checkkyc', {proofOfAddressContext});
            }
          },
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        .on('idCheck.stepCompleted', (payload) => {
        })
        .on('idCheck.applicantStatus', (payload) => {
          if (payload?.reviewResult && payload?.reviewResult?.reviewAnswer === 'GREEN' && payload?.reviewStatus === `completed`) {
            props.history.push('/checkkyc', {proofOfAddressContext});
          }
        })
        .on('idCheck.onError', (error) => {
        })
        .on('onError', (error) => {
        })
        .build();

      snsWebSdkInstance.launch('#sumsub-websdk-container')
    }

    UserService.getUserProfile()
      .then((data) => {
        if (data && data.status === `success` && data.response) {
          const user = data.response.user || {};
          if ([`VERIFIED`, `REJECTED`, `CHECKS_STARTED`].indexOf(user.kycStatus) > -1) {
            if (!proofOfAddressContext || [`VERIFIED`, `REJECTED`, `CHECKS_STARTED`].indexOf(user.kycPoaStatus) > -1) {
              props.history.push(`/checkkyc`, {proofOfAddressContext});
              return;
            }
          } else if ([`ONHOLD`].indexOf(user.kyc_status) > -1) {
            setErrorText("Your KYC is under manual review. Please try again later or contact us at team@payfura.com");
            return;
          }
          if (proofOfAddressContext && user.kycStatus !== `VERIFIED`) {
            setErrorText("Your KYC is not complete. Please try again or contact us at team@payfura.com");
            return;
          }
          KycService.generateSdkToken({
            cfpmId: selectedPaymentMethod.cfpm_id,
          })
            .then((response) => {
              const sdkToken = lodash.get(response, 'response.sdkToken');
              if (response && response.status === `success` && sdkToken) {
                renderSumsub(sdkToken);
              }
            })
            .catch((e) => {
              setErrorText("Looks like we are facing some issue with our KYC partner service. We are working on resolving it immediately. Please try again later or contact us at team@payfura.com");
            });
        }
      });
  }, [proofOfAddressContext, props.history, selectedPaymentMethod.cfpm_id, userProfile.email, userProfile.isdCode, userProfile.phoneNumber]);

  return (
    <Box className={props.classes.mainDiv} overflow="auto">
      {errorText ? <FormHeader titleText="Complete Kyc" history={props.history} /> : null}
      {errorText ? <Box style={{ fontSize: 'small', textAlign: "center", marginTop: "15em", padding: "2em" }}>
        <FormLabel style={{ fontSize: 'small' }}>{errorText}</FormLabel>
      </Box> : null}
      <div id="sumsub-websdk-container" className={props.classes.kycDiv}></div>
    </Box>
  );

}

export default withStyles(styles)(KycForm);