import PriceDetail from '../../widgets/PriceDetail'
import FormHeader from '../../widgets/FormHeader';
import WalletField from './widgets/WalletField';
import CustomFormButton from '../../widgets/CustomFormButton';
import { Grid, Box, Divider, FormLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import util from '../../lib/util';
import { useSelector, useDispatch } from 'react-redux';
import UserService from '../../lib/services/UserService';
import { setUserAddress, setUserProfile } from 'actions';
import Fade from '@mui/material/Fade';
import { useEffect, useState, useCallback } from 'react';
import OrderService from 'lib/services/OrderService';
import lodash from 'lodash';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textLabelGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  divider: {
    marginBottom: '20px'
  },
  priceDetailDiv: {
    height: '110px',
  },
  errorText: {
    fontSize: "14px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  }
});

function WalletForm(props) {
  const dispatch = useDispatch();
  const walletAddress = useSelector(state => state.walletAddress);
  const priceDetails = useSelector(state => state.priceDetails);
  const fiatAmount = useSelector(state => state.fiatAmount);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const partnerOrderId = useSelector(state => state.partnerOrderId);
  const customWalletPartner = useSelector(state => state.customWalletPartner);
  const disableWalletAddressChange = props.disableWalletAddressChange;
  const [renderWalletForm, setRenderWalletForm] = useState(false);
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const [errorText, setErrorText] = useState();
  const [loadingText, setLoadingText] = useState("");
  const isKycRedirected = lodash.get(props, 'location.state.isKycRedirected');

  const fetchUserProfile = useCallback(() => {
    UserService.getUserProfile()
      .then((data) => {
        if (data && data.status === `success` && data.response) {
          const user = data.response.user || {};

          if (user.firstName && user.lastName && user.isdCode && user.phoneNumber) {
            dispatch(setUserProfile({
              firstName: user.firstName,
              dateOfBirth: user.dateOfBirth || "",
              lastName: user.lastName,
              email: user.email,
              isdCode: user.isdCode,
              phoneNumber: user.phoneNumber,
            }));
          } else {
            props.history.push('/userprofile');
            return;
          }

          if (user.address && Object.keys(user.address).length) {
            dispatch(setUserAddress({
              addressLine1: user.address.addressLine1,
              addressLine2: user.address.addressLine2,
              state: user.address.state,
              country: user.address.country,
              city: user.address.city,
              postalCode: user.address.postalCode
            }));
          } else {
            props.history.push('/addressform');
            return;
          }

          if (isKycRedirected) {
            props.history.push('/createpartneruser', { isKycRedirected });
            return;
          }

          props.history.push('/documentinfoform');
        } else {
          props.history.push('/emailform');
        }
      })
      .catch((err) => {
        props.history.push('/emailform');
      })
  }, [dispatch, isKycRedirected, props.history])

  const validateStellarWalletAddress = useCallback(async () => {
    try {
      setLoadingText("Validating your Stellar wallet...");
      const response = await OrderService.validateAddressStellarCustomWalletTransaction({
        cryptoCurrencyCode: selectedCryptoCurrency?.code,
        cryptoCurrencyNetwork: selectedCryptoCurrency?.network,
        walletAddress,
        cfpmId: selectedPaymentMethod.cfpm_id,
      });

      if (response?.status === `success` && response?.data?.isStellarAssetTrusted) {
        fetchUserProfile();
        return;
      }
      setRenderWalletForm(true);
      setErrorText(`Your wallet is not ready to accept ${selectedCryptoCurrency?.code}. Please establish a trustline for ${selectedCryptoCurrency?.code} and click on Continue.`);
    } catch (e) {
      setRenderWalletForm(true);
      setErrorText(`Error in validating your wallet address. Please try again later.`);
    }
  }, [fetchUserProfile, selectedCryptoCurrency?.code, selectedCryptoCurrency?.network, selectedPaymentMethod.cfpm_id, walletAddress]);

  useEffect(() => {
    if (isKycRedirected) {
      fetchUserProfile();
      return;
    }
    if (customWalletPartner?.toUpperCase() === `STELLAR`) {
      validateStellarWalletAddress();
    } else if (disableWalletAddressChange === "true" && walletAddress) {
      fetchUserProfile();
    } else if (customWalletPartner?.toUpperCase() === `LEDGER` && walletAddress) {
      fetchUserProfile();
    } else {
      setRenderWalletForm(true);
    }
  }, [partnerOrderId, disableWalletAddressChange, fetchUserProfile, selectedCryptoCurrency.name, selectedCryptoCurrency, props.history, walletAddress, dispatch, customWalletPartner, selectedPaymentMethod.cfpm_id, validateStellarWalletAddress, isKycRedirected]);

  const onClick = () => {
    if (!util.isValidCryptoAddress(walletAddress, selectedCryptoCurrency)) {
      return;
    }
    if (customWalletPartner?.toUpperCase() === `STELLAR`) {
      validateStellarWalletAddress();
      return;
    }
    fetchUserProfile();
  }

  const handleBackButton = () => {
    props.history.push("/", {notKycRedirected: true});
  }

  return (
    renderWalletForm ? <Fade in={true} timeout={800}>
      <Box className={props.classes.mainDiv}>
        <Grid container direction={'column'} >
          <Grid item className={props.classes.header}>
            <FormHeader titleText={`Wallet Address`} history={props.history} customHandleBackButton={handleBackButton} />
          </Grid>
          <Grid item style={{ height: "20px" }}></Grid>
          <Grid item>
            <WalletField disableWalletAddressChange={disableWalletAddressChange} />
          </Grid>
          <Grid item style={{ height: "30px" }}></Grid>
          <Grid item >
            <Box style={{ display: 'flex', flexDirection: 'column', }}>
              <Grid container>
                <Grid item xs={6} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Pay:</Typography>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                <Grid item xs={4} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{selectedFiatCurrency.fiat_symbol}{fiatAmount}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '1em' }}>
                <Grid item xs={3} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Get:</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={7} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{priceDetails.cryptoCurrencyAmount}
                    {selectedCryptoCurrency.code.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {!selectedCryptoCurrency.memo_required ? <Grid item className={props.classes.divider}>
            <Divider />
          </Grid> : null}
          {!selectedCryptoCurrency.memo_required ? <Grid item className={props.classes.priceDetailDiv}>
            <PriceDetail />
          </Grid> : null}
          {!selectedCryptoCurrency.memo_required ? <Grid item style={{ height: "105px" }}></Grid> : <Grid item style={{ height: "36px" }}></Grid>}
          <Grid item >
            {errorText ? <FormLabel className={props.classes.errorText}>{errorText}</FormLabel> : ''}
            <CustomFormButton onClick={onClick} buttonText="Continue" />
          </Grid>
        </Grid>
      </Box>
    </Fade> : <Box style={{textAlign: "center", marginTop: "300px"}}>
      <Typography variant="body1">
        {loadingText}
      </Typography>
    </Box>
  )
}

export default withStyles(styles)(WalletForm);
