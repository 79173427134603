import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import lodash from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import CurrenyService from '../../../lib/services/CurrencyService';
import { setMinimumAmountError, setPriceDetails } from '../../../actions';

const styles = theme => ({
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
  },
});

function PriceFetch(props) {
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const fiatAmount = useSelector(state => state.fiatAmount);
  const partnerApiKey = useSelector(state => state.partnerApiKey);
  const dispatch = useDispatch();

  useEffect(
    () => {
      const delayDebouncePriceFetch = setTimeout(() => {
        if (!selectedFiatCurrency.fiat_code || !selectedCryptoCurrency.code || !fiatAmount) {
          return;
        }
        dispatch(setPriceDetails({}));
        dispatch(setMinimumAmountError({
          isError: false,
          errorText: "",
          minimumAmount: ""
        }));
        if (props.setHttpCallInProgressFromComponentAsTrue) {
          props.setHttpCallInProgressFromComponentAsTrue();
        }

        let body = {
          "cryptoCurrencyCode": String(selectedCryptoCurrency.code),
          "fiatAmount": fiatAmount,
          "direction": "BUY_CRYPTO",
          "cfpmId": selectedPaymentMethod.cfpm_id,
          "countryCode": selectedFiatCurrency.country_code,
        }

        if (partnerApiKey) {
          body.partnerApiKey = partnerApiKey;
        }

        if (selectedCryptoCurrency.network) {
          body.cryptoCurrencyNetwork = selectedCryptoCurrency.network;
        }

        CurrenyService.getPricingDetails(body)
          .then((data) => {
            if (data && data.status === `success` && data.response) {
              dispatch(setPriceDetails({
                "priceInFiatCurrency": data.response.priceInFiatCurrency,
                "cryptoCurrencyAmount": data.response.cryptoCurrencyAmount,
                "paymentMethodFee": data.response.paymentMethodFee,
                "witdrawalFeeInFiatCurrency": data.response.witdrawalFeeInFiatCurrency,
              }));
              dispatch(setMinimumAmountError({
                isError: false,
                errorText: "",
                minimumAmount: ""
              }));
              props.setHttpCallInProgressFromComponentAsFalse();
            }
          })
          .catch((err) => {
            const errorMessage = lodash.get(err, 'response.data.message', null);
            const minimumAmount = lodash.get(err, 'response.data.minimumAmount', null);
            let minimumAmountPrefix = `The minimum amount you can buy is `;
            if (errorMessage && errorMessage.startsWith(minimumAmountPrefix)) {
              let minimumAmount = Number(errorMessage.replace(minimumAmountPrefix, ""));
              let digitCount = 1;
              digitCount = minimumAmount === 0 ? 1 : Math.floor(Math.log10(Math.abs(minimumAmount)));
              minimumAmount = (Math.ceil(minimumAmount/ Math.pow(10, digitCount)) *  Math.pow(10, digitCount))
              dispatch(setMinimumAmountError({
                isError: true,
                errorText: minimumAmountPrefix,
                minimumAmount: minimumAmount
              }));
            } else {
              dispatch(setMinimumAmountError({
                isError: true,
                errorText: errorMessage,
                minimumAmount: minimumAmount || "",
              }));
            }
          });
      }, 1000)

      return () => clearTimeout(delayDebouncePriceFetch)
  }, [dispatch, selectedFiatCurrency.fiat_code, selectedPaymentMethod.cfpm_id, selectedCryptoCurrency.code, selectedCryptoCurrency.network, fiatAmount]);

return (
  <Box></Box>
)
}

export default withStyles(styles)(PriceFetch);


