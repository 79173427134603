import { Box, Grid, FormLabel, Card, IconButton, Typography, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import PaymentService from '../../lib/services/PaymentService';
import { useSelector } from 'react-redux';
import FormHeader from 'widgets/FormHeader';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import util from '../../lib/util';
import EditIcon from '@material-ui/icons/Edit';
import lodash from 'lodash';
import CustomTooltip from 'widgets/CustomTooltip';
import Fade from '@mui/material/Fade';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%'
  },
  tooltip: {
    fontSize: "25px"
  },
  errorText: {
    fontSize: "12px",
    marginTop: "20px",
    marginBottom: "20px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    display: "flex",
    justifyContent: "center",
    height: '10px'
  },
  // formDiv: {
  //   marginTop: '0.3em',
  //   marginBottom: '30px'
  // },
  customFormButton: {
    // marginTop: '0.3em'
  },
  addressHeading: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "16px",
  },
  addressText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "14px",
    inlineSize: "300px",
    overflow: true,
    height: '40px'
  },
  editButton: {
    display: "flex",
    justifyContent: "flex-end"
  },
  payViaText: {
    color: theme.palette.secondary.dark,
    marginRight: "1em",
    marginTop: "10px",
    fontSize: "14px"
  },
  payViaGrid: {
    marginTop: "0.6em",
  }
});


function CardDetail(props) {
  const [errorText, setErrorText] = useState("");
  const [isCardValid, setIsCardValid] = useState(false);
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const orderId = useSelector(state => state.orderId);
  const userAddress = useSelector(state => state.userAddress);
  const formattedAddress = util.generateFormattedAddress(userAddress);

  const handleEditAddressClick = () => {
    props.history.push('/addressform', { callingPage: "CARD_DETAIL" });
  }

  useEffect(() => {
    window.Frames?.init({
      publicKey: "pk_sbox_pzgadlnglfxoor7dopqzrl2d4ue",
      cardValidationChanged: function () {
        console.log("window.Frames.isCardValid()", window.Frames.isCardValid());
        setErrorText("");
        setIsCardValid(window.Frames.isCardValid());
      },
      cardTokenized: function (data) {
        setErrorText("");
        const cardToken = data.token;
        console.log("cardtokenized", data.token);
        console.log(isCardValid, cardToken, orderId);
        if (!cardToken || !orderId) {
          return;
        }
        const body = {
          token: cardToken,
          order_id: String(orderId)
        };
        setHttpCallInProgress(true);
    
        PaymentService.createPayment(body)
          .then((data) => {
            setHttpCallInProgress(false);
            if (data) {
              if (data && data.requiresRedirect && data.redirectLink) {
                props.history.push('/checkpayment');
                window.open(data.redirectLink, "_blank");
              } else {
                props.history.push('/checkpayment');
              }
              return;
            }
          })
          .catch((err) => {
            setHttpCallInProgress(false);
            const errorMessage = lodash.get(err, 'response.data.message', null);
            console.log(errorMessage);
            setErrorText(errorMessage);
          })
      },
      cardSubmitted: function (data) {
        console.log("submitted", data);
        setErrorText("");
      },
      cardTokenizationFailed: function (event) {
        // catch the error
        setErrorText(`Not able to capture card details. Please try again later.`);
      },

    });
  }, []);

  const handleSubmit = (event) => {
    setErrorText("");
    window.Frames.submitCard()
    .then(() => {
      console.log("card data successdully submitted");
    })
    .catch((e) => {
      console.log(e);
    });
  }

  return (
    <Fade in={true} timeout={800}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <FormHeader titleText="Debit/Credit card details" history={props.history} />
          </Grid>
          <Grid item style={{ height: "20px" }}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      Billing Address
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} className={props.classes.editButton}>
                    <CustomTooltip title={"Edit"}>
                      <IconButton size="small" onClick={handleEditAddressClick}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.addressText}>
                  {formattedAddress}
                </Typography>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container className={props.classes.payViaGrid}>
          <Grid item className={props.classes.payViaText}>
            Pay using
          </Grid>
          <Grid item>
            <img alt="mastercard" src="https://rl-crypto-currency.s3.amazonaws.com/mastercard_symbol.svg" width="40px" height="35px" style={{ marginBottom: "0.2em" }} />
            <img alt="visa" src="https://rl-crypto-currency.s3.amazonaws.com/visa.svg" width="70px" height="40px" />
          </Grid>
        </Grid>
              <div className="card-number-frame">
              </div>
          <Grid container spacing={3}>
            <Grid item xs={5} sm={5}>
              <div className="expiry-date-frame">
              </div>
            </Grid>
            <Grid item xs={7} sm={7}>
              <div className="cvv-frame">
              </div>
            </Grid>
          </Grid>
        {/* </form> */}
        <Grid container className={props.classes.customFormButton}></Grid>
        <FormLabel className={props.classes.errorText}>{errorText ? errorText : ''}</FormLabel>
        <Box>
          {httpCallInProgress ? <CustomLinearProgressBar /> : null}
          <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress || !isCardValid} buttonText="Submit" />
        </Box>
      </Box >
    </Fade>
  )
}

export default withStyles(styles)(CardDetail);
