const selectedPaymentMethodReducer = (state = {}, action) => {
  switch (action.type) {
      case 'SET_SELECTED_PAYMENT_METHOD':
          return action.payload;

      default:
          return state;
  }
}

export default selectedPaymentMethodReducer;
