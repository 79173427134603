import React from 'react';
import CustomFormButton from '../../widgets/CustomFormButton';
import FormHeader from '../../widgets/FormHeader';
import { Grid, Box, FormLabel, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Fade from '@mui/material/Fade';
import lodash from 'lodash';
import { useTheme } from '@material-ui/core/styles';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  errorDiv: {
    marginBottom: "0.4em",
    display: "flex",
    justifyContent: "center"
  },
  label: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark,
    fontSize: "12px"
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  textPaymentMethod: {
    fontSize: '14px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  nextStepText: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  nextStepTextSecond: {
    fontSize: '14px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  walletText: {
    fontSize: '12px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    overflowWrap: "break-word",
    wordWrap: "break-word",
    color: theme.palette.secondary.main,
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textLabelGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  submitButton: {
    marginTop: "0em"
  },
  divider: {
    marginBottom: '10px'
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  priceDetailDiv: {
    height: '80px',
    marginTop: "15px",
    marginBottom: "27px"
  },
  nextStepsDiv: {
    marginTop: "15px",
    marginBottom: "2px"
  },
  instructionDiv: {
    height: '20px',
    marginTop: "15px",
    marginBottom: "2px"
  },
  checkBoxLabel: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
});

function DestinationWalletPayment(props) {
  const fiatAmount = useSelector(state => state.fiatAmountForSell);
  const cryptoAmount = useSelector(state => state.cryptoAmountToSell);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrencyToSell);
  const selectedCryptoCurrencyCode = selectedCryptoCurrency.code;
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrencyForSell);
  const paymentMethodName = lodash.get(props, 'location.state.paymentMethodName');
  const payinWalletAddress = lodash.get(props, 'location.state.payinWalletAddress');
  const theme = useTheme();

  const handleBackButton = () => {
    props.history.push("/", {notKycRedirected: true});
  }

  const handleSubmit = () => {
    props.history.replace("/");
  }

  return (
    <Fade in={true} timeout={800}>
      <Box overflow="auto" className={props.classes.mainDiv}>
        <Grid container direction="column" style={{ width: "100%" }}>
          <Grid item>
            <FormHeader hideBackButton={true} titleText="Wallet address to Pay" history={props.history} customHandleBackButton={handleBackButton} />
          </Grid>
          <Grid item className={props.classes.nextStepsDiv}>
            <Typography variant="caption" className={props.classes.nextStepText}>
              {`Please send ${cryptoAmount} ${selectedCryptoCurrencyCode} to below address`}
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "10px", height: "180px" }}>
            <Grid container>
              <Grid item>
                <FormLabel className={props.classes.label}>{selectedCryptoCurrencyCode} Wallet Address to Pay</FormLabel>
              </Grid>
            </Grid>
            <TextField
              id="outlined-multiline-flexible"
              label=""
              style={{
                width: "100%",
                fontSize: "10px",
                fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
              }}
              inputProps={{ style: { height: "20px", fontSize: "14px", color: theme.palette.secondary.dark, fontFamily: "whitneymedium,muli,system-ui,Helvetica,sans-serif" } }}
              multiline
              maxRows={4}
              variant="outlined"
              value={payinWalletAddress}
              disabled
            />
            <Grid item style={{height: "60px"}}>
            </Grid>
            <Grid item className={props.classes.instructionDiv}>
              <Typography variant="caption" className={props.classes.nextStepTextSecond}>
                Important Instructions:
              </Typography>
            </Grid>
            <Grid item>
              <ul>
                <li>
                  <FormLabel className={props.classes.label}>Please make sure that you send only {selectedCryptoCurrencyCode} to this wallet address. Otherwise it may lead to loss of funds.</FormLabel>    
                </li>
                <li>
                  <FormLabel className={props.classes.label}>{`Once crypto is received in the above wallet, a payout of ${selectedFiatCurrency.fiat_symbol}${fiatAmount.toLocaleString('en')} will be initiated to ${paymentMethodName}`}</FormLabel>    
                </li>
                <li>
                  <FormLabel className={props.classes.label}>
                    You can close this window once you have sent crypto to the above wallet. We will notify you on your email about the payout status.
                  </FormLabel>    
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid item style={{height: "180px"}}>
          </Grid>
        </Grid>
        <Box className={props.classes.submitButton}>
          <Grid container direction="column">
            <Grid item>
              <CustomFormButton onClick={handleSubmit} buttonText={`Go Home`} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(DestinationWalletPayment);
