import ApiConstant from '../ApiConstant';
import Request from '../Request';
class UserService {

  static submitUserProfile(body) {
    let opts = {
      method: ApiConstant.submitUserProfile.method,
      url: ApiConstant.submitUserProfile.url,
      data: body
    }

    return Request.Fire(opts);
  }

  static submitUserAddress(body) {
    let opts = {
      method: ApiConstant.submitUserAddress.method,
      url: ApiConstant.submitUserAddress.url,
      data: body
    }

    return Request.Fire(opts);
  }
  
  static logout() {
    let opts = {
      method: ApiConstant.logout.method,
      url: ApiConstant.logout.url,
    }
    return Request.Fire(opts);
  }

  static getUserProfile() {
    let opts = {
      method: ApiConstant.getUserProfile.method,
      url: ApiConstant.getUserProfile.url,
    }

    return Request.Fire(opts);
  }

  static createFiatPartnerUser(data) {
    let opts = {
      method: ApiConstant.createFiatPartnerUser.method,
      url: ApiConstant.createFiatPartnerUser.url,
      data,
    }

    return Request.Fire(opts);
  }

  static logAnalyticsInfo(data) {
    let opts = {
      method: ApiConstant.logAnalyticsInfo.method,
      url: ApiConstant.logAnalyticsInfo.url,
      data,
    }

    return Request.Fire(opts);
  }
  
  static createPartnerAgreement(data) {
    let opts = {
      method: ApiConstant.createPartnerAgreement.method,
      url: ApiConstant.createPartnerAgreement.url,
      data,
    }

    return Request.Fire(opts);
  }
  
  static updatePartnerAgreement(data) {
    let opts = {
      method: ApiConstant.updatePartnerAgreement.method,
      url: ApiConstant.updatePartnerAgreement.url,
      data,
    }

    return Request.Fire(opts);
  }

  static getFiatPartnerUser(cfpmId) {
    let opts = {
      method: ApiConstant.getFiatPartnerUser.method,
      url: `${ApiConstant.getFiatPartnerUser.url}?cfpmId=${cfpmId}`,
    }

    return Request.Fire(opts);
  }
}

export default UserService;
