import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
    borderRadius: 5,
    textAlign: "center",
    marginLeft: "30px",
    marginRight: "30px",
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'black',
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function CustomLinearProgressBar() {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <BorderLinearProgress variant="indeterminate" />
    </Grid>
  );
}

export default CustomLinearProgressBar;