import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import FormHeader from 'widgets/FormHeader';
import PaymentService from 'lib/services/PaymentService';

const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2em'
  },
  CustomFormButton: {
    marginTop: '12em'
  },
  creditCardDiv: {
    width: '100%',
    height: '100%'
  },
  mainDiv: {
    width: '100%',
    padding: "1em",
  },
  ".redirect-divider": {
    display: "none",
  }
});

function PtCreditCardAdd(props) {
  const [errorText, setErrorText] = useState("");
  const orderId = useSelector(state => state.orderId);

  useEffect(() => {
    PaymentService.createPaymentMethod({
      orderId: orderId,
    })
      .then((data) => {
        const response = data.response;
        const CARK_RESOURCE_TOKEN = response?.resourceTokenHash;
        const paymentMethodId = response?.paymentMethodId;

        window.primeTrustReady = function (pt) {
          pt.launchCreditCard({
            target: document.getElementById("credit-card-widget-pt-add"),
            resourceTokenHash: CARK_RESOURCE_TOKEN,
            theme: {
              primary: "#000000",
            },
            events: {
              cardVerified: function (contactId, fundTransferMethodId) {
                PaymentService.patchPaymentMethod({
                  paymentMethodId,
                  orderId,
                  fundTransferMethodId,
                  contactId,
                })
                  .then((data) => {
                    props.history.push('/choosefiatpaymentmethod');
                  })
                  .catch((e) => {
                    setErrorText("Error in saving card details. Please try again later.");
                  });
              },
            }
          });
        }
      })
      .catch((e) => {
        setErrorText("Error in saving card detail. Please try again later.");
      });
  }, [orderId, props.history]);

  return (
    <Box className={props.classes.mainDiv}>
      <FormHeader titleText="Add Card Details" history={props.history} hideBackButton={true} />
      {errorText ? <Box style={{ fontSize: 'small', textAlign: "center", marginTop: "2em", padding: "2em" }}>
        <FormLabel style={{ fontSize: 'small' }}>{errorText}</FormLabel>
      </Box> : null}
      <div id="credit-card-widget-pt-add" className={props.classes.creditCardDiv}></div>
    </Box>
  );

}

export default withStyles(styles)(PtCreditCardAdd);
