import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import FormHeader from 'widgets/FormHeader';
import lodash from 'lodash';
import PaymentService from 'lib/services/PaymentService';
import UserService from 'lib/services/UserService';
import OrderService from 'lib/services/OrderService';

const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2em'
  },
  CustomFormButton: {
    marginTop: '12em'
  },
  creditCardDiv: {
    width: '100%',
    height: '100%'
  },
  mainDiv: {
    width: '100%',
    padding: "1em",
  },
  ".redirect-divider": {
    display: "none",
  }
});

function PtCreditCardCharge(props) {
  const [errorText, setErrorText] = useState("");
  const paymentMethodId = lodash.get(props, 'location.state.paymentMethodId');
  const orderId = useSelector(state => state.orderId);

  useEffect(() => {
    const body = {
      order_id: orderId,
      paymentMethodId,
    };
    PaymentService.createFiatPayment(body)
      .then((data) => {
        const contributionAmount = Number(data?.fiatAmount);
        const contributionToken = data?.contributionToken;
        if (data?.fiatCode !== 'USD' || !contributionAmount || !contributionToken) {
          throw new Error(`invalid payment method`);
        }
        window.primeTrustReady = function (pt) {
          pt.launchCreditCard({
            target: document.getElementById("credit-card-widget-pt-charge"),
            amount: contributionAmount,
            resourceTokenHash: contributionToken,
            theme: {
              primary: "#000000",
            },
            events: {
              onContribution: function (firstId, secondId, paymentId) {
                const logBody = {
                  orderId,
                  firstId,
                  secondId,
                  paymentId
                };
                UserService.logAnalyticsInfo(logBody)
                  .then((data) => {
                    if (data?.status === `success`) {
                      props.history.push('/convertfiattocrypto', {});
                    } else {
                      setErrorText(`We are facing some problem in checking payment status. If you have been charged please reach out to team@payfura.com`);
                    }
                  })
                  .catch(() => {
                    setErrorText(`We are facing some problem in checking payment status. Please try again later. If you have been charged please reach out to team@payfura.com`);
                  });
              },
              onContributionError: function (error) {
                props.history.push("/checkpayment");
              }
            }
          });
        }
      })
      .catch((e) => {
        setErrorText(`We are facing some problem in creating payment for you right now. Please try again later. If the problem persists please reach out to team@payfura.com`);
      });
  }, [orderId, paymentMethodId, props.history]);

  return (
    <Box className={props.classes.mainDiv}>
      <FormHeader titleText="Complete Payment" history={props.history} hideBackButton={true} />
      {errorText ? <Box style={{ fontSize: 'small', textAlign: "center", marginTop: "2em", padding: "2em" }}>
        <FormLabel style={{ fontSize: 'small' }}>{errorText}</FormLabel>
      </Box> : null}
      <div id="credit-card-widget-pt-charge" className={props.classes.creditCardDiv}></div>
    </Box>
  );

}

export default withStyles(styles)(PtCreditCardCharge);
