import { Box, Grid, Card, Typography, CardContent, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import OrderService from '../../lib/services/OrderService';
import { useSelector, useDispatch } from 'react-redux';
import FormHeader from 'widgets/FormHeader';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import VerifyEmailField from 'widgets/VerifyEmailField';
import { styled } from "@mui/material/styles";
import { setFiatAmount, setOrderId, setPartnerOrderId, setSelectedCryptoCurrency, setSelectedFiatCurrency, setSelectedPaymentMethod, setUserLoggedIn, setUserProfile, setWalletAddress, setWalletAddressMemo, setUserAddress, setApiCheckoutFlow } from 'actions';
import CurrencyService from 'lib/services/CurrencyService';
import lodash from 'lodash';
import PaymentMethod from 'components/BuyForm/widgets/PaymentMethod';
import PriceFetch from 'components/BuyForm/widgets/PriceFetch';
import { setToken, setPartnerApiKeyInRequest } from 'lib/Request';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%'
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  textLabelGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  tooltip: {
    fontSize: "25px"
  },
  subText: {
    fontSize: "12px",
    marginTop: "10px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  errorText: {
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "5px",
    color: "black",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    display: "flex",
    justifyContent: "center",
    height: '10px'
  },
  formDiv: {
    marginTop: '0.3em',
    marginBottom: '10px'
  },
  customFormButton: {
    padding: 0,
  },
  addressHeading: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "16px",
  },
  walletText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "14px",
    inlineSize: "100%",
    height: '40px',
  },
  editButton: {
    display: "flex",
    justifyContent: "flex-end"
  },
  payViaText: {
    color: theme.palette.secondary.dark,
    marginRight: "1em",
    marginTop: "10px",
    fontSize: "14px"
  },
  payViaGrid: {
    marginTop: "0.6em",
  },
  checkBoxLabel: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
  paymentLabelText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "14px",
    color: theme.palette.secondary.dark,
    marginLeft: "5px",
    maxWidth: "150px",
  },
  labelText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "16px",
    color: theme.palette.secondary.dark,
  },
  buttonContent: {
    display: "flex",
    fontSize: "16px",
    justifyContent: "center",
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  payWithGrid: {
    display: "flex",
    justifyContent: "flex-start",
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      format="##/##"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function DirectCardCheckout(props) {
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const orderId = useSelector(state => state.partnerOrderId);
  const partnerApiKey = useSelector(state => state.partnerApiKey);
  const [allowVerification, setAllowVerification] = useState(false);
  const [optLabelMessage, setOptLabelMessage] = useState("Sending verification OTP to your email...");
  const [otp, setOtp] = useState("");
  const [cryptoCurrencyCode, setCryptoCurrencyCode] = useState("");
  const [formattedFiatAmount, setFormattedFiatAmount] = useState("");
  const dispatch = useDispatch();
  const [loadedPaymentMethod, setLoadedPaymentMethod] = useState(false);
  const [loadedCryptoCurrencies, setLoadedCryptoCurrencies] = useState(false);
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const priceDetails = useSelector(state => state.priceDetails);
  const walletAddress = useSelector(state => state.walletAddress);

  const handleOTPChange = (value) => {
    setOtp(value);
  }

  useEffect(() => {
    setPartnerApiKeyInRequest(partnerApiKey);
    const body = {
      orderId, partnerApiKey
    };
    setHttpCallInProgress(true);
    OrderService.requestOrderVerification(body)
      .then((response) => {
        if (response && response.status === `success`) {
          const order = response.response?.order || {};
          setOptLabelMessage(response.message);
          setAllowVerification(true);
          setCryptoCurrencyCode(order.cryptoCode);
          setFormattedFiatAmount(`${order.fiatSymbol}${Number(order.fiatAmount)?.toFixed(2)}`);
          dispatch(setFiatAmount(Number(order.fiatAmount)?.toFixed(2)));
          if (order.countryCode) {
            CurrencyService.getFiatCurrencyList(order.countryCode)
              .then((currencyData) => {
                if (currencyData && currencyData.status === `success` && currencyData.response && currencyData.response.length) {
                  let fiatCurrency = currencyData.response && currencyData.response[0];
                  if (fiatCurrency && fiatCurrency.fiat_code === order.fiatCode) {
                    dispatch(setSelectedFiatCurrency(fiatCurrency));
                    const paymentMethods = fiatCurrency.payment_methods;
                    const orderPaymentMethod = lodash.filter(paymentMethods, (method) => {
                      return method.cfpm_id === order.paymentMethod.cfpm_id;
                    });
                    if (orderPaymentMethod && orderPaymentMethod[0]) {
                      dispatch(setSelectedPaymentMethod(orderPaymentMethod[0]));
                      setLoadedPaymentMethod(true);
                    }
                  }
                  const cryptoCurrencyId = order.cryptoCurrencyId;
                  CurrencyService.getCryptoCurrencyById(cryptoCurrencyId)
                    .then((cryptoCurrencyData) => {
                      if (cryptoCurrencyData && cryptoCurrencyData.status === `success` && cryptoCurrencyData.response && cryptoCurrencyData.response.length) {
                        let cryptoCurrency = cryptoCurrencyData.response && cryptoCurrencyData.response[0];
                        if (cryptoCurrency?.code === order.cryptoCode) {
                          dispatch(setSelectedCryptoCurrency(cryptoCurrency));
                          setLoadedCryptoCurrencies(true);
                        }
                      }
                    })
                }
              });
          }
          return;
        }
        setOptLabelMessage("We could not verify this order. Please try again later.");
        setAllowVerification(false);
      })
      .catch((e) => {
        setHttpCallInProgress(false);
        setOptLabelMessage("Order verification failed. Please try again later.");
        setAllowVerification(false);
      });
  }, [dispatch, orderId, partnerApiKey]);

  const handleVerifySubmit = () => {
    const body = {
      orderId,
      partnerApiKey,
      otp
    };
    OrderService.verifyOrder(body)
      .then((response) => {
        if (response && response.status === `success`) {
          const user = response.response?.user || {};
          const order = response.response?.order || {};
          dispatch(setOrderId(order.id));
          setErrorText("");
          setToken(user.authToken);
          dispatch(setUserLoggedIn(true));
          dispatch(setPartnerOrderId(order.id));
          dispatch(setWalletAddress(order.walletAddress));
          dispatch(setWalletAddressMemo(order.walletAddressMemo));
          const userData = {
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            dateOfBirth: user.dateOfBirth || "",
            isdCode: user.isdCode || "",
            phoneNumber: user.phoneNumber || "",
            email: user.email || "",
          };
          dispatch(setUserProfile(userData))
          dispatch(setUserAddress({
            addressLine1: user.address.addressLine1,
            addressLine2: user.address.addressLine2,
            state: user.address.state,
            country: user.address.country,
            city: user.address.city,
            postalCode: user.address.postalCode
          }));
          dispatch(setApiCheckoutFlow(true));
          props.history.push('/documentinfoform');
          return;
        }
      })
      .catch((e) => {
        console.log(e);
        setOptLabelMessage("Order verification failed. Please try again later.");
      });
  };

  const CardContentNoPadding = styled(CardContent)(`
    &:last-child {
      padding-bottom: 0;
      padding-top: 5px;
    }
  `);

  return (
    <Fade in={true} timeout={800}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <FormHeader titleText={"Buying  " + cryptoCurrencyCode} history={props.history} hideBackButton={true} hideMenuButton={true} />
          </Grid>
        </Grid>
        <Grid container style={{ height: "10px" }}>
        </Grid>
          <Grid container>
            <Grid item xs={8} sm={8}>
              <VerifyEmailField setOTP={handleOTPChange} placeholderText={"Enter Verification Code"} customStyle={{ borderRadius: "0" }} />
            </Grid>
            <Grid item xs={4} sm={4} style={{ paddingLeft: "0px" }}>
              <Box style={{ height: "100%" }}>
                <CustomFormButton customDivStyle={{ height: "100%", borderRaius: "0" }} customButtonStyle={{ height: "100%", borderRadius: "0" }} onClick={handleVerifySubmit} disabled={allowVerification && otp.length !== 6 && httpCallInProgress} buttonText="Verify" />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormLabel className={props.classes.subText}>* {optLabelMessage}</FormLabel>
            </Grid>
          </Grid>
          <Grid container style={{ height: "20px" }}></Grid>
          {allowVerification && walletAddress ?
          <Card variant='outlined'>
            <CardContentNoPadding>
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      Wallet Address
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.walletText}>
                  {walletAddress}
                </Typography>
              </Box>
            </CardContentNoPadding>
          </Card> : null}
          <Grid container style={{ height: "20px" }}></Grid>
          <Grid container style={{ height: "80px" }}>
          {formattedFiatAmount ? 
          <Card variant='outlined' style={{width: "100%"}}>
            <CardContentNoPadding>
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      You Pay
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container style={{width: "100%"}}>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.walletText}>
                  {formattedFiatAmount}
                </Typography>
                </Grid>
              </Box>
            </CardContentNoPadding>
          </Card> : null}
          </Grid>
          <Grid container style={{ height: "20px" }}></Grid>
          <Grid container style={{ height: "80px" }}>
          {priceDetails?.cryptoCurrencyAmount ? 
          <Card variant='outlined' style={{width: "100%"}}>
            <CardContentNoPadding>
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      You Get
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container style={{width: "100%"}}>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.walletText}>
                  {priceDetails.cryptoCurrencyAmount.toLocaleString('en')}&nbsp;{selectedCryptoCurrency.code.toUpperCase()}
                  <span style={{float: "right"}}>@ {selectedFiatCurrency.fiat_symbol}{priceDetails.priceInFiatCurrency.toLocaleString('en', { maximumFractionDigits: 14 })} / {selectedCryptoCurrency.code.toUpperCase()}</span>
                </Typography>
                </Grid>
              </Box>
            </CardContentNoPadding>
          </Card> : null}
          </Grid>
        {
          allowVerification && loadedPaymentMethod && loadedCryptoCurrencies ?
            <Grid container direction="column">
              <PriceFetch />
            </Grid> :
            null
        }
        <Grid container style={{ height: "10px" }}></Grid>
        {
          allowVerification && loadedPaymentMethod ?
            <Grid container direction="column" className={props.classes.payWithGrid}>
              <Grid item style={{ marginTop: "5px" }}>
                <Typography className={props.classes.labelText}>
                  Paying with {selectedPaymentMethod.name?.replace("Pay with ", "")}
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: "10px" }}>
                <PaymentMethod hideBottomPaddingGrid={true}/>
              </Grid>
            </Grid>
            : null
        }
        <FormLabel className={props.classes.errorText}>{errorText || ''}</FormLabel>
      </Box >
    </Fade>
  )
}

export default withStyles(styles)(DirectCardCheckout);
