import { withStyles } from '@material-ui/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormHeader from 'widgets/FormHeader';
import PaymentService from 'lib/services/PaymentService';
import loadingBankAnimation from 'assets/animations/bank_loading.json';
import paymentRejectedAnimation from 'assets/animations/failed.json';
import CustomLottie from 'widgets/CustomLottie';

const styles = theme => ({
  mainDiv: {
    padding: '1em',
    paddingTop: '1em',
    paddingBottom: '0em',
    width: '100%',
  },
  paymentMethodGrid: {
    fontWeight: "bold",
    padding: "1em",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
  mainText: {
    display: "flex",
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  mainTextGrid: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
  paymentButton: {
    marginTop: "10em",
    width: "100%",
  }
});


function InitializeBankPaymentMethod(props) {

  const [paymentMethodListFetched, setPaymentMethodListFetched] = useState(false);
  const orderId = useSelector(state => state.orderId);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const [mainText, setMainText] = useState("Initialising bank account setup...");
  const [errorOccured, setErrorOccured] = useState(false);
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);

  useEffect(() => {
    if (paymentMethodListFetched) {
      return;
    }

    const createPayments = (paymentMethodId) => {
      const fiatPaymentBody = {
        order_id: String(orderId),
        paymentMethodId,
      };
      const cryptoPaymentBody = {
        order_id: String(orderId)
      };
      PaymentService.createCryptoPayment(cryptoPaymentBody)
        .then(() => PaymentService.createFiatPayment(fiatPaymentBody))
        .then((data) => {
          props.history.push('/checkpayment', {
            bankDepositDetailsPresent: data.bankDepositDetailsPresent,
            bankDetails: data.bankDetails,
            numericReference: data.numericReference,
          });
        });
    }
    if ([`EU`, `GB`].indexOf(selectedFiatCurrency.country_code) > -1) {
      setHttpCallInProgress(true);
      PaymentService.getPaymentMethods(orderId)
        .then((paymentMethodResponse) => {
          const transferMethods = paymentMethodResponse?.response?.transferMethods || [];
          if (transferMethods?.length) {
            createPayments(transferMethods[0].id);
            setPaymentMethodListFetched(true);
          } else {
            PaymentService.createPaymentMethod({
              orderId: orderId
            })
              .then((data) => {
                setMainText("Finalising your bank account with us. This may take a few minutes. Please bear with us...");
                let intervalId = setInterval(function checkStatusIfTabInFocus() {
                  if (!paymentMethodListFetched) {
                    setHttpCallInProgress(true);
                    PaymentService.getPaymentMethods(orderId)
                      .then((response) => {
                        const transferMethods = response?.response?.transferMethods || [];
                        if (transferMethods?.length) {
                          clearInterval(intervalId);
                          setMainText(`Set up complete. Redirecting to payment page...`);
                          setPaymentMethodListFetched(true);
                          setHttpCallInProgress(false);
                          setTimeout(() => {
                            createPayments(transferMethods[0].id);
                          }, 5000);
                        }
                      })
                  }
                }, 4000);
              })
              .catch((e) => {
                setHttpCallInProgress(false);
                setErrorOccured(true);
                setMainText(`Error occured in fetching bank details. Please try again later.`);
              });
          }
        })
        .catch((err) => {
          setHttpCallInProgress(false);
          setErrorOccured(true);
          setMainText(`Error occured in fetching bank details. Please try again later.`);
        });
    } else {
      setHttpCallInProgress(false);
      setErrorOccured(true);
      setMainText(`Bank Accounts are not available in this country. Please try again later.`);
    }
  }, [paymentMethodListFetched, selectedFiatCurrency.country_code, orderId, props.history]);

  const handleBackButton = () => {
    props.history.goBack();
  }

  return (
    <Box className={props.classes.mainDiv}>
      <Grid container direction="column" className={props.classes.paymentMethodGrid} style={{ height: "30em" }}>
        <Grid item>
          <FormHeader customHandleBackButton={handleBackButton} titleText={`Bank Account`} history={props.history} />
        </Grid>
        <Grid item style={{ height: "120px" }}>

        </Grid>
        <Grid item style={{ height: "200px" }}>
          {httpCallInProgress ? <CustomLottie
            lottie={loadingBankAnimation}
            loop={true}
            width={180}
            hieght={180}
          /> : null}
          {errorOccured ? <CustomLottie
            lottie={paymentRejectedAnimation}
            loop={false}
            width={150}
            hieght={150}
          /> : null}
        </Grid>
        <Grid item style={{marginTop: "2em"}}>
          <Box overflow="auto" className={props.classes.mainTextGrid}>
            <Typography className={props.classes.mainText} color="textSecondary">
              {mainText}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withStyles(styles)(InitializeBankPaymentMethod);
