import React, { useState } from "react";
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import CustomFormButton from "widgets/CustomFormButton";

const styles = theme => ({
  submitButton: {
    paddingTop: '1em',
    width: '100%'
  },
});

function StripeCheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorText, setErrorText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://payfura.com/payment.html",
      },
      redirect: "if_required",
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setErrorText(error.message);
      } else {
        setErrorText("An unexpected error occurred. Please try again after some time or contact the team at team@payfura.com");
      }
  
      setIsLoading(false);
      return;
    }

    if (paymentIntent) {
      switch (paymentIntent.status) {
        case "succeeded":
          setErrorText("Payament succeeded!");
          props.history.push('/checkpayment');
          break;
        case "processing":
          setErrorText("Your payment is processing.");
          props.history.push('/checkpayment');
          break;
        case "requires_payment_method":
          setErrorText("Your payment was not successful, please try again.");
          props.history.push('/checkpayment');
          break;
        default:
        props.history.push('/checkpayment');
          setErrorText("Something went wrong.");
          break;
      }
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
    readOnly: !!errorText    
  }

  const onError = (e) => {
    setErrorText(`Error in initializing your payment. Our team is working on fixing it. Please retry after some time or contact the team at team@payfura.com.`);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* <LinkAuthenticationElement
        id="link-authentication-element"
        displayName="Optional"
        onChange={(e) => {
          // console.log(e, e.target);
          setEmail(e.value?.email )
        }}
      /> */}
      <Grid container style={{ height: "20px" }}></Grid>
      <PaymentElement id="payment-element" options={paymentElementOptions} onLoadError={onError} readOnly={errorText} />
      {isLoading ? <div className="spinner" id="spinner"></div> : ""}
      <Grid container style={{ height: "20px" }}></Grid>
      {!errorText ? <CustomFormButton id="submit" onClick={handleSubmit} disabled={isLoading || !stripe || !elements} buttonText={"Pay Now"} buttonClass={props.classes.submitButton} /> : null}
      {/* Show any error or success messages */}
      {errorText && <div id="payment-message">{errorText}</div>}
    </form>
  );
}

export default withStyles(styles)(StripeCheckoutForm);