import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import React from 'react';
import lodash from 'lodash';
import { useSelector } from 'react-redux';
import ApiConstant from 'lib/ApiConstant';
import OrderService from 'lib/services/OrderService';
import FormHeader from 'widgets/FormHeader';

const styles = theme => ({
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

function CustomPaymentPage(props) {
  const redirectLink = lodash.get(props, 'location.state.redirectLink');
  const successText = lodash.get(props, 'location.state.successText');
  const failureText = lodash.get(props, 'location.state.failureText');
  const orderId = useSelector(state => state.orderId);

  useEffect(() => {
    let intervalId = setInterval(function checkStatusIfTabInFocus() {
      if (document.visibilityState === `visible`) {
        OrderService.getOrderById(orderId)
          .then((response) => {
            const orderStatus = lodash.get(response, 'status');
            if ([`COMPLETED`, `PROCESSING_CRYPTO`, `RELEASING_CRYPTO`, `PAYMENT_SUCCESS`, `FAILED`].indexOf(orderStatus) > -1) {
              clearInterval(intervalId);
              props.history.push('/checkpayment', {
                successText,
                failureText,
              });
            }
          })
          .catch((e) => {
            clearInterval(intervalId);
          });
      }
    }, ApiConstant.getOrder.pollingIntervalInMs);
  }, [failureText, orderId, props.history, successText])

  const handleBackButton = () => {
    props.history.replace("/", {notKycRedirected: true});
  }

  return (
    <Box overflow="auto" style={mainDivStyle}>
      <Grid container direction="column">
        <Grid item>
          <FormHeader titleText="Payment Page" history={props.history} customHandleBackButton={handleBackButton} />
        </Grid>
      </Grid>
      <iframe src={redirectLink} title="Complete Payment" id="payment-iframe" loading="lazy" width="100%" height="100%"
        allow="accelerometer; autoplay; camera; gyroscope; payment" frameborder="0" class="sdk-iframe">
        Your browser does not support iframes.
      </iframe>
    </Box>
  )
}

const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '1.5em',
  height: "100%",
  width: "100%"
}

export default withStyles(styles)(CustomPaymentPage);
