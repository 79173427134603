import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import LoginRoute from './router/LoginRoute'
import MainForm from './components/MainForm'
import OrderDetail from './components/OrderDetail';
import './App.css'
import './selection_form_style.css'
import CustomLayout from 'widgets/CustomLayout';
class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <CustomLayout>
            <LoginRoute exact path="/" component={MainForm} />
            <Route exact path="/order" component={OrderDetail} />
          </CustomLayout>
        </Switch>
      </Router>
    )
  }
}

export default App
