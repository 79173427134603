import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import React from 'react';
import lodash from 'lodash';
import { useSelector } from 'react-redux';
import ApiConstant from 'lib/ApiConstant';
import OrderService from 'lib/services/OrderService';
import paymentCheckAnimationV2 from 'assets/animations/payment_check_v2.json';
import paymentSuccessAnimation from 'assets/animations/success.json';
import paymentRejectedAnimation from 'assets/animations/failed.json';
import CustomLottie from 'widgets/CustomLottie';
import CustomFormButton from 'widgets/CustomFormButton';

const styles = theme => ({
  header: {
    display: 'flex',
    height: '70px',
    backgroundColor: '#fbfcf8',
    marginLeft: '-1.5em',
    marginRight: '-1.5em',
    marginTop: '-1em',
  },
  text: {
    fontSize: 13
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "1em",
  },
  mainText: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
    color: theme.palette.secondary.main,
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  depositBankText: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  mainTextGrid: {
    padding: "15px",
    borderRadius: "10px",
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "20px"
  },
  accountTextGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  accountHeaderGrid: {
    justifyContent: 'flex-start',
    fontSize: "14px",
    color: "darkgray",
    fontWeight: "bold",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  bankDetailsGrid: {
    width: "100%",
    marginTop: "1em",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  bankDetailContainer: {
    paddingBottom: "14px",
  },
  bankDetailsMessage: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: "#e89713",
    fontWeight: "bold",
  }
});

function CheckPayment(props) {
  const redirectLink = lodash.get(props, 'location.state.redirectLink');
  const [showRedirectButton, setShowRedirectButton] = useState(redirectLink);
  const [paymentStatus, setPaymentStatus] = useState("PENDING");
  const [orderStatusState, setOrderStatusState] = useState("");
  const successText = lodash.get(props, 'location.state.successText');
  const mainTextFromCallingPage = lodash.get(props, 'location.state.mainTextFromCallingPage');
  const [mainText, setMainText] = useState(mainTextFromCallingPage || "Please stay on this screen after attempting payment. We are constantly checking your payment status.");
  const failureText = lodash.get(props, 'location.state.failureText');
  const bankDepositDetailsPresent = lodash.get(props, 'location.state.bankDepositDetailsPresent');
  const [showBankDetails, setShowBankDetails] = useState(bankDepositDetailsPresent);
  const bankDetails = lodash.get(props, 'location.state.bankDetails');
  const numericReference = lodash.get(props, 'location.state.numericReference');
  const orderId = useSelector(state => state.orderId);
  const fiatAmount = useSelector(state => state.fiatAmount);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const apiCheckoutFlow = useSelector(state => state.apiCheckoutFlow);
  const customWalletPartnerTransactionId = useSelector(state => state.customWalletPartnerTransactionId);
  const customWalletPartner = useSelector(state => state.customWalletPartner);

  useEffect(() => {
    function sendStellarNotification(newStatus) {
      if (customWalletPartner === `STELLAR`) {
        OrderService.notifyStellarCustomWalletTransaction({partnerTransactionId: customWalletPartnerTransactionId, newStatus})
        .then(() => {
          //
        })
      }
    }

    sendStellarNotification(`CHECKING_PAYMENT_STATUS`);
    let intervalId = setInterval(function checkStatusIfTabInFocus() {
      if (document.visibilityState === `visible` || apiCheckoutFlow) {
        OrderService.getOrderById(orderId)
          .then((response) => {
            const orderStatus = lodash.get(response, 'status');
            if ([`COMPLETED`, `PROCESSING_CRYPTO`, `RELEASING_CRYPTO`, `PAYMENT_SUCCESS`].indexOf(orderStatus) > -1) {
              sendStellarNotification(`PAYMENT_STATUS_SUCCESS`, orderStatus);
              setOrderStatusState(orderStatus);
              setMainText(successText || "Payment received successfully. We are initiating crypto transfer. " + (apiCheckoutFlow ? "You can close this window now." : `${customWalletPartner === `STELLAR` ? `You can close this window now and check the status of your order in the wallet app` : `You can check the status of your order in the My Orders section`}.`));
              setPaymentStatus('SUCCESS');
              setShowRedirectButton(false);
              setShowBankDetails(false);
              clearInterval(intervalId);
            } else if (orderStatus === `FAILED`) {
              sendStellarNotification(`PAYMENT_STATUS_FAILED`, orderStatus);
              setOrderStatusState(orderStatus);
              setMainText(failureText || "Your payment could not be completed. " + (apiCheckoutFlow ? " Please try again later." : `${customWalletPartner === `STELLAR` ? `You can close this window now and create a new transaction from the wallet app.` : `Check My Orders section for more details.`}`));
              setPaymentStatus('FAILED');
              setShowRedirectButton(false);
              setShowBankDetails(false);
              clearInterval(intervalId);
            } else {
              setOrderStatusState(orderStatus);
            }
          })
          .catch((e) => {
            clearInterval(intervalId);
            setMainText("We are still checking the status of your transaction in the backend. Please don't retry the payment yet.");
            setPaymentStatus('FAILED');
            setShowBankDetails(false);
            setShowRedirectButton(false);
          });
      }
    }, ApiConstant.getOrder.pollingIntervalInMs);
  }, [failureText, apiCheckoutFlow, orderId, successText, customWalletPartnerTransactionId, customWalletPartner])


  function getPaymentLottie() {
    if (paymentStatus === `SUCCESS`) {
      return (<CustomLottie
        lottie={paymentSuccessAnimation}
        loop={false}
        speed={2.0}
        width={100}
        height={100}
      />);
    } else if (paymentStatus === `FAILED`) {
      return (<CustomLottie
        lottie={paymentRejectedAnimation}
        loop={false}
        width={100}
        hieght={100}
      />);
    } else {
      return (<CustomLottie
        lottie={paymentCheckAnimationV2}
        loop={true}
        width={100}
        hieght={100}
      />);
    }
  }

  const handleOrderListClick = () => {
    props.history.replace("/orderlist", { callingPage: "CHECK_PAYMENT" });
  };

  const handleRedirectPaymentClick = () => {
    setShowRedirectButton(false);
    window.open(redirectLink, "_blank");
  }

  const generatePostMessage = async () => {
    if (window && (window.parent || window.opener)) {
      const messageData = {
        order: {
          id: orderId,
          status: orderStatusState,
        },
      }

      if (customWalletPartnerTransactionId) {
        messageData.partnerTransaction = {
          id: customWalletPartnerTransactionId,
        };
        if (orderStatusState === `FAILED`) {
          messageData.partnerTransaction.status = `error`;
        } else if ([`COMPLETED`, `PROCESSING_CRYPTO`, `RELEASING_CRYPTO`, `PAYMENT_SUCCESS`].indexOf(orderStatusState) > -1) {
          messageData.partnerTransaction.status = `pending_anchor`;
        }
      }

      if (customWalletPartnerTransactionId) {
        try {
          const result = await OrderService.getTransactionData(orderId);
          if (result?.transactionResponse) {
            const transactionResponse = result?.transactionResponse;
            delete transactionResponse.amount_expected;
            transactionResponse.to = transactionResponse.destination_account;
            delete transactionResponse.destination_account;

            if (transactionResponse?.amount_fee) {
              transactionResponse.amount_fee_asset = transactionResponse?.amount_fee?.asset;
              transactionResponse.amount_fee = transactionResponse?.amount_fee?.amount;
            }

            if (transactionResponse?.amount_in) {
              transactionResponse.amount_in_asset = transactionResponse?.amount_in?.asset;
              transactionResponse.amount_in = transactionResponse?.amount_in?.amount;
            }

            if (transactionResponse?.amount_out) {
              transactionResponse.amount_out_asset = transactionResponse?.amount_out?.asset;
              transactionResponse.amount_out = transactionResponse?.amount_out?.amount;
            }

            transactionResponse.refunded = false;
            messageData.transaction = transactionResponse;
          }
        } catch (e) {
          console.log(e);
        }
      }

      console.log('JSON', JSON.stringify(messageData), messageData);

      if (window.parent) {
        window.parent.postMessage(messageData, '*');
      }

      if (window.opener) {
        window.opener.postMessage(messageData, '*');
      }
    }
    setTimeout(() => {
      props.history.push("/", {notKycRedirected: true});
    }, 3000);
  }

  function getPaymentStatusAction() {
    if ((paymentStatus === `SUCCESS` || paymentStatus === `FAILED`) && !apiCheckoutFlow) {
      if (customWalletPartner === `STELLAR`) {
        return (<CustomFormButton onClick={generatePostMessage} buttonText="Close" />);
      }
      return (<CustomFormButton onClick={handleOrderListClick} buttonText="My Orders" />);
    } else {
      return (<div></div>);
    }
  }

  function getRedirectLink() {
    if (showRedirectButton && (paymentStatus !== `SUCCESS` || paymentStatus !== `FAILED`)) {
      return (
        <Grid className={props.classes.mainText} style={{ marginTop: "35%" }}>
          <Grid item>
            <Typography variant="caption">If you are not automatically redirected to payment's page, please click the button below</Typography>
            <Grid style={{ height: "10px" }}></Grid>
            <CustomFormButton onClick={handleRedirectPaymentClick} buttonText="Continue Payment" />
          </Grid>
        </Grid>
      );
    } else {
      return (<div></div>);
    }
  }

  function getAccountDetails() {
    if (showBankDetails && bankDepositDetailsPresent && bankDetails) {
      return (
        <Grid>
          <Grid item className={props.classes.mainTextGrid} style={{ backgroundColor: "#f6feff" }}>
            <Grid container className={props.classes.bankDetailContainer}>
              <Grid item xs={8}>
                <Typography className={props.classes.accountHeaderGrid}>Amount to Pay:</Typography>
              </Grid>
              <Grid item xs={4} className={props.classes.accountTextGrid}>
                <Typography component="h4" className={props.classes.depositBankText}>{selectedFiatCurrency.fiat_symbol}{fiatAmount}</Typography>
              </Grid>
            </Grid>
            <Grid container className={props.classes.bankDetailContainer}>
              <Grid item xs={8}>
                <Typography className={props.classes.accountHeaderGrid}>Payment Method:</Typography>
              </Grid>
              <Grid item xs={4} className={props.classes.accountTextGrid}>
                <Typography component="h4" className={props.classes.depositBankText}>{bankDetails.depositType}</Typography>
              </Grid>
            </Grid>
            {bankDetails.accountNumber ? <Grid container className={props.classes.bankDetailContainer}>
              <Grid item xs={8}>
                <Typography className={props.classes.accountHeaderGrid}>Account Number:</Typography>
              </Grid>
              <Grid item xs={4} className={props.classes.accountTextGrid}>
                <Typography component="h4" className={props.classes.depositBankText}>{bankDetails.accountNumber}</Typography>
              </Grid>
            </Grid> : null}
            {bankDetails.bankName ? <Grid container className={props.classes.bankDetailContainer}>
              <Grid item xs={8}>
                <Typography className={props.classes.accountHeaderGrid}>Bank Name:</Typography>
              </Grid>
              <Grid item xs={4} className={props.classes.accountTextGrid}>
                <Typography component="h4" className={props.classes.depositBankText}>{bankDetails.bankName}</Typography>
              </Grid>
            </Grid> : null}
            {bankDetails.accountHolderName ? <Grid container className={props.classes.bankDetailContainer}>
              <Grid item xs={8}>
                <Typography className={props.classes.accountHeaderGrid}>Account Holder Name:</Typography>
              </Grid>
              <Grid item xs={4} className={props.classes.accountTextGrid}>
                <Typography component="h4" className={props.classes.depositBankText}>{bankDetails.accountHolderName}</Typography>
              </Grid>
            </Grid> : null}
            {bankDetails.iban ? <Grid container className={props.classes.bankDetailContainer}>
              <Grid item xs={8}>
                <Typography className={props.classes.accountHeaderGrid}>IBAN:</Typography>
              </Grid>
              <Grid item xs={4} className={props.classes.accountTextGrid}>
                <Typography component="h4" className={props.classes.depositBankText}>{bankDetails.iban}</Typography>
              </Grid>
            </Grid> : null}
            {bankDetails.bic ? <Grid container className={props.classes.bankDetailContainer}>
              <Grid item xs={8}>
                <Typography className={props.classes.accountHeaderGrid}>BIC:</Typography>
              </Grid>
              <Grid item xs={4} className={props.classes.accountTextGrid}>
                <Typography component="h4" className={props.classes.depositBankText}>{bankDetails.bic}</Typography>
              </Grid>
            </Grid> : null}
            {numericReference ? <Grid container className={props.classes.bankDetailContainer}>
              <Grid item xs={8}>
                <Typography className={props.classes.accountHeaderGrid}>Reference Number:</Typography>
              </Grid>
              <Grid item xs={4} className={props.classes.accountTextGrid}>
                <Typography component="h4" className={props.classes.depositBankText}>{numericReference}</Typography>
              </Grid>
            </Grid> : null}
            {bankDetails.routingCodes?.length ?
              <Typography className={props.classes.accountHeaderGrid}>Routing Codes:</Typography>
            : null}
            {bankDetails.routingCodes?.length ?
              (
                bankDetails.routingCodes.map((code) =>
                  <React.Fragment key={code.type}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography className={props.classes.accountHeaderGrid}>{code.type?.replace("_", " ")}</Typography>
                      </Grid>
                      <Grid item xs={4} className={props.classes.accountTextGrid}>
                        <Typography component="h4" className={props.classes.depositBankText}>{code.value}</Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )) : null}
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item>
              <Typography className={props.classes.bankDetailsMessage}>Please make sure that you send the exact amount mentioned above and enter the reference number accurately.</Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Box style={mainDivStyle}>
      <Grid container direction="column" className={props.classes.mainGrid}>
        {!showBankDetails ? <Grid item style={{ height: "140px" }}></Grid> : null}
        <Grid item className={props.classes.lottie}>
          {getPaymentLottie()}
        </Grid>
        <Grid item>
          <span className={props.classes.mainText}>{mainText}</span>
        </Grid>
        <Grid item className={props.classes.actionButton}>
          {getPaymentStatusAction()}
        </Grid>
        <Grid item className={props.classes.actionButton}>
          {getRedirectLink()}
        </Grid>
        <Grid item className={props.classes.bankDetailsGrid}>
          {getAccountDetails()}
        </Grid>
      </Grid>
    </Box>
  )
}

const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '0.5em',
}

export default withStyles(styles)(CheckPayment);
