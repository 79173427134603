const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const PATCH = "PATCH";

class ApiConstant {

  static get fiatCurrencyList() {
    return {
      url: '/v2/fiat_currency',
      method: GET
    };
  }

  static get cryptoCurrencyList() {
    return {
      url: '/v1/crypto_currencies',
      method: GET
    };
  }

  static get pricingDetails() {
    return {
      url: '/v1/pricing',
      method: POST
    };
  }

  static get requestVerificationCode() {
    return {
      url: '/v1/user/register',
      method: POST
    };
  }

  static get validateVerificationCode() {
    return {
      url: '/v1/user/login',
      method: POST
    };
  }

  static get decodeQueryParams() {
    return {
      url: '/v1/decode/params',
      method: POST
    };
  }

  static get submitUserProfile() {
    return {
      url: '/v1/user',
      method: PUT
    };
  }
  
  static get submitUserAddress() {
    return {
      url: '/v1/address',
      method: POST
    };
  }
  
  static get logout() {
    return {
      url: '/v1/user/logout',
      method: POST
    };
  }

  static get getUserProfile() {
    return {
      url: '/v1/user',
      method: GET
    };
  }

  static get generateSdKToken() {
    return {
      url: '/v1/kyc/sdk_token',
      method: POST
    };
  }

  static get createFiatPartnerUser() {
    return {
      url: '/v1/fiat-partner/user',
      method: POST
    };
  }

  static get logAnalyticsInfo() {
    return {
      url: '/v1/log/analytics/info',
      method: POST
    };
  }

  static get createPartnerAgreement() {
    return {
      url: '/v1/fiat-partner/user-agreement',
      method: POST
    };
  }

  static get updatePartnerAgreement() {
    return {
      url: '/v1/fiat-partner/user-agreement',
      method: PATCH
    };
  }


  static get getFiatPartnerUser() {
    return {
      url: '/v1/fiat-partner/user',
      method: GET
    };
  }
  
  static get submitDocumentInfo() {
    return {
      url: '/v1/kyc/document_info',
      method: POST
    };
  }

  static get logKycAnalytics() {
    return {
      url: '/v1/log/kyc/analytics',
      method: POST
    };
  }
  
  static get uploadKycDocument() {
    return {
      url: '/v1/kyc/upload/document',
      method: POST
    };
  }

  static get getDocumentInfoStatus() {
    return {
      url: '/v1/kyc/document_info',
      method: GET
    };
  }

  static get getKycStatus() {
    return {
      url: '/v1/kyc/check',
      method: GET,
      pollingIntervalInMs: 3000
    };
  }
  
  static get checkHigherLimitStatus() {
    return {
      url: '/v1/user/higher_limit/kyc',
      method: GET,
      pollingIntervalInMs: 3000
    };
  }

	static get orderCreate() {
    return {
      url: '/v1/order',
      method: POST
    };
  }
  
  static get getOrder() {
    return {
      url: '/v1/order',
      method: GET,
      pollingIntervalInMs: 5000
    };
  }

  static get getTransactionData() {
    return {
      url: '/v1/order/partner/transaction/:order_id',
      method: GET,
    };
  }

  static get getPartnerOrder() {
    return {
      url: '/v1/partner/order/detail',
      method: GET,
    };
  }

  static get verifyStellarCustomWalletTransaction() {
    return {
      url: '/v1/stellar/custom/wallet/verify',
      method: POST,
    };
  }

  static get notifyStellarCustomWalletTransaction() {
    return {
      url: '/v1/stellar/custom/wallet/notify',
      method: POST,
    };
  }
  
  static get validateAddressStellarCustomWalletTransaction() {
    return {
      url: '/v1/stellar/custom/wallet/validateAddress',
      method: POST,
    };
  }

  static get getStellarCustomWalletTransaction() {
    return {
      url: '/v1/stellar/custom/wallet/order',
      method: GET,
    };
  }

  static get updatePartnerOrder() {
    return {
      url: '/v1/partner/order',
      method: PATCH,
    };
  }
  
  static get requestOrderVerification() {
    return {
      url: '/v1/partner/order/verify/request',
      method: POST,
    };
  }

  static get verifyOrder() {
    return {
      url: '/v1/partner/order/verify',
      method: POST,
    };
  }
  
  static get createFiatPayment() {
    return {
      url: '/v1/fiat-payment',
      method: POST
    };
  }

  static get createCryptoPayment() {
    return {
      url: '/v1/crypto-payment',
      method: POST
    };
  }

  static get fulfilOrder() {
    return {
      url: '/v1/order/fulfil',
      method: POST
    };
  }

  static get authorizePayment() {
    return {
      url: '/v1/fiat-payment/authorize',
      method: POST
    };
  }

  static get addBankAccount() {
    return {
      url: '/v1/fiat-payment/add',
      method: POST
    };
  }
  
  static get generateCryptoWalletForSell() {
    return {
      url: '/v1/fiat-payment/cryptowallet',
      method: POST
    };
  }
  
  static get getPaymentMethods() {
    return {
      url: '/v1/fiat-payment/transfer_method',
      method: GET
    };
  }

  static get createPaymentMethod() {
    return {
      url: '/v1/fiat-payment/transfer_method',
      method: POST
    };
  }
  
  static get patchPaymentMethod() {
    return {
      url: '/v1/fiat-payment/transfer_method',
      method: PATCH
    };
  }

}

export default ApiConstant
