import { Box, Grid, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useState } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import UserService from '../../lib/services/UserService';
import FormHeader from 'widgets/FormHeader';
import CustomFormTextField from 'widgets/CustomFormTextField';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import lodash from 'lodash';
import { setUserProfile } from 'actions';
import { useTheme } from '@material-ui/core/styles';
import Fade from '@mui/material/Fade';
import MaterialInput from '@material-ui/core/Input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    display: 'flex'
  },
  avatar: {
    width: '25px',
    height: '25px',
    marginRight: '0.6em',
  },
  menuItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  errorDiv: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
    },
  },
  fieldDiv: {
    marginBottom: '16px',
  },
  label: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
  labelSubText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark,
    fontSize: "12px",
    marginLeft: "5px",
    float: "right"
  },
  errorText: {
    fontSize: "14px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  submitButton: {
    marginTop: '5em'
  }
});

function UserProfile(props) {
  const theme = useTheme();
  const userProfile = useSelector(state => state.userProfile);

  const [isdCode, setIsdCode] = useState(userProfile.isdCode || "");
  const [phoneNumber, setPhoneNumber] = useState(userProfile.phoneNumber || "");
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const [phoneNumberCountry, setPhoneNumberCountry] = useState(userProfile.phoneNumberCountry || selectedFiatCurrency?.country_code?.toLowerCase() ||  "");
  const [firstName, setFirstName] = useState(userProfile.firstName || "");
  const [lastName, setLastName] = useState(userProfile.lastName || "");
  const [dateOfBirth, setDateOfBirth] = useState(userProfile.dateOfBirth || "");
  const [invalidFirstName, setInvalidFirstName] = useState(false)
  const [invalidLastName, setInvalidLastName] = useState(false)
  const [invalidDOB, setInvalidDOB] = useState(false)
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false)
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);

  const dispatch = useDispatch();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleDateOfBirthChange = (event) => {
    setDateOfBirth(event.target.value);
  };

  const getValidationError = (params) => {
    let validForm = true;
    if (!firstName) {
      setInvalidFirstName(true);
      setErrorText("Please enter first name");
      validForm = false;
      return;
    } else {
      setInvalidFirstName(false);
    }

    if (!lastName) {
      setInvalidLastName(true);
      setErrorText("Please enter last name");
      validForm = false;
      return;
    } else {
      setInvalidLastName(false);
    }

    if (!dateOfBirth || !(moment(dateOfBirth, 'YYYY-MM-DD').isValid())) {
      setInvalidDOB(true);
      setErrorText("Please select a date of birth");
      validForm = false;
      return;
    } else {
      setInvalidDOB(false);
    }

    if (!isdCode || !(isdCode.startsWith("+") && isdCode.length >= 2)) {
      setInvalidPhoneNumber(true);
      setErrorText("Please enter a valid isd code");
      validForm = false;
    } else {
      setInvalidPhoneNumber(false);
    }

    if (!phoneNumber || !(/^[0-9]{5,20}$/.test(phoneNumber))) {
      setInvalidPhoneNumber(true);
      setErrorText("Please enter a phone number");
      validForm = false;
    } else {
      setInvalidPhoneNumber(false);
    }

    return validForm;
  }

  const handleSubmit = (event) => {
    setErrorText("");
    const body = {
      firstName, lastName, isdCode, phoneNumber, dateOfBirth, phoneNumberCountry
    };
    const isValidForm = getValidationError(body);
    if (!isValidForm) {
      return;
    }
    setHttpCallInProgress(true);

    UserService.submitUserProfile(body)
      .then((data) => {
        setHttpCallInProgress(false);
        if (data && data.status === `success`) {
          setErrorText("User profile submitted successfully");
          dispatch(setUserProfile({
            firstName: body.firstName,
            lastName: body.lastName,
            dateOfBirth: dateOfBirth,
            isdCode: body.isdCode,
            phoneNumber: body.phoneNumber,
            phoneNumberCountry: body.phoneNumberCountry,
            email: data.response?.email,
          }));
          if (lodash.get(props, 'location.state.callingPage') === `CONNECT_BANK_ACCOUNT`) {
            props.history.push('/connectbankaccount');
            return;
          } else if (lodash.get(props, 'location.state.callingPage') === `CHECK_FIAT_PARTNER_USER_STATUS`) {
            props.history.push('/checkfiatpartneruserstatus');
            return;
          } else if (lodash.get(props, 'location.state.callingPage') === `MENU_PAGE` || lodash.get(props, 'location.state.callingPage') === `CHECK_KYC`) {
            props.history.goBack();
            return;
          }
          props.history.push('/addressform');
        } else {
          setErrorText("Something went wrong. Please try again later.");
        }
      })
      .catch((err) => {
        const errorMessage = lodash.get(err, 'response.data.message', null);
        setHttpCallInProgress(false);
        setErrorText(errorMessage || "Something went wrong. Please try again later.");
      })
  }

  return (
    <Fade in={true} timeout={800}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <FormHeader titleText="User Profile" history={props.history} />
              </Grid>
              <Grid item style={{ height: "20px" }}></Grid>
            </Grid>
            <Grid container className={props.classes.fieldDiv}>
              <Grid item xs={12} sm={12}>
                <CustomFormTextField
                  labelText="First Name*"
                  width="100%"
                  value={firstName}
                  error={invalidFirstName}
                  onChange={handleFirstNameChange}
                />
              </Grid>
            </Grid>
            <Grid container className={props.classes.fieldDiv}>
              <Grid item xs={12} sm={12}>
                <CustomFormTextField
                  labelText="Last Name*"
                  width="100%"
                  value={lastName}
                  error={invalidLastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
            </Grid>
            <Grid container className={props.classes.fieldDiv}>
              <Grid item xs={12} sm={12}>
                <FormLabel className={props.classes.label}>Phone Number*</FormLabel>
                <PhoneInput
                  country={phoneNumberCountry}
                  searchPlaceholder="search"
                  enableSearch
                  inputProps={{ style: { padding: "25px", borderRadius: "8px", width: "100%", paddingLeft: "60px", fontSize: "16px", color: theme.palette.secondary.dark, fontFamily: "whitneymedium,muli,system-ui,Helvetica,sans-serif" } }}
                  value={`+${isdCode}${phoneNumber}`}
                  onChange={(phone, country) => {
                    setPhoneNumberCountry(country.countryCode);
                    setPhoneNumber(phone?.replace(country.dialCode, ""));
                    setIsdCode(`+${country.dialCode}`);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={props.classes.fieldDiv} direction="column">
              <Grid item xs={12} sm={12}>
                <FormLabel className={props.classes.label}>{"Date of Birth*"}</FormLabel>
                <FormLabel className={props.classes.labelSubText}>{"DD/MM/YYYY"}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MaterialInput
                  type="date"
                  style={{ border: "1px solid grey", height: "20px", borderRadius: "8px", padding: "27px", paddingLeft: "10px", width: "100%", paddingRight: "20px", color: "grey" }}
                  variant="outlined"
                  disableUnderline
                  onChange={handleDateOfBirthChange}
                  value={dateOfBirth}
                  error={invalidDOB}
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid item className={props.classes.errorDiv}>
            <FormLabel className={props.classes.errorText}>{errorText}</FormLabel>
          </Grid>
          <Box className={props.classes.submitButton}>
            {httpCallInProgress ? <CustomLinearProgressBar /> : null}
            <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress} buttonText="Submit" />
          </Box>
        </Grid>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(UserProfile);
