import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import ApiConstant from 'lib/ApiConstant';
import kycCheckAnimation from 'assets/animations/kyc_check.json';
import kycSuccessAnimation from 'assets/animations/success.json';
import kycRejectedAnimation from 'assets/animations/failed.json';
import CustomLottie from 'widgets/CustomLottie';
import CustomFormButton from 'widgets/CustomFormButton';
import UserService from 'lib/services/UserService';
import OrderService from 'lib/services/OrderService';
import lodash from 'lodash';

const styles = theme => ({
  header: {
    display: 'flex',
    height: '70px',
    backgroundColor: '#fbfcf8',
    marginLeft: '-1.5em',
    marginRight: '-1.5em',
    marginTop: '-1em',
  },
  text: {
    fontSize: 13
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    marginTop: "20%",
  },
  mainText: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "20px"
  }
});

function CheckKyc(props) {
  const [mainText, setMainText] = useState("We are verifying the documents submitted by you. Please stay on this screen.");
  const [kycStatus, setKycStatus] = useState("PENDING");
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const customWalletPartnerTransactionId = useSelector(state => state.customWalletPartnerTransactionId);
  const customWalletPartner = useSelector(state => state.customWalletPartner);
  const proofOfAddressContext = lodash.get(props, 'location.state.proofOfAddressContext');

  useEffect(() => {
    function sendStellarNotification(newStatus) {
      if (customWalletPartner === `STELLAR` && !proofOfAddressContext) {
        OrderService.notifyStellarCustomWalletTransaction({partnerTransactionId: customWalletPartnerTransactionId, newStatus})
        .then(() => {
          //
        })
      }
    }

    let intervalId = setInterval(function checkStatusIfTabInFocus() {
      if (document.visibilityState === `visible`) {
        UserService.getUserProfile()
          .then((data) => {
            if (data && data.status === `success` && data.response) {
              const user = data.response.user || {};
              if (user.kycStatus === `VERIFIED` && (!proofOfAddressContext || user.kycPoaStatus === `VERIFIED`)) {
                clearInterval(intervalId);
                sendStellarNotification(`KYC_${user.kycStatus}`);
                if (selectedPaymentMethod.fiat_user_required) {
                  props.history.push('/createpartneruser', {autoSubmitUserCreation: true});
                  return;
                } else {
                  setMainText(proofOfAddressContext ? "Proof of address validated successfully" : "KYC Successfully Completed. Redirecting...");
                  setKycStatus("SUCCESS");
                  setTimeout(() => {
                    props.history.push('/ordersummary');
                  }, 3000);
                  return;
                }
              } else if (user.kycStatus === `REJECTED` || (user.kycStatus === `VERIFIED` && proofOfAddressContext && user.kycPoaStatus === `REJECTED`)) {
                clearInterval(intervalId);
                let rejectMainText = `Your KYC could not be verified. Please try again or reach out to team@payfura.com`;
                if (user.kycStatus === `VERIFIED` && proofOfAddressContext && user.kycPoaStatus === `REJECTED`) {
                  rejectMainText = `Your Proof of Address could not be verified. Please reach out to team@payfura.com`;
                }
                setMainText(rejectMainText);
                setKycStatus("FAILED");
                sendStellarNotification(`KYC_${user.kycStatus}`);
              } else if (user.kycStatus === `PENDING`) {
                sendStellarNotification(`KYC_${user.kycStatus}`);
              }
            }
          })
          .catch((e) => {

          })
      }
    }, ApiConstant.getKycStatus.pollingIntervalInMs);

  }, [customWalletPartner, customWalletPartnerTransactionId, proofOfAddressContext, props.history, selectedPaymentMethod.fiat_user_required]);

  function getKycLottie() {
    if (kycStatus === `SUCCESS`) {
      return (<CustomLottie
        lottie={kycSuccessAnimation}
        loop={false}
        speed={2.0}
        width={120}
        height={120}
      />);
    } else if (kycStatus === `FAILED`) {
      return (<CustomLottie
        lottie={kycRejectedAnimation}
        loop={false}
        width={120}
        height={120}
      />);
    } else {
      return (<CustomLottie
        lottie={kycCheckAnimation}
        loop={true}
        width={120}
        height={120}
      />);
    }
  }

  const handleGoHome = () => {
    props.history.replace("/", {notKycRedirected: true});
  };

  function getKycStatusAction() {
    if (kycStatus === `FAILED`) {
      return (<CustomFormButton onClick={handleGoHome} buttonText="Go Home" />);
    } else {
      return (<div></div>);
    }
  }

  return (
    <Box style={mainDivStyle}>
      <Grid container direction="column" className={props.classes.mainGrid}>
        <Grid item className={props.classes.lottie}>
          {getKycLottie()}
        </Grid>
        <Grid item>
          <span className={props.classes.mainText}>{mainText}</span>
        </Grid>
        <Grid item className={props.classes.actionButton}>
          {getKycStatusAction()}
        </Grid>
      </Grid>
    </Box>
  )
}


const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '1em',
}

export default withStyles(styles)(CheckKyc);
