import { Box, Grid, FormLabel } from '@material-ui/core';
import { getData } from 'country-list';
import { withStyles } from '@material-ui/styles';
import { useState } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import UserService from '../../lib/services/UserService';
import FormHeader from 'widgets/FormHeader';
import CustomFormTextField from 'widgets/CustomFormTextField';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import lodash from 'lodash';
import { setUserAddress } from '../../actions'
import { useSelector, useDispatch } from 'react-redux';
import Fade from '@mui/material/Fade';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em'
  },
  submitButton: {
    marginTop: "35px"
  },
  menuItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  errorDiv: {
    marginBottom: "15px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    height: "20px"
  },
  fieldDiv: {
    marginBottom: '15px'
  },
  label: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark,
  },
  errorText: {
    fontSize: "14px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  }
});

function AddressForm(props) {
  const userAddress = useSelector(state => state.userAddress);
  const transactionType = useSelector(state => state.transactionType);
  const [state, setState] = useState(userAddress.state);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const [country, setCountry] = useState(userAddress.country || selectedFiatCurrency?.country_code ||  "US");
  const [addressLine1, setAddressLine1] = useState(userAddress.addressLine1);
  const [addressLine2, setAddressLine2] = useState(userAddress.addressLine2);
  const [city, setCity] = useState(userAddress.city);
  const [postalCode, setPostalCode] = useState(userAddress.postalCode);
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const dispatch = useDispatch();

  const [errorText, setErrorText] = useState("");

  const handleAddressLine1Change = (event) => {
    setAddressLine1(event.target.value);
  };

  const handleAddressLine2Change = (event) => {
    setAddressLine2(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleStateChange = (value) => {
    setState(value);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };

  let countryList = getData();

  countryList.sort((a, b) => {
    return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
  });

  const getValidationError = (params) => {
    let validForm = true;
    if (!addressLine1) {
      setErrorText("Please enter your main address");
      validForm = false;
      return;
    }

    if (!country) {
      setErrorText("Please select a country");
      validForm = false;
      return;
    }

    if (!city) {
      setErrorText("Please enter your city");
      validForm = false;
      return;
    }
    if (!state) {
      setErrorText("Please select a state");
      validForm = false;
      return;
    }

    if (!postalCode) {
      setErrorText("Please enter your postal code");
      validForm = false;
      return;
    }

    if (country === `US` && [`NY`, `SD`, `HI`, `CT`, `NC`, `TX`].indexOf(state) > -1) {
      setErrorText(`We are currently not able to support residents in ${state}.`);
      validForm = false;
      return;
    }

    if (country === `US` && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode)) {
      setErrorText(`Please enter a valid US postal code.`);
      validForm = false;
      return;
    }

    return validForm;
  }

  const handleSubmit = (event) => {
    setErrorText("");
    const body = {
      addressLine1, addressLine2, state, country, city, postalCode
    };
    const isValidForm = getValidationError(body);
    if (!isValidForm) {
      return;
    }
    setHttpCallInProgress(true);

    UserService.submitUserAddress(body)
      .then((data) => {
        setHttpCallInProgress(false);

        if (data && data.success) {
          dispatch(setUserAddress(body));
          if (lodash.get(props, 'location.state.callingPage') === `CARD_DETAIL`) {
            props.history.push('/carddetail');
            return;
          } else if (lodash.get(props, 'location.state.callingPage') === `CONNECT_BANK_ACCOUNT`) {
            props.history.push('/connectbankaccount');
            return;
          } else if (lodash.get(props, 'location.state.callingPage') === `CHECK_FIAT_PARTNER_USER_STATUS`) {
            props.history.push('/checkfiatpartneruserstatus');
            return;
          } else if (lodash.get(props, 'location.state.callingPage') === `MENU_PAGE` || lodash.get(props, 'location.state.callingPage') === `CHECK_KYC`) {
            props.history.goBack();
            return;
          }
          UserService.getUserProfile()
            .then((data) => {
              if (data && data.status === `success` && data.response) {
                const user = data.response.user || {};
                if (user.kycStatus === `VERIFIED`) {
                  if (transactionType === `SELL`) {
                    props.history.push('/connectbankaccount');
                  } else {
                    props.history.push('/documentinfoform');
                  }
                  return;
                } else {
                  props.history.push('/documentinfoform');
                }
              } else {
                setErrorText("Error in updating address. Please try again later.");
              }
            })
            .catch((err) => {
              const errorMessage = lodash.get(err, 'response.data.message', null);
              setErrorText(errorMessage || "Something went wrong. Please try again later.");
            });
        }
      })
      .catch((err) => {
        setHttpCallInProgress(false);
        const errorMessage = lodash.get(err, 'response.data.message', null);
        setErrorText(errorMessage || "Error in updating address. Please try again later.");
      })
  }

  return (
    <Fade in={true} timeout={800}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <FormHeader titleText="Address" history={props.history} />
          </Grid>
          <Grid item style={{ height: "40px" }}></Grid>
        </Grid>
        <Grid container className={props.classes.fieldDiv}>
          <Grid item xs={12} sm={12}>
            <CustomFormTextField
              labelText="Address Line 1*"
              width="100%"
              value={addressLine1}
              onChange={handleAddressLine1Change}
            />
          </Grid>
        </Grid>
        <Grid container className={props.classes.fieldDiv}>
          <Grid item xs={12} sm={12}>
            <CustomFormTextField
              labelText="Address Line 2 (optional)"
              width="100%"
              value={addressLine2}
              onChange={handleAddressLine2Change}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={props.classes.fieldDiv}>
          <Grid item xs={6} sm={6}>
            <Grid container direction="column">
              <Grid item>
                <FormLabel className={props.classes.label}>Country*</FormLabel>
              </Grid>
              <Grid item>
                <CountryDropdown
                  style={{ padding: "15px", borderRadius: "8px", width: "100%", color: "grey", fontSize:"14px" }}
                  value={country}
                  disabled={selectedFiatCurrency?.country_code === "US"}
                  valueType={"short"}
                  blacklist={["RU"]}
                  onChange={handleCountryChange} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Grid container direction="column">
              <Grid item>
                <FormLabel className={props.classes.label}>State*</FormLabel>
              </Grid>
              <Grid item>
                <RegionDropdown
                  style={{ padding: "15px", borderRadius: "8px", width: "100%", color: "grey", fontSize:"14px" }}
                  disableWhenEmpty={true}
                  countryValueType="short"
                  country={country}
                  valueType="short"
                  value={state}
                  blankOptionLabel={"Select State"}
                  defaultOptionLabel={"Select State"}
                  onChange={handleStateChange} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <CustomFormTextField
              labelText="City*"
              width="100%"
              value={city}
              onChange={handleCityChange}
              placeholder=""
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <CustomFormTextField
              labelText="Postal Code*"
              width="100%"
              value={postalCode}
              onChange={handlePostalCodeChange}
              placeholder=""
            />
          </Grid>
        </Grid>
        <Box className={props.classes.submitButton}>
          <Grid item className={props.classes.errorDiv}>
            {errorText ? <FormLabel className={props.classes.errorText}>{errorText}</FormLabel> : ''}
          </Grid>
          {httpCallInProgress ? <CustomLinearProgressBar /> : null}
          <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress} buttonText="Submit" />
        </Box>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(AddressForm);
