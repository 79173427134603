const apiCheckoutFlowReducer = (state = null, action) => {
  switch (action.type) {
      case 'SET_API_CHECKOUT_FLOW':
          return action.payload;

      default:
          return state;
  }
}


export default apiCheckoutFlowReducer;
