import { withStyles } from '@material-ui/styles';
import { Box, List, ListItem, Grid, ListItemText, ListItemIcon, IconButton } from '@material-ui/core';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LoginIcon from '@mui/icons-material/Login';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import FormHeader from 'widgets/FormHeader';
import UserService from 'lib/services/UserService';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { setToken } from 'lib/Request';
import { useSelector, useDispatch } from 'react-redux';
import Fade from '@mui/material/Fade';
import { setWalletAddress } from 'actions';
import Config from '../../config';


const styles = theme => ({
  mainDiv: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%'
  },
  listItem: {
    borderRadius: "20px",
    // margin: "4px",
    // padding: "1em"
  },
  listItemText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.main
  },
  footer: {
    marginTop: "280px"
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  arrowIcon: {
    color: theme.palette.secondary.main,
    fontSize: "12x"
  },
  wrappingGrid: {
    maxHeight: '60vh',
    overflow: 'auto',
    overflowX: "hidden",
    borderRadius: "10px",
    marginRight: "20px"
  }
});

function MenuPage(props) {
  const dispatch = useDispatch();
  const userLoggedIn = useSelector(state => state.userLoggedIn);
  const customWalletPartner = useSelector(state => state.customWalletPartner);

  const handleOrderList = () => {
    props.history.push('/orderlist');
  }

  const handleUserProfile = () => {
    props.history.push('/userprofile', { callingPage: "MENU_PAGE" });
  }

  const handleAddress = () => {
    props.history.push('/addressform', { callingPage: "MENU_PAGE" });
  }

  const handleHomeButton = () => {
    if (customWalletPartner?.toUpperCase() === `LEDGER`) {
      dispatch(setWalletAddress(""));
    }
    props.history.replace('/', {notKycRedirected: true});
  }

  const handleLogout = () => {
    UserService.logout()
      .then((data) => {
        setToken(null);
        localStorage.removeItem(Config.atTokenName);
        props.history.push("/", {notKycRedirected: true});
      })
      .catch((err) => {
        props.history.push("/", {notKycRedirected: true});
      })
  }

  const handleLogin = () => {
    props.history.replace('/emailform', { callingPage: "MENU_PAGE" });
  }

  const handleAbout = () => {
    window.open("https://payfura.com", '_blank');
  }

  return (
    <Fade in={true} timeout={500}>
      <Box overflow="auto" className={props.classes.mainDiv}>
        <Grid container direction="column">
          <FormHeader titleText={`Menu`} history={props.history} hideMenuButton={true} />
        </Grid>
        <Grid container direction="column">
          <Grid item style={{ height: "20px" }}></Grid>
          <Grid item className={props.classes.wrappingGrid}>
            <List component="nav">
              <ListItem button className={props.classes.listItem} onClick={handleHomeButton}>
                <ListItemIcon>
                  <HomeOutlinedIcon className={props.classes.icon} />
                </ListItemIcon>
                <ListItemText primary="Home" className={props.classes.listItemText} />
                <IconButton edge="end" aria-label="comments">
                  <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
                </IconButton>
              </ListItem>
              {!userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleLogin}>
                <ListItemIcon>
                  <LoginIcon className={props.classes.icon} />
                </ListItemIcon>
                <ListItemText primary="Login" className={props.classes.listItemText} />
                <IconButton edge="end" aria-label="comments" onClick={handleLogin}>
                  <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
                </IconButton>
              </ListItem> : null}
              {userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleOrderList}>
                <ListItemIcon>
                  <ReceiptOutlinedIcon className={props.classes.icon} />
                </ListItemIcon>
                <ListItemText primary="My Orders" className={props.classes.listItemText} />
                <IconButton edge="end" aria-label="comments">
                  <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
                </IconButton>
              </ListItem> : null}
              {userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleUserProfile}>
                <ListItemIcon>
                  <AccountCircleOutlinedIcon className={props.classes.icon} />
                </ListItemIcon>
                <ListItemText primary="My Profile" className={props.classes.listItemText} />
                <IconButton edge="end" aria-label="comments">
                  <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
                </IconButton>
              </ListItem> : null}
              {userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleAddress}>
                <ListItemIcon>
                  <BusinessOutlinedIcon className={props.classes.icon} />
                </ListItemIcon>
                <ListItemText primary="My Address" className={props.classes.listItemText} />
                <IconButton edge="end" aria-label="comments">
                  <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
                </IconButton>
              </ListItem> : null}
              {userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToAppOutlinedIcon className={props.classes.icon} />
                </ListItemIcon>
                <ListItemText primary="Logout" className={props.classes.listItemText} />
                <IconButton edge="end" aria-label="comments" onClick={handleLogout}>
                  <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
                </IconButton>
              </ListItem> : null}
              <ListItem button className={props.classes.listItem} onClick={handleAbout}>
                <ListItemIcon>
                  <ExitToAppOutlinedIcon className={props.classes.icon} />
                </ListItemIcon>
                <ListItemText primary="About Payfura" className={props.classes.listItemText} />
                <IconButton edge="end" aria-label="comments" onClick={handleAbout}>
                  <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
                </IconButton>
              </ListItem>
              {/* <ListItem button className={props.classes.listItem} onClick={handlePrivacyPolicy}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments" onClick={handlePrivacyPolicy}>
                <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
              </IconButton>
            </ListItem>
            <ListItem button className={props.classes.listItem} onClick={handleTermsOfUse}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Terms of Use" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments" onClick={handleTermsOfUse}>
                <ArrowForwardIosOutlinedIcon className={props.classes.arrowIcon} />
              </IconButton>
            </ListItem> */}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(MenuPage);
