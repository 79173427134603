import FormHeader from '../../widgets/FormHeader';
import DocumentIdField from './widgets/DocumentIdField';
import CustomFormButton from '../../widgets/CustomFormButton';
import { Grid, Box, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import Fade from '@mui/material/Fade';
import KycService from 'lib/services/KycService';
import lodash from 'lodash';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  spaceGridItem: {
    height: "40px"
  },
  errorGridItem: {
    height: '3px'
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  CustomFormButton: {
    marginTop: '15em'
  }
});

function DocumentInfoForm(props) {
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const [documentId, setDocumentId] = useState();
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const [documentType, setDocumentType] = useState();
  const [allDocumentTypes, setAllDocumentTypes] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [redirectToSocure, setRedirectToSocure] = useState(false);
  const callingPage = lodash.get(props, 'location.state.callingPage');
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);

  useEffect(() => {
    if (callingPage === `CHECK_FIAT_PARTNER_USER_STATUS`) {
      let validDocumentTypes = [{"name": "SSN Number", "type": "SSN"}];
      setAllDocumentTypes(validDocumentTypes);
      setDocumentType(validDocumentTypes[0]?.type);
      setLoadingData(false);
      return;
    }
    KycService.getDocumentInfoStatus(selectedFiatCurrency.country_code, selectedPaymentMethod.cfpm_id)
      .then((response) => {
        const data = response?.response;
        if (response?.status === `ALREADY_SUBMITTED`) {
          if (data.askForSocureDocument) {
            props.history.push('/kycformsocure');
            return;
          }
          if (data.askForPlatformKyc) {
            props.history.push('/kycform');
            return;
          }
          if (data.askForProofOfAddress) {
            props.history.push('/kycform', {proofOfAddressContext: true});
            return;
          }
          props.history.push('/ordersummary');
          return;
        }
        if (data.askForSocureDocument) {
          setRedirectToSocure(true);
        }
        const validDocumentTypes = data.documentTypes;
        if (validDocumentTypes?.length) {
          setAllDocumentTypes(validDocumentTypes);
          setDocumentType(validDocumentTypes[0]?.type);
          setLoadingData(false);
        } else {
          if (data.askForPlatformKyc) {
            props.history.push('/kycform');
            return;
          }
          if (data.askForProofOfAddress) {
            props.history.push('/kycform', {proofOfAddressContext: true});
            return;
          }
          props.history.push('/ordersummary');
          return;
        }
      })
      .catch((e) => {
      });
  }, [callingPage, props.history, selectedFiatCurrency.country_code, selectedPaymentMethod.cfpm_id]);

  const onClick = () => {
    setErrorText("");
    if (!documentType || !documentId) {
      setErrorText("* Please enter a valid document number");
      return;
    }

    if (documentType === `SSN`) {
      const ssnRegex =
      /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/;
      if (!(ssnRegex.test(String(documentId)))) {
        setErrorText("* Please enter a valid 9 digit SSN Number");
        return;
      }
    }
    setHttpCallInProgress(true);

    const body = {
      documentType,
      documentId,
      countryCode: selectedFiatCurrency.country_code,
    };

    KycService.submitDocumentInfo(body)
      .then(() => {
        setHttpCallInProgress(false);
        if (callingPage === `CHECK_FIAT_PARTNER_USER_STATUS`) {
          props.history.push('/checkfiatpartneruserstatus');
          return;
        }
        if (redirectToSocure) {
          props.history.push('/kycformsocure');
          return;
        }
        props.history.push('/ordersummary');
        return;
      })
      .catch((e) => {
        setHttpCallInProgress(false);
        setErrorText("Something went wrong. Please try again later.");
      });
  }

  const handleBackButton = () => {
    props.history.goBack();
  }

  const handleDocumentIdChange = (documentId) => {
    setDocumentId(documentId);
  }

  const handleDocumentTypeChange = (documentType) => {
    setDocumentType(documentType);
  }

  if (loadingData) {
    return (<Box></Box>);
  }

  return (
    <Fade in={true} timeout={800}>
      <Box className={props.classes.mainDiv}>
        <Grid container direction={'column'} >
          <Grid item>
            <FormHeader customHandleBackButton={handleBackButton} titleText="Identity Information" history={props.history} />
          </Grid>
          <Grid item className={props.classes.spaceGridItem}></Grid>
          <Grid item>
            <DocumentIdField handleDocumentIdChange={handleDocumentIdChange} selectedDocumentType={documentType} handleDocumentTypeChange={handleDocumentTypeChange} allDocumentTypes={allDocumentTypes} />
          </Grid>
          <Grid item className={props.classes.CustomFormButton}>
            <FormLabel className={props.classes.errorText}>{errorText}</FormLabel>
            {httpCallInProgress ? <CustomLinearProgressBar /> : null}
            <CustomFormButton onClick={onClick} disabled={httpCallInProgress} buttonText="Submit" />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )

}

export default withStyles(styles)(DocumentInfoForm);
