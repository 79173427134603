import { Box, OutlinedInput, InputAdornment, Typography, Grid, Select, MenuItem } from '@material-ui/core';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { useTheme } from '@material-ui/core/styles';

const styles = {
  singleLabelText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "16px",
    color: "black",
  },
  selectMenuText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "16px",
    color: "black",
  },
  labelText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "12px",
    color: "grey",
  },
};


function DocumentIdField(props) {
  const theme = useTheme();

  const handleDocumentIdChange = (event) => {
    props.handleDocumentIdChange(event.target.value);
  };

  const handleDocumentTypeChange = (event) => {
    props.handleDocumentTypeChange(event.target.value);
  };

  const getDocumentTypeField = (selectedDocumentType) => {
    const documentTypes = props.allDocumentTypes;
    if (documentTypes.length === 1) {
      return (
        <Typography variant="body1" style={styles.singleLabelText}>{documentTypes[0]?.name}</Typography>
      );
    } else {
      return (
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body1" style={styles.singleLabelText}>Document Type</Typography>
          </Grid>
          <Grid item>
            <Select
              value={selectedDocumentType}
              onChange={handleDocumentTypeChange}
              height="50%"
              color="secondary"
              style={{
                width: "100%",
                // height: "70%",
                color: theme.palette.secondary.dark,
                borderColor: theme.palette.secondary.dark
              }}
              variant="outlined"
            >
              {documentTypes.map((document) => 
                <MenuItem key={document.type} value={document.type}>
                  <Typography variant="caption" style={styles.selectMenuText}>{document.name}</Typography>
                </MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Box>
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          {getDocumentTypeField(props.selectedDocumentType)}
        </Grid>
        <Grid item>
        {props.allDocumentTypes?.length > 1 ? <Typography variant="body1" style={styles.singleLabelText}>
          Document Id</Typography> : null}
          <OutlinedInput
            id="outlined-adornment-weight"
            value={props.documentId}
            onChange={handleDocumentIdChange}
            startAdornment={
              <InputAdornment position="start">
                <FingerprintIcon />
              </InputAdornment>
            }
            type="password"
            style={{
              width: "100%",
              fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
              backgroundColor: theme.palette.secondary.light
            }}
            placeholder={props.selectedDocumentType === `SSN` ? "123456789" : ""}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            labelWidth={0}
          />
        </Grid>
        {props.allDocumentTypes?.length === 1 ? <Grid item style={{height: "80px"}}>
        </Grid> : null}
      </Grid>
    </Box>
  )
}

export default DocumentIdField;
