import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import unsupportedRegionAnimation from 'assets/animations/unsupportedregion.json';
import CustomLottie from 'widgets/CustomLottie';

const styles = theme => ({
  text: {
    fontSize: 13
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    marginTop: "20%",
  },
  mainText: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "20px"
  }
});

function UnsupportedRegion(props) {

  function getKycLottie() {
      return (<CustomLottie
        lottie={unsupportedRegionAnimation}
        loop={false}
        speed={10.0}
        width={200}
        height={200}
      />);
  }


  return (
    <Box style={mainDivStyle}>
      <Grid container direction="column" className={props.classes.mainGrid}>
        <Grid item className={props.classes.lottie}>
          {getKycLottie()}
        </Grid>
        <Grid item style={{"marginTop": "2em"}}>
          <span className={props.classes.mainText}>{"Your region is not supported yet. We are constantly trying to enable new regions around the globe, please check back in future."}</span>
        </Grid>
      </Grid>
    </Box>
  )
}


const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '1em',
}

export default withStyles(styles)(UnsupportedRegion);
