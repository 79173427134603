export const setFiatCurrencies = (payload) => {
  return {
    type: 'SET_FIAT_CURRENCIES',
    payload: payload
  }
}

export const setSelectedFiatCurrency = (payload) => {
  return {
    type: 'SET_SELECTED_FIAT_CURRENCY',
    payload: payload
  }
}

export const setFiatAmount = (payload) => {
  return {
    type: 'SET_FIAT_AMOUNT',
    payload: payload
  }
}

export const setFiatAmountForSell = (payload) => {
  return {
    type: 'SET_FIAT_AMOUNT_FOR_SELL',
    payload: payload
  }
}

export const setCryptoAmountToSell = (payload) => {
  return {
    type: 'SET_CRYPTO_AMOUNT_TO_SELL',
    payload: payload
  }
}

export const setCryptoCurrencies = (payload) => {
  return {
    type: 'SET_CRYPTO_CURRENCIES',
    payload: payload
  }
}

export const setSelectedCryptoCurrency = (payload) => {
  return {
    type: 'SET_SELECTED_CRYPTO_CURRENCY',
    payload: payload
  }
}

export const setSelectedCryptoCurrencyToSell = (payload) => {
  return {
    type: 'SET_SELECTED_CRYPTO_CURRENCY_TO_SELL',
    payload: payload
  }
}

export const setSelectedFiatCurrencyForSell = (payload) => {
  return {
    type: 'SET_SELECTED_FIAT_CURRENCY_FOR_SELL',
    payload: payload
  }
}

export const setPriceDetails = (payload) => {
  return {
    type: 'SET_BUY_CRYPTO_PRICE_DETAILS',
    payload: payload
  }
}

export const setPriceDetailsForSell = (payload) => {
  return {
    type: 'SET_SELL_CRYPTO_PRICE_DETAILS',
    payload: payload
  }
}

export const setPriceParamChanged = (payload) => {
  return {
    type: 'SET_PRICE_PARAM_CHANGED',
    payload: payload
  }
}

export const setWalletAddress = (payload) => {
  return {
    type: 'SET_WALLET_ADDRESS',
    payload: payload
  }
}

export const setWalletAddressMemo = (payload) => {
  return {
    type: 'SET_WALLET_ADDRESS_MEMO',
    payload: payload
  }
}

export const setWalletAddressMap = (payload) => {
  return {
    type: 'SET_WALLET_ADDRESS_MAP',
    payload: payload
  }
}

export const setEmailAddress = (payload) => {
  return {
    type: 'SET_EMAIL_ADDRESS',
    payload: payload
  }
}

export const setSelectedPaymentMethod = (payload) => {
  return {
    type: 'SET_SELECTED_PAYMENT_METHOD',
    payload: payload
  }
}

export const setUserProfile = (payload) => {
  return {
    type: 'SET_USER_PROFILE',
    payload: payload
  }
}

export const setTransactionType = (payload) => {
  return {
    type: 'SET_TRANSACTION_TYPE',
    payload: payload
  }
}

export const setOrderId = (payload) => {
  return {
    type: 'SET_ORDER_ID',
    payload: payload
  }
}

export const setFiatListLoaded = (payload) => {
  return {
    type: 'SET_FIAT_LIST_LOADED',
    payload: payload
  }
}

export const setCryptoListLoaded = (payload) => {
  return {
    type: 'SET_CRYPTO_LIST_LOADED',
    payload: payload
  }
}

export const setUserAddress = (payload) => {
  return {
    type: 'SET_USER_ADDRESS',
    payload: payload
  }
}

export const setMinimumAmountError = (payload) => {
  return {
    type: 'SET_MINIMUM_AMOUNT_ERROR',
    payload: payload
  }
}

export const setUserLoggedIn = (payload) => {
  return {
    type: 'SET_USER_LOGGED_IN',
    payload: payload
  }
}

export const setPartnerApiKey = (payload) => {
  return {
    type: 'SET_PARTNER_API_KEY',
    payload: payload
  }
}

export const setPrimaryColor = (payload) => {
  return {
    type: 'SET_PRIMARY_COLOR',
    payload: payload
  }
}

export const setIsDarkTheme = (payload) => {
  return {
    type: 'SET_IS_DARK_THEME',
    payload: payload
  }
}

export const setPartnerAgreementAccepted = (payload) => {
  return {
    type: 'SET_PARTNER_AGREEMENT_ACCEPTED',
    payload: payload
  }
}

export const setFirstTimePageLoaded = (payload) => {
  return {
    type: 'SET_FIRST_TIME_PAGE_LOADED',
    payload: payload
  }
}

export const setPartnerOrderId = (payload) => {
  return {
    type: 'SET_PARTNER_ORDER_ID',
    payload: payload
  }
}

export const setIsBeta = (payload) => {
  return {
    type: 'SET_IS_BETA',
    payload: payload
  }
}

export const setIsSmallDevice = (payload) => {
  return {
    type: 'SET_IS_SMALL_DEVICE',
    payload: payload
  }
}

export const setCustomWalletPartner = (payload) => {
  return {
    type: 'SET_CUSTOM_WALLET_PARTNER',
    payload: payload
  }
}

export const setCustomWalletPartnerTransactionId = (payload) => {
  return {
    type: 'SET_CUSTOM_WALLET_PARTNER_TRANSACTION_ID',
    payload: payload
  }
}

export const setApiCheckoutFlow = (payload) => {
  return {
    type: 'SET_API_CHECKOUT_FLOW',
    payload: payload
  }
}
