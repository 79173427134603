import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { blue } from "@mui/material/colors";

const { palette } = createMuiTheme();

const getMuiTheme = (primaryColor, secondaryColorMain, secondaryColorDark) => {
  return createMuiTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColorMain,
        light: '#f7f7f7',
        dark: secondaryColorDark
      },
      neutral: {
        main: palette.augmentColor({main: blue[600]})
      },
      default: {
        main: '#000'
      }
    },
  });
};

const CustomTheme = ({ children }) => {
  const isDarkTheme = useSelector(state => state.isDarkTheme) || false;
  const primaryColor = useSelector(state => state.primaryColor) || 
    (isDarkTheme ? "#ffffff" : "#000");
  const secondaryColorMain = isDarkTheme ? "#ffffff" : "#000000";
  const secondaryColorDark = isDarkTheme ? "#ffffff" : "#727070";

	return (
		<MuiThemeProvider theme={getMuiTheme(primaryColor, secondaryColorMain, secondaryColorDark)}>
      {children}
		</MuiThemeProvider>
	)
}

export default CustomTheme
