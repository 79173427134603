import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormLabel, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import KycService from 'lib/services/KycService';
import FormHeader from 'widgets/FormHeader';
import lodash from 'lodash';
import Config from '../../config';
import Request from 'lib/Request';

const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2em'
  },
  CustomFormButton: {
    marginTop: '12em'
  },
  kycDiv: {
    width: '100%',
    height: '100%'
  },
  mainDiv: {
    width: '100%',
    padding: "1em",
  },
  ".redirect-divider": {
    display: "none",
  },
  additionalDetailText: {
    textAlign: "center",
    marginTop: "4em",
  },
  labelText: {

  }
});

function KycFormSocure(props) {
  const PUBLIC_API_KEY = Config.socurePublicKey;
  const [errorText, setErrorText] = useState("");
  const userProfile = useSelector(state => state.userProfile);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const walletAddress = useSelector(state => state.walletAddress);
  const walletAddressMemo = useSelector(state => state.walletAddressMemo);
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const fiatAmount = useSelector(state => state.fiatAmount);
  const isSmallDevice = useSelector(state => state.isSmallDevice);

  useEffect(() => {
    let additionalConfig = {};
    let sdkInitialised = false;

    const config = {
      onProgress: (data) => {
        // console.log(data);
      },
      onSuccess: (data) => {
        if (additionalConfig?.redirect?.url) {
          return;
        }
        const documentUuid = data?.documentUuid;
        const body = {
          documentType: `SOCURE_WEB_SDK`,
          documentId: documentUuid,
          countryCode: selectedFiatCurrency.country_code,
          documentSessionId: window.localStorage?.getItem("DEVICE_SESSION_ID"),
        };

        KycService.submitDocumentInfo(body)
          .then(() => {
            window.Socure?.reset();
            if (lodash.get(props, 'location.state.callingPage') === `CHECK_FIAT_PARTNER_USER_STATUS`) {
              props.history.push('/checkfiatpartneruserstatus');
              return;
            } else {
              props.history.push('/createpartneruser');
              return;
            }
          })
          .catch((e) => {
            setErrorText("Something went wrong. Please try again later.");
            window.Socure?.reset();
          });
      },
      onError: (data) => {
      },
      qrCodeNeeded: true
    };


    function initialiseSocureSdk() {
      if (sdkInitialised) {
        window.Socure?.cleanup();
        window.Socure?.reset();
        window.sessionStorage.removeItem('documentVerificationToken');
        window.sessionStorage.removeItem('publicApiKey');
        window.localStorage.removeItem('devicer_id');
        console.log("Socure DocV Session cleaned!")
      }

      const inputData = {
        customerUserId: userProfile.email,
        mobileNumber: `${userProfile.isdCode}${userProfile.phoneNumber}`
      };
      window.SocureInitializer.init(PUBLIC_API_KEY)
      .then(lib => {
        lib.init(PUBLIC_API_KEY, "#socure-websdk-container", config).then(function () {
          lib.start(1, inputData, additionalConfig).then(function (response) {
            sdkInitialised = true;
          },
            function (error) {
              console.log("error1", error);
              delete inputData.mobileNumber;
              // if (error?.status === "InvalidMobileNumber") {
              lib.start(1, inputData, additionalConfig)
              .then(function (response2) {
                sdkInitialised = true;
              }, function (error2) {
                console.log("error2", error2);
                setErrorText(`Please exit the app and try again. We faced issue in initiating your KYC. ${error?.data?.message || ``}`);
              })
              // }
            });
        }, function (error) {
          setErrorText("We are facing some difficulties in initiating your KYC. Please try again later.");
        });
      });
    }

    let EVENT_ID = ``;

    async function logKycAnalytics(queryMap) {
      KycService.logKycAnalytics({
        payload: queryMap,
      })
      .then((data) => {
        if (data?.eventId) {
          EVENT_ID = data?.eventId;
        }
        const url = `${Config.apiHost}/v1/kyc/redirect?eventId=${EVENT_ID}`;
        additionalConfig = {
          redirect: {
            url,
            "method": "POST"
          },
        }

        initialiseSocureSdk();
      })
      .catch((ex) => {
        console.log(ex);
        // do nothing
      });
    }

    if (isSmallDevice) {
      let queryParamString = (window.location.search || ``).replace("?", "");

      const existingQueryParams  = queryParamString.split("&");
      const paramsToOverride = [`from`, `to`, `toNetwork`, `amt`, `paymentmethod`, `walletAddress`, `walletAddrMemo`, `walletAddr`, `kycRedirected`, `x-rl-auth-token`, `documentSessionId`, `countryCode`];

      const newQueryParamsMap = {};

      existingQueryParams.forEach((param) => {
        const paramKey = param.split(`=`)[0];
        const paramValue = param.split(`=`)[1] || ``;
        if (paramKey && paramsToOverride.indexOf(paramKey) === -1) {
          newQueryParamsMap[paramKey] = paramValue;
        }
      });


      newQueryParamsMap.from = selectedFiatCurrency?.fiat_code;
      newQueryParamsMap.to = selectedCryptoCurrency?.code;

      if (selectedCryptoCurrency?.network) {
        newQueryParamsMap.toNetwork = selectedCryptoCurrency?.network;
      }
      newQueryParamsMap.amt = fiatAmount;
      if (selectedPaymentMethod?.short_codes?.length) {
        newQueryParamsMap.paymentmethod = selectedPaymentMethod?.short_codes[0];  
      }
      newQueryParamsMap.walletAddr = walletAddress;
      if (walletAddressMemo) {
        newQueryParamsMap.walletAddrMemo = walletAddressMemo;
      }
      newQueryParamsMap.kycRedirected = true;
      newQueryParamsMap["x-rl-auth-token"] = Request.getAuthToken();
      newQueryParamsMap.documentSessionId = window.localStorage?.getItem("DEVICE_SESSION_ID");
      newQueryParamsMap.countryCode = selectedFiatCurrency.country_code;

      logKycAnalytics(newQueryParamsMap);
    } else {
      initialiseSocureSdk();
    }
  }, [PUBLIC_API_KEY, fiatAmount, isSmallDevice, props, props.history, selectedCryptoCurrency?.code, selectedCryptoCurrency?.network, selectedFiatCurrency.country_code, selectedFiatCurrency?.fiat_code, selectedPaymentMethod?.short_codes, userProfile, userProfile.isdCode, userProfile.phoneNumber, walletAddress, walletAddressMemo]);

  return (
    <Box className={props.classes.mainDiv}>
      <FormHeader titleText="Submit Document" history={props.history} hideBackButton={true} />
      {errorText ? <Box style={{ fontSize: 'small', textAlign: "center", marginTop: "2em", padding: "2em" }}>
        <FormLabel style={{ fontSize: 'small' }}>{errorText}</FormLabel>
      </Box> : null}
      {isSmallDevice ? <Grid className={props.classes.additionalDetailText}>
        <Typography variant="body" className={props.classes.labelText}>
          We need some additional details from you to approve your account. Please continue to complete your KYC.
        </Typography>
      </Grid> : null}
      <div id="socure-websdk-container" className={props.classes.kycDiv}></div>
    </Box>
  );

}

export default withStyles(styles)(KycFormSocure);
