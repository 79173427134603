import ApiConstant from '../ApiConstant';
import Request from '../Request';

class PaymentService {

  static createFiatPayment(body, headers) {
    let opts = {
      method: ApiConstant.createFiatPayment.method,
      url: ApiConstant.createFiatPayment.url,
      data: body
    }

    if (headers) {
      opts.headers = headers;
    }

    return Request.Fire(opts);
  }

  static createCryptoPayment(body, headers) {
    let opts = {
      method: ApiConstant.createCryptoPayment.method,
      url: ApiConstant.createCryptoPayment.url,
      data: body
    }

    if (headers) {
      opts.headers = headers;
    }

    return Request.Fire(opts);
  }
  
  static fulfilOrder(body, headers) {
    let opts = {
      method: ApiConstant.fulfilOrder.method,
      url: ApiConstant.fulfilOrder.url,
      data: body
    }

    if (headers) {
      opts.headers = headers;
    }

    return Request.Fire(opts);
  }

  static authorizePayment(body) {
    let opts = {
      method: ApiConstant.authorizePayment.method,
      url: ApiConstant.authorizePayment.url,
      data: body
    }

    return Request.Fire(opts);
  }

  static getPaymentMethods(orderId) {
    let opts = {
      method: ApiConstant.getPaymentMethods.method,
      url:`${ApiConstant.getPaymentMethods.url}?orderId=${orderId}`,
    }

    return Request.Fire(opts);
  }

  static createPaymentMethod(data) {
    let opts = {
      method: ApiConstant.createPaymentMethod.method,
      url:`${ApiConstant.createPaymentMethod.url}`,
      data: data,
    }

    return Request.Fire(opts);
  }

  static patchPaymentMethod(data) {
    let opts = {
      method: ApiConstant.patchPaymentMethod.method,
      url:`${ApiConstant.patchPaymentMethod.url}`,
      data: data,
    }

    return Request.Fire(opts);
  }

}

export default PaymentService;
