import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useState, useEffect } from 'react';
import ApiConstant from 'lib/ApiConstant';
import kycCheckAnimation from 'assets/animations/kyc_check.json';
import kycSuccessAnimation from 'assets/animations/success.json';
import CustomLottie from 'widgets/CustomLottie';
import CustomFormButton from 'widgets/CustomFormButton';
import UserService from 'lib/services/UserService';
import FormHeader from 'widgets/FormHeader';
import lodash from 'lodash';
import { useSelector } from 'react-redux';

const styles = theme => ({
  header: {
    display: 'flex',
    height: '70px',
    backgroundColor: '#fbfcf8',
    marginLeft: '-1.5em',
    marginRight: '-1.5em',
    marginTop: '-1em',
  },
  text: {
    fontSize: 13
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "1em",
  },
  mainText: {
    display: "flex",
    fontSize: "14px",
    color: "black"
  },
  mainTextGrid: {
    padding: "15px",
    borderRadius: "10px",
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "20px"
  }
});

function CheckFiatPartnerUserStatus(props) {
  const verifyingText = lodash.get(props, 'location.state.verifyingText', '');
  const [mainText, setMainText] = useState(verifyingText || "We are verifying the details submitted by you. Please stay on this screen.");
  const [kycStatus, setKycStatus] = useState("PENDING");
  const [mainTextGridBgColor, setMainTextGridBgColor] = useState("#fff4d5");
  const [kycFailAction, setKycFailAction] = useState("");
  const [kycFailActionButtonText, setKycFailActionButtonText] = useState("");
  const [accountClosed, setAccountClosed] = useState(false);
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);

  useEffect(() => {
    let intervalId = setInterval(function checkStatusIfTabInFocus() {
      if (document.visibilityState === `visible`) {
        UserService.getFiatPartnerUser(selectedPaymentMethod.cfpm_id)
          .then((data) => {
            if (data.status === `opened` || data.status === `ACTIVE` || data.status === `SUCCESSFULLY_CREATED`) {
              setMainText("KYC Successfully Completed. Redirecting....");
              setKycStatus("SUCCESS");
              setMainTextGridBgColor("#93ffd7");
              clearInterval(intervalId);
              setTimeout(() => {
                props.history.push('/ordersummary');
              }, 3000);
              return;
            } else if (data.response?.action === `update` && data.response?.kycRequiredAction?.info) {
              clearInterval(intervalId);
              setMainText(data.response?.kycRequiredAction.info || "Your KYC needs more information. Please complete the required actions.");
              setMainTextGridBgColor("#fff7e8");
              setKycStatus("FAILED");
              setKycFailAction(data.response?.kycRequiredAction.action);
              setKycFailActionButtonText(data.response?.kycRequiredAction.actionButtonText);
            } else if (data.status === `closed`) {
              clearInterval(intervalId);
              setMainText("Your KYC was rejected, please reach out to team@payfura.com if you wish to raise this issue");
              setMainTextGridBgColor("#ffd6bf");
              setKycStatus("FAILED");
              setAccountClosed(true);
            }
          })
          .catch((e) => {

          })
      }
    }, ApiConstant.getKycStatus.pollingIntervalInMs);

  }, [props.history]);

  function getKycLottie() {
    if (kycStatus === `SUCCESS`) {
      return (<CustomLottie
        lottie={kycSuccessAnimation}
        loop={false}
        speed={2.0}
        width={120}
        height={120}
      />);
    } else if (kycStatus === `FAILED`) {
      return (<div></div>);
    } else {
      return (<CustomLottie
        lottie={kycCheckAnimation}
        loop={true}
        width={120}
        height={120}
      />);
    }
  }

  const handleAddress = () => {
    props.history.push("/addressform", { callingPage: "CHECK_FIAT_PARTNER_USER_STATUS" });
  };
  const handleAddressProof = () => {
    props.history.push("/uploaddocumentform", {
      callingPage: "CHECK_FIAT_PARTNER_USER_STATUS",
      documentClass: `PROOF_OF_ADDRESS`,
      documentTypes: [
        {
          name: "Residence Permit",
          type: "residence_permit"
        },
        {
          name: "Utility Bill",
          type: "utility_bill"
        }
      ],
      documentHeading: `Proof of Address`
    });
  };

  const handleNameProof = () => {
    props.history.push("/uploaddocumentform", {
      callingPage: "CHECK_FIAT_PARTNER_USER_STATUS",
      documentClass: `PROOF_OF_NAME`,
      documentTypes: [
        {
          name: "Name Change Affidavit",
          type: "name_change_affidavit",
          sendAsOtherToAPI: true,
        },
        {
          name: "Marriage Certificate",
          type: "marriage_certificate",
          sendAsOtherToAPI: true,
        },
        {
          name: "Divorce Decree",
          type: "divorce_decree",
          sendAsOtherToAPI: true,
        },
        {
          name: "Other Legal Document",
          type: "legal_document",
          sendAsOtherToAPI: true,
        }
      ],
      documentHeading: `Proof of Name`
    });
  };

  const handleUserProfile = () => {
    props.history.push("/userprofile", { callingPage: "CHECK_FIAT_PARTNER_USER_STATUS" });
  };
  const handleResubmitDoc = () => {
    props.history.push("/kycformsocure", { callingPage: "CHECK_FIAT_PARTNER_USER_STATUS" });
  };
  const handleDocumentInfoSubmit = () => {
    props.history.push("/documentinfoform", { callingPage: "CHECK_FIAT_PARTNER_USER_STATUS" });
  };

  function getKycStatusAction() {
    if (kycStatus === `FAILED` && !accountClosed) {
      if (kycFailAction === `RESOLVE_NAME`) {
        return (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <CustomFormButton onClick={handleUserProfile} customButtonStyle={customButtonStyle} buttonText={kycFailActionButtonText} />
            </Grid>
              {getOrText()}
            <Grid item>
              <CustomFormButton onClick={handleNameProof} customButtonStyle={customButtonStyle} buttonText={"Upload proof of name"} />
            </Grid>
          </Grid>
        );
      } else if (kycFailAction === `RESOLVE_DOB`) {
        return (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <CustomFormButton onClick={handleUserProfile} customButtonStyle={customButtonStyle} buttonText={kycFailActionButtonText} />
            </Grid>
          </Grid>
        );
      } else if (kycFailAction === `RESOLVE_SSN`) {
        return (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <CustomFormButton onClick={handleDocumentInfoSubmit} customButtonStyle={customButtonStyle} buttonText={kycFailActionButtonText} />
            </Grid>
            {getOrText()}
            <Grid item>
              <CustomFormButton variant="text" onClick={handleUserProfile} customButtonStyle={customButtonStyle} buttonText={"Edit Name"} />
            </Grid>
          </Grid>
        );
      } else if (kycFailAction === `RESOLVE_ADDRESS`) {
        return (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <CustomFormButton onClick={handleAddress} customButtonStyle={customButtonStyle} buttonText={kycFailActionButtonText} />
            </Grid>
            {getOrText()}
            <Grid item>
              <CustomFormButton onClick={handleAddressProof} customButtonStyle={customButtonStyle} buttonText={"Upload Proof of Address"} />
            </Grid>
          </Grid>
        );
      } else if (kycFailAction === `RESOLVE_IDENTITY`) {
        return (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <CustomFormButton onClick={handleResubmitDoc} customButtonStyle={customButtonStyle} buttonText={kycFailActionButtonText} />
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography variant="caption">
              Based on the error message above, choose one the following actions to retrigger KYC check:
            </Typography>
          </Grid>
          <Grid item>
            <CustomFormButton onClick={handleUserProfile} customButtonStyle={customButtonStyle} buttonText="Edit Profile Data" />
          </Grid>
          <Grid item>
            <CustomFormButton onClick={handleAddress} customButtonStyle={customButtonStyle} buttonText="Edit Address" />
          </Grid>
          <Grid item>
              <CustomFormButton onClick={handleAddressProof} customButtonStyle={customButtonStyle} buttonText={"Upload Proof of Address"} />
            </Grid>
          <Grid item>
            <CustomFormButton onClick={handleResubmitDoc} customButtonStyle={customButtonStyle} buttonText="Resubmit Identity Documents" />
          </Grid>
          <Grid item>
              <CustomFormButton onClick={handleDocumentInfoSubmit} customButtonStyle={customButtonStyle} buttonText={"Resubmit SSN"} />
            </Grid>
        </Grid>
      );
    } else {
      return (<div></div>);
    }

    function getOrText() {
      return <Grid item style={{ justifyContent: "center", display: "flex", textAlign: "center" }}>
        <Typography variant="caption">
          OR
        </Typography>
      </Grid>;
    }
  }

  return (
    <Box style={mainDivStyle}>
      <Grid container direction="column">
        <Grid item>
          <FormHeader titleText={`KYC ${kycStatus !== `FAILED` ? kycStatus : "PENDING"}`} history={props.history} />
        </Grid>
        <Grid item style={{ height: "20px" }}></Grid>
      </Grid>
      <Grid container direction="column" className={props.classes.mainGrid}>
        <Grid item className={props.classes.lottie}>
          {getKycLottie()}
        </Grid>
        <Grid item className={props.classes.mainTextGrid} style={{ backgroundColor: mainTextGridBgColor }}>
          <span className={props.classes.mainText}>{mainText}</span>
        </Grid>
        <Grid item className={props.classes.actionButton}>
          {getKycStatusAction()}
        </Grid>
      </Grid>
    </Box>
  )
}


const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '1em',
}

const customButtonStyle = { height: "100%", borderRadius: "10px", color: "black", backgroundColor: "#cfeaf4", border: "1px solid #a3d5eb" };

export default withStyles(styles)(CheckFiatPartnerUserStatus);
