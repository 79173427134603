import React from 'react';
import FormHeader from '../../widgets/FormHeader';
import VerifyEmailField from '../../widgets/VerifyEmailField';
import { Typography, Grid, Box, FormControlLabel, FormLabel, Link } from '@material-ui/core';
import CustomFormButton from '../../widgets/CustomFormButton';
import { withStyles } from '@material-ui/styles';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AuthService from '../../lib/services/AuthService';
import util from '../../lib/util';
import CustomCheckBox from 'widgets/CustomCheckBox';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import UserService from '../../lib/services/UserService';
import { setUserAddress, setUserLoggedIn, setUserProfile } from 'actions';
import lodash from 'lodash';
import { setToken } from 'lib/Request';
import Fade from '@mui/material/Fade';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  subText: {
    fontSize: "10px",
    marginTop: "10px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  patriotActText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "16px",
    color: theme.palette.secondary.dark,
  },
  patriotActGrid: {
    border: "1px solid lightgrey",
    borderRadius: "10px",
    height: "270px",
    padding: "1em",
  },
  vpnWarningText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "14px",
    color: theme.palette.secondary.dark,
  },
  vpnWarningGrid: {
    border: "1px solid lightgrey",
    borderRadius: "10px",
    height: "100px",
    padding: "1em",
    backgroundColor: "#eaf8e5",
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  CustomFormButton: {
    marginTop: '2em'
  },
  errorTextDiv: {
    marginBottom: "0.5em",
    height: '3px'
  },
  checkBoxLabel: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
  linkText: {
    textDecoration: "underline"
  }
});

function VerifyEmailOTP(props) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [partnerAgreementChecked, setPartnerAgreementChecked] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [showPatriotAct, setShowPatriotAct] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const [errorText, setErrorText] = useState("");
  const emailAddress = useSelector(state => state.emailAddress);
  const transactionType = useSelector(state => state.transactionType);
  const callingPage = lodash.get(props, 'location.state.callingPage');
  const isAgreementAccepted = lodash.get(props, 'location.state.isAgreementAccepted');
  const isPartnerAgreementAccepted = lodash.get(props, 'location.state.isPartnerAgreementAccepted');
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const partnerOrderId = useSelector(state => state.partnerOrderId);
  const partnerApiKey = useSelector(state => state.partnerApiKey);

  const setOTP = (value) => {
    setEmailOTP(value);
  }

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };


  const handlePartnerAgreementCheckBoxChange = (event) => {
    setPartnerAgreementChecked(event.target.checked);
  };

  useEffect(() => {
    if (!isAgreementAccepted) {
      setShowAgreement(true);
    }
    if (selectedFiatCurrency.country_code === `US` && !isPartnerAgreementAccepted) {
      setShowPatriotAct(true);
    }
  }, [isAgreementAccepted, isPartnerAgreementAccepted, selectedFiatCurrency.country_code]);

  const onClick = () => {
    setErrorText("");
    if (!util.isValidVerificationCode(emailOTP)) {
      setErrorText("* Please enter a valid six digit code");
      return;
    }
    setHttpCallInProgress(true);
    const body = {
      email: emailAddress,
      otp: emailOTP,
    };
    if (partnerOrderId) {
      body.orderId = partnerOrderId;
    }

    if (partnerApiKey) {
      body.partnerApiKey = partnerApiKey;
    }

    if (checked) {
      body.agreementAccepted = true;
    }
    if (partnerAgreementChecked) {
      body.partnerAgreementAccepted = true;
    }

    AuthService.validateVerificationCode(body)
      .then((loginResult) => {
        setHttpCallInProgress(false);
        if (loginResult && loginResult.status === `success` && loginResult.response && loginResult.response.authToken) {
          try {
            setToken(loginResult.response.authToken);
          } catch (e) {
          }
        }

        UserService.getUserProfile()
          .then((data) => {
            if (data && data.status === `success` && data.response) {
              dispatch(setUserLoggedIn(true));
              if (callingPage === `MENU_PAGE`) {
                props.history.push("/", {notKycRedirected: true});
                return;
              }
              const user = data.response.user || {};
              const userData = {
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                dateOfBirth: user.dateOfBirth || "",
                isdCode: user.isdCode || "",
                phoneNumber: user.phoneNumber || "",
                email: user.email,
              };
              dispatch(setUserProfile(userData));
              if (!(user.firstName && user.lastName && user.isdCode && user.phoneNumber && user.dateOfBirth)) {
                props.history.push('/userprofile');
                return;
              }

              if (user.address && Object.keys(user.address).length > 0) {
                dispatch(setUserAddress({
                  addressLine1: user.address.addressLine1,
                  addressLine2: user.address.addressLine2,
                  state: user.address.state,
                  country: user.address.country,
                  city: user.address.city,
                  postalCode: user.address.postalCode
                }));
              } else {
                props.history.push('/addressform');
                return;
              }
              if (user.kycStatus === `VERIFIED`) {
                if (transactionType === `SELL`) {
                  props.history.push('/connectbankaccount');
                } else {
                  props.history.push('/documentinfoform');
                }
                return;
              } else {
                props.history.push('/documentinfoform');
              }
            } else {
              props.history.push('/userprofile');
            }
          })
          .catch((err) => {
            props.history.push('/userprofile');
          })

      })
      .catch((e) => {
        setHttpCallInProgress(false);
        setErrorText("Something went wrong. Please try again later.");
      });
  }

  return (
    <Fade in={true} timeout={800}>
      <Box className={props.classes.mainDiv}>
        <Grid container direction={'column'}>
          <Grid item>
            <FormHeader titleText="Verification Code" history={props.history} />
          </Grid>
          <Grid item style={{ height: "40px" }}></Grid>
          <Grid item>
            <VerifyEmailField setOTP={setOTP} />
          </Grid>
          <Grid item >
            <FormLabel className={props.classes.subText}>* We have sent a six digit code to {emailAddress}</FormLabel>
          </Grid>
          <Grid item className={props.classes.errorTextDiv}> {errorText ?
            <FormLabel className={props.classes.errorText}>{errorText}</FormLabel>
            : ''}
          </Grid>
          {showPatriotAct ?
          <Grid item className={props.classes.patriotActGrid}>
            <Typography variant="caption" style={styles.patriotActText}><b>US Patriot Act:</b> To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.</Typography>
          </Grid>

          : <React.Fragment>
              <Grid style={{"height": "170px"}} />
              <Grid item className={props.classes.vpnWarningGrid}>
                <Typography variant="body" className={props.classes.vpnWarningText}>Please make sure you are not connected to VPN or any insecure network as it may cause your KYC verification and payments to fail.</Typography>
              </Grid>
            </React.Fragment> }
          <Grid item className={props.classes.CustomFormButton}>
          {showAgreement ?
            <FormControlLabel
              control={<CustomCheckBox checked={checked} onChange={handleCheckBoxChange} />}
              label={
                <Typography className={props.classes.checkBoxLabel}>
                  I agree to Payfura's&nbsp;
                  <Link href="https://payfura.com/termsofuse.html" target="_blank" rel="noreferrer" className={props.classes.linkText}>
                    Terms of Use
                  </Link> and&nbsp;
                  <Link href="https://payfura.com/privacy.html" target="_blank" rel="noreferrer" className={props.classes.linkText}>
                    Privacy Policy
                  </Link>
                </Typography>
              }
            /> : null }
            {showAgreement ? null : <Grid style={{height: "40px"}}></Grid> }
            {httpCallInProgress ? <CustomLinearProgressBar /> : null}
            <CustomFormButton onClick={onClick} disabled={httpCallInProgress || !(!showAgreement || (checked && showAgreement))} buttonText="Submit Code" />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )

}

export default withStyles(styles)(VerifyEmailOTP);
