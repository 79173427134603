import FormHeader from '../../widgets/FormHeader';
import EmailField from './widgets/EmailField';
import CustomFormButton from '../../widgets/CustomFormButton';
import { Grid, Box, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import util from '../../lib/util';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import AuthService from '../../lib/services/AuthService';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import Fade from '@mui/material/Fade';
import lodash from 'lodash';
import { setPartnerAgreementAccepted } from 'actions';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  spaceGridItem: {
    height: "40px"
  },
  errorGridItem: {
    height: '3px'
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  CustomFormButton: {
    marginTop: '24.2em'
  }
});

function EmailForm(props) {
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const emailAddress = useSelector(state => state.emailAddress);
  const partnerApiKey = useSelector(state => state.partnerApiKey);
  const callingPage = lodash.get(props, 'location.state.callingPage');
  const partnerOrderId = useSelector(state => state.partnerOrderId);
  const walletAddress = useSelector(state => state.walletAddress);
  const customWalletPartner = useSelector(state => state.customWalletPartner);
  const dispatch = useDispatch();

  const onClick = () => {
    setErrorText("");
    if (!util.isValidEmailAddress(emailAddress)) {
      setErrorText("* Please enter a valid email address");
      return;
    }
    setHttpCallInProgress(true);

    const body = { email: emailAddress };

    if (partnerApiKey) {
      body.partnerApiKey = partnerApiKey;
    }

    AuthService.requestVerificationCode(body)
      .then((result) => {
        const locationParams = {
          callingPage,
        };
        if (result?.response?.isAgreementAccepted) {
          locationParams.isAgreementAccepted = result?.response?.isAgreementAccepted;
        }

        locationParams.isPartnerAgreementAccepted= result?.response?.isPartnerAgreementAccepted || false;
        dispatch(setPartnerAgreementAccepted(result?.response?.isPartnerAgreementAccepted || false));

        setHttpCallInProgress(false);
        props.history.push('/verifyemailotp', locationParams);
      })
      .catch((e) => {
        setHttpCallInProgress(false);
        setErrorText("Something went wrong. Please try again later.");
      });
  }

  const handleBackButton = () => {
    if (partnerOrderId && walletAddress) {
      props.history.push("/", {notKycRedirected: true});
      return;
    } else if (customWalletPartner?.toUpperCase() === `LEDGER`) {
      props.history.push("/", {notKycRedirected: true});
      return;
    } else {
      props.history.goBack();
    }
  }

  return (
    <Fade in={true} timeout={800}>
      <Box className={props.classes.mainDiv}>
        <Grid container direction={'column'} >
          <Grid item>
            <FormHeader customHandleBackButton={handleBackButton} titleText="Email Address" history={props.history} />
          </Grid>
          <Grid item className={props.classes.spaceGridItem}></Grid>
          <Grid item>
            <EmailField />
          </Grid>
          <Grid item className={props.classes.errorGridItem}>
            {errorText ?
              <FormLabel className={props.classes.errorText}>{errorText}</FormLabel>
              : ''}
          </Grid>
          <Grid item className={props.classes.CustomFormButton}>
            {httpCallInProgress ? <CustomLinearProgressBar /> : null}
            <CustomFormButton onClick={onClick} disabled={httpCallInProgress} buttonText="Request Code" />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )

}

export default withStyles(styles)(EmailForm);
