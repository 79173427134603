import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Footer from 'widgets/Footer';
import { useSelector, useDispatch } from 'react-redux';
import CustomTheme from './CustomTheme';
import { setIsSmallDevice } from 'actions';

const CustomLayout = ({ children }) => {
  const dispatch = useDispatch();
	const isSmallDevice = useMediaQuery('(max-width:480px)');
  const isDarkTheme = useSelector(state => state.isDarkTheme) || false;

  // const backgroundColor = isDarkTheme ? "#000000" : "rgb(243 235 246 / 20%)";
  const backgroundColor = isDarkTheme ? "#000000" : "#fff";
  if (isSmallDevice) {
    dispatch(setIsSmallDevice(true));
  }

  const smallDevicePaperStyle = {
    display: 'flex',
    minHeight: '37em',
    height: "100%",
    width: '100%',
    borderRadius: "5px",
    backgroundColor,
  }
  
  const largeDevicePaperStyle = {
    display: "flex",
    minHeight: '37em',
    height: "39em",
    width: '500px',
    justifyContent: 'center',
    margin: 'auto',
    borderRadius: "15px",
    backgroundColor,
    overflow: "auto"
  }

	return (
		<CustomTheme>
			<div style={(!isSmallDevice ? {paddingTop: '5%', paddingBottom: '5%', width: "100%", "height": "100vh"} : { width: "100%", "padding": "0.3em"})}>
				<Paper elevation={isSmallDevice ? 0 : 1} style={isSmallDevice ? smallDevicePaperStyle : largeDevicePaperStyle }>
					<Grid container direction="column">
						<Grid item style={{height: "95%"}}>
							{children}
						</Grid>
						<Grid item style={{height: "5%"}}>
							<Footer />
						</Grid>
					</Grid>
				</Paper>
			</div>
		</CustomTheme>
	)
}

export default CustomLayout
