import { React, useEffect } from 'react';
import CustomFormButton from '../../widgets/CustomFormButton';
import FormHeader from '../../widgets/FormHeader';
import { Grid, Box, FormLabel } from '@material-ui/core';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import OrderService from '../../lib/services/OrderService';
import { setOrderId } from '../../actions';
import { useDispatch } from 'react-redux';
import PriceDetail from '../..//widgets/PriceDetail'
import Util from '../../lib/util';
import Fade from '@mui/material/Fade';
import PaymentService from 'lib/services/PaymentService';
import lodash from 'lodash';
import UserService from 'lib/services/UserService';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  errorDiv: {
    marginBottom: "15px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    height: "30px"
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  memoText: {
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  walletText: {
    fontSize: '12px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    overflowWrap: "anywhere",
    color: theme.palette.secondary.main,
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textLabelGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  submitButton: {
    marginTop: "35px"
  },
  divider: {
    marginBottom: '10px'
  },
  errorText: {
    fontSize: "14px",
    marginTop: "6px",
    color: "#ff7402",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  priceDetailDiv: {
    height: '110px',
    marginTop: "15px",
    marginBottom: "27px"
  },
  walletDiv: {
    height: '50px',
    width: "100%",
    marginTop: "10px",
  },
  memoWalletDiv: {
    height: '20px',
    width: "100%",
  }
});

const doPostOrderProcessing = async (data, dispatch, props, setHttpCallInProgress, setErrorText, setAskForHighLimitVerification, setDisableActionButton) => {
  if (data && data.status === `success` && data.order && data.order.id) {
    dispatch(setOrderId(data.order.id));
    if (data.order.createFiatPaymentFlag) {
      const fiatPaymentBody = {
        order_id: String(data.order.id)
      };
      const cryptoPaymentBody = {
        order_id: String(data.order.id)
      };
      PaymentService.createCryptoPayment(cryptoPaymentBody)
        .then(() => PaymentService.createFiatPayment(fiatPaymentBody))
        .then((data) => {
          if (data) {
            if (data && data.requiresRedirect && data.redirectLink) {
              if (data.iframe) {
                props.history.push('/custompaymentpage', {
                  redirectLink: data.redirectLink,
                });
              } else {
                props.history.push('/checkpayment', {
                  redirectLink: data.redirectLink,
                });
                window.open(data.redirectLink, "_blank");
              }
            } else if (data.paymentPartnerToken) {
              // open stripe link in iframe
              props.history.push('/stripecreditcardpage', {
                paymentPartnerToken: data.paymentPartnerToken,
              });
            } else {
              props.history.push('/checkpayment', {
                redirectLink: data.redirectLink,
                bankDepositDetailsPresent: data.bankDepositDetailsPresent,
                bankDetails: data.bankDetails,
                numericReference: data.numericReference,
              });
            }
          }
          setHttpCallInProgress(false);
        })
        .catch((err) => {
          const errorMessage = lodash.get(err, 'response.data.message', null);
          const allowKyc = lodash.get(err, 'response.data.allowKyc', null);
          setErrorText(errorMessage || "We are facing some issue in creating an order for you. Please try again.");
          if (allowKyc) {
            setAskForHighLimitVerification(true);
            setHttpCallInProgress(false);
          } else {
            setAskForHighLimitVerification(false);
            setHttpCallInProgress(false);
            setDisableActionButton(true);
          }
        });
    } else if (data.order.initilizeBankAccountFlag) {
      setHttpCallInProgress(false);
      props.history.push('/initializebankpaymentmethod');
    } else {
      setHttpCallInProgress(false);
      props.history.push('/choosefiatpaymentmethod');
    }
  }
};

function OrderSummary(props) {
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState("");
  const [disableActionButton, setDisableActionButton] = useState(false);
  const walletAddress = useSelector(state => state.walletAddress);
  const walletAddressMemo = useSelector(state => state.walletAddressMemo);
  const priceDetails = useSelector(state => state.priceDetails);
  const fiatAmount = useSelector(state => state.fiatAmount);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const selectedCryptoCurrencyId = selectedCryptoCurrency.id;
  const selectedCryptoCurrencyCode = selectedCryptoCurrency.code;
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const partnerOrderId = useSelector(state => state.partnerOrderId);
  const partnerApiKey = useSelector(state => state.partnerApiKey);
  const disableWalletAddressChange = props.disableWalletAddressChange;
  const [askForHighLimitVerification, setAskForHighLimitVerification] = useState(false);
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const apiCheckoutFlow = useSelector(state => state.apiCheckoutFlow);
  const customWalletPartnerTransactionId = useSelector(state => state.customWalletPartnerTransactionId);
  const customWalletPartner = useSelector(state => state.customWalletPartner);

  useEffect(() => {
    if (apiCheckoutFlow) {
      handleSubmit();
    }
  }, []);

  function createOrder() {
    const body = {
      "order": {
        "cfpmId": selectedPaymentMethod.cfpm_id,
        "cryptoCurrencyId": selectedCryptoCurrencyId,
        "walletAddress": walletAddress,
        "fiatAmount": fiatAmount,
        "cryptoAmount": priceDetails?.cryptoCurrencyAmount,
        "cryptoPrice": priceDetails?.priceInFiatCurrency,
        "paymentMethodFee": priceDetails?.paymentMethodFee,
        "networkFee": priceDetails.witdrawalFeeInFiatCurrency,
        "cryptoCurrencyCode": selectedCryptoCurrencyCode
      }
    };

    if (partnerApiKey) {
      body.partnerApiKey = partnerApiKey;
    }

    if (walletAddressMemo) {
      body.order.walletAddressMemo = walletAddressMemo;
    }

    if (customWalletPartnerTransactionId) {
      body.order.partnerTransactionId = customWalletPartnerTransactionId;
    }

    OrderService.create(body)
      .then((data) => {
        doPostOrderProcessing(data, dispatch, props, setHttpCallInProgress, setErrorText, setAskForHighLimitVerification, setDisableActionButton);
      })
      .catch((err) => {
        const errorMessage = lodash.get(err, 'response.data.message', null);
        const allowKyc = lodash.get(err, 'response.data.allowKyc', null);
        setErrorText(errorMessage || "We are facing some issue in creating an order for you. Please try again.");
        if (allowKyc) {
          setAskForHighLimitVerification(true);
          setHttpCallInProgress(false);
        } else {
          setAskForHighLimitVerification(false);
          setHttpCallInProgress(false);
          setDisableActionButton(true);
        }
      });
  }

  function updateOrder() {
    const body = {
      "cryptoAmount": priceDetails.cryptoCurrencyAmount,
      "cfpmId": selectedPaymentMethod.cfpm_id,
      "cryptoPrice": priceDetails.priceInFiatCurrency,
      "paymentMethodFee": priceDetails.paymentMethodFee,
      "networkFee": priceDetails.witdrawalFeeInFiatCurrency,
      "orderId": partnerOrderId,
      "walletAddress": walletAddress,
      "walletAddressMemo": walletAddressMemo,
    };

    OrderService.updatePartnerOrder(body)
      .then((data) => {
        doPostOrderProcessing(data, dispatch, props, setHttpCallInProgress, setErrorText, setAskForHighLimitVerification, setDisableActionButton);
      })
      .catch((err) => {
        const errorMessage = lodash.get(err, 'response.data.message', null);
        const allowKyc = lodash.get(err, 'response.data.allowKyc', null);
        setErrorText(errorMessage || "We are facing some issue in creating an order for you. Please try again.");
        if (allowKyc) {
          setAskForHighLimitVerification(true);
          setHttpCallInProgress(false);
        } else {
          setAskForHighLimitVerification(false);
          setHttpCallInProgress(false);
          setDisableActionButton(true);
        }
      });
  }

  const handleSubmit = () => {
    setErrorText("");
    setHttpCallInProgress(true);
    if (askForHighLimitVerification) {
      props.history.push('/kycform');
      return;
    }
    if (selectedPaymentMethod.fiat_user_required && selectedFiatCurrency.country_code === `US`) {
      UserService.getFiatPartnerUser(selectedPaymentMethod.cfpm_id)
        .then((data) => {
          if (data.status === `opened`) {
            if (partnerOrderId) {
              updateOrder();
            } else {
              createOrder();
            }
          } else if (!data.status) {
            props.history.push("/createpartneruser");
          } else {
            props.history.push("/checkfiatpartneruserstatus", { verifyingText: `Checking your verification status. Please stay on this screen.` });
          }
        })
        .catch((e) => {
          setErrorText("We are facing some issue in verifying your indentity to create an order. Please try again.");
          setHttpCallInProgress(false);
        })
    } else if (selectedPaymentMethod.fiat_user_required) {
      UserService.getFiatPartnerUser(selectedPaymentMethod.cfpm_id)
        .then((data) => {
          if (data.status === `ACTIVE` || data.status === `SUCCESSFULLY_CREATED`) {
            if (partnerOrderId) {
              updateOrder();
            } else {
              createOrder();
            }
          } else if (!data.status) {
            props.history.push("/createpartneruser", {autoSubmitUserCreation: true});
          } else {
            props.history.push("/checkfiatpartneruserstatus", { verifyingText: `Checking your verification status. Please stay on this screen.` });
          }
        })
        .catch((e) => {
          setErrorText("We are facing some issue in verifying your indentity to create an order. Please try again.");
          setHttpCallInProgress(false);
        })
    } else {
      if (partnerOrderId) {
        updateOrder();
      } else {
        createOrder();
      }
    }
  }

  const handleBackButton = () => {
    if (partnerOrderId && disableWalletAddressChange === "true" && walletAddress) {
      props.history.push("/", {notKycRedirected: true});
      return;
    } else if (customWalletPartner?.toUpperCase() === `LEDGER`) {
      props.history.push("/", {notKycRedirected: true});
      return;
    } else if (customWalletPartner?.toUpperCase() === `STELLAR`) {
      props.history.push("/", {notKycRedirected: true});
      return;
    }
    props.history.push("/walletform");
  }

  return (
    <Fade in={true} timeout={800}>
      <Box overflow="auto" className={props.classes.mainDiv}>
        <Grid container direction="column" style={{ width: "100%" }}>
          <Grid item>
            <FormHeader titleText="Review Order" history={props.history} customHandleBackButton={handleBackButton} />
          </Grid>

          <Grid item style={{ marginTop: "50px", height: "100px" }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid container>
                <Grid item xs={6} className={props.classes.textLabelGrid}>
                  <Typography component="subtext" className={props.classes.text}>You Pay:</Typography>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                <Grid item xs={4} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{selectedFiatCurrency.fiat_symbol}{fiatAmount.toLocaleString('en')}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '1em' }}>
                <Grid item xs={6} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Get:</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{priceDetails.cryptoCurrencyAmount.toLocaleString('en')}&nbsp;
                    {selectedCryptoCurrency.code.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item className={props.classes.divider}>
            <Divider />
          </Grid>
          <Grid item className={props.classes.priceDetailDiv}>
            <PriceDetail />
          </Grid>
          <Grid item className={props.classes.divider}>
            <Divider />
          </Grid>
          <Grid item className={props.classes.walletDiv}>
            <Grid container>
              <Grid item xs={12} className={props.classes.textLabelGrid}>
                <Typography component="h4" className={props.classes.text}>{Util.capitalizeFirstLetter(selectedCryptoCurrency.name)} Wallet Address:</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={props.classes.textLabelGrid}>
                <Typography component="h4" className={props.classes.walletText}>{walletAddress}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {walletAddressMemo ? <Grid item className={props.classes.memoWalletDiv}>
            <Grid container>
              <Grid item xs={12} className={props.classes.textLabelGrid}>
                <Typography component="p" variant="subtitle2">Memo: {walletAddressMemo}</Typography>
              </Grid>
            </Grid>
          </Grid> : null}
          {!walletAddressMemo ? <Grid item style={{ height: "15px" }}></Grid> : null }
        </Grid>
        {/* <Grid item style={{ height: "30px" }}></Grid> */}
        <Box className={props.classes.submitButton}>
          <Grid container direction="column">
            <Grid item className={props.classes.errorDiv}>
              {errorText ? <FormLabel className={props.classes.errorText}>{errorText}</FormLabel> : ''}
            </Grid>
            <Grid item>
              {httpCallInProgress ? <CustomLinearProgressBar /> : null}
              <CustomFormButton onClick={handleSubmit} disabled={disableActionButton || httpCallInProgress} buttonText={askForHighLimitVerification ? "Complete KYC" : "Proceed to Payment"} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(OrderSummary);

