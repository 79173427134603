import * as React from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { withStyles } from '@material-ui/styles';
import { ListItemButton } from '@mui/material';
import { Fade, Grid } from '@material-ui/core';

const styles = theme => ({
  buttonContent: {
    display: "flex",
    fontSize: "10px",
    justifyContent: "center",
    color: theme.palette.secondary.dark,
  },
  dialog: {
    '& .MuiDialog-container': {
      alignItems: "flex-start",
      marginTop: "120px",
      overflow: "auto",
      height: "30em"
    },
    '& .MuiBackdrop-root': {
      background: "none",
    },
  },
  mainGrid: {
    background: "none",
    '& .MuiModal-root': {
      background: "none",
    },
    borderRadius: "20px",
  },
  listItemText: {
    '& .MuiTypography-body1': {
      fontSize: "16px",
      fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    }
  }
});

function SimpleDialog(props) {
  const { onClose, selectedValue, open, possibleValues, classes } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Grid container className={classes.mainGrid}>
      <Dialog onClose={handleClose} maxWidth="lg" className={classes.dialog} open={open} TransitionComponent={Fade}>
        <DialogTitle sx={{ paddingLeft: "70px", paddingRight: "70px", fontSize: "14px", fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif", }}>Choose Payment Method</DialogTitle>
        <List sx={{ pt: 0 }}>
          {possibleValues.map((item) => (
            <ListItemButton onClick={() => handleListItemClick(item.cfpm_id)} key={item.cfpm_id} selected={item.cfpm_id === selectedValue}>
              {item.image ?
                <ListItemAvatar>
                  <img alt={""} src={item.image} width="20px" height="20px" />
                </ListItemAvatar>
                : null}
              <ListItemText className={classes.listItemText} primary={item.name} />
            </ListItemButton>
          ))}
        </List>
      </Dialog>
    </Grid>
  );
}

function SelectPaymentMethod(props) {
  const possibleValues = props.possibleValues;
  const [open, setOpen] = React.useState(false);
  const heading = props.heading;
  const selectedValue = props.selectedValue;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    props.handleSelection(value);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} color="primary" variant="text" size="small" style={{ borderRadius: "8px", backgroundColor: "#eef7ff", }}>
        <span className={props.classes.buttonContent}>
          {heading}
        </span>
      </Button>
      <SimpleDialog
        possibleValues={possibleValues}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        classes={props.classes}
      />
    </div>
  );
}
export default withStyles(styles)(SelectPaymentMethod);