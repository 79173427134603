import React from 'react'
import { Box, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
	text: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	mainDiv: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	buttonClass: {
		width: "100%",
		boxShadow: "none",
		height: "50px",
		borderRadius: "10px",
    "&:disabled": {
      backgroundColor: theme.palette.secondary.light || 'red'
    }
	}
});

function CustomFormButton(props) {
	return (
		<Box className={props.classes.mainDiv} style={props.customDivStyle || {}}>
			<Button size="large" onClick={props.onClick} disabled={props.disabled} variant={props.variant || "contained"} color="primary" className={props.classes.buttonClass} style={props.customButtonStyle || {}}>
				{props.buttonText || "Continue"}
			</Button>
		</Box>
	)
}

export default withStyles(styles)(CustomFormButton);
