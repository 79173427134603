import FormHeader from '../../widgets/FormHeader';
import CustomFormButton from '../../widgets/CustomFormButton';
import { Grid, Box, FormLabel, Select, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import Fade from '@mui/material/Fade';
import KycService from 'lib/services/KycService';
import MaterialInput from '@material-ui/core/Input';
import lodash from 'lodash';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  spaceGridItem: {
    height: "40px"
  },
  errorGridItem: {
    height: '3px'
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  CustomFormButton: {
    marginTop: '13em'
  }
});

function UploadDocumentForm(props) {
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const [documentInfo, setDocumentInfo] = useState();
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const [documentType, setDocumentType] = useState();
  const [allDocumentTypes, setAllDocumentTypes] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const callingPage = lodash.get(props, 'location.state.callingPage');
  const documentTypes = lodash.get(props, 'location.state.documentTypes');
  const documentHeading = lodash.get(props, 'location.state.documentHeading');
  const documentClass = lodash.get(props, 'location.state.documentClass');
  const theme = useTheme();

  useEffect(() => {
    if (callingPage === `CHECK_FIAT_PARTNER_USER_STATUS` && documentTypes.length) {
      let validDocumentTypes = documentTypes;
      setAllDocumentTypes(validDocumentTypes);
      setDocumentType(validDocumentTypes[0]?.type);
      setLoadingData(false);

      return;
    }
  }, [callingPage, documentTypes]);

  const onClick = () => {
    setErrorText("");
    if (!documentType || !documentInfo) {
      setErrorText("* Please upload a valid document");
      return;
    }

    setHttpCallInProgress(true);
    const formData = new FormData();
    formData.append('file', documentInfo);

    const queryParams = {
      countryCode: selectedFiatCurrency.country_code,
      documentType,
      documentClass,
    }

    const document = lodash.find(allDocumentTypes, function (item) {
      return item.type === documentType;
    });

    if (document.name) {
      queryParams.documentName = document.name;
    }
    if (document?.sendAsOtherToAPI) {
      queryParams.documentType = `other`;
    }

    KycService.uploadKycDocument(formData, queryParams, {"Content-Type": "multipart/form-data"})
      .then(() => {
        setHttpCallInProgress(false);
        if (callingPage === `CHECK_FIAT_PARTNER_USER_STATUS`) {
          props.history.push('/checkfiatpartneruserstatus');
          return;
        }
      })
      .catch((e) => {
        setHttpCallInProgress(false);
        setErrorText("Something went wrong. Please try again later.");
      });
  }

  const handleBackButton = () => {
    props.history.goBack();
  }

  const handleUploadButtonClick = (documentInfo) => {
    setDocumentInfo(documentInfo.target.files && documentInfo.target.files[0]);
  }

  const handleDocumentTypeChange = (e) => {
    setDocumentType(e.target.value);
  }

  function getDocumentSelect() {
    return (
      <Select
        className={props.classes.select}
        value={documentType}
        onChange={handleDocumentTypeChange}
        height="50%"
        color="secondary"
        style={{
          width: "100%",
          height: "70%",
          color: theme.palette.secondary.dark,
          borderColor: theme.palette.secondary.dark
        }}
        variant="outlined"
      >
        {allDocumentTypes.map((documentType) =>
          <MenuItem key={documentType.type} value={documentType.type}>
            <Box className={props.classes.menuItem}>
              <span className={props.classes.documentTypeSpan}>{documentType.name}</span>
            </Box>
          </MenuItem>
        )}
      </Select>
    );
  }

  if (loadingData) {
    return (<Box></Box>);
  }

  return (
    <Fade in={true} timeout={800}>
      <Box className={props.classes.mainDiv}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <FormHeader customHandleBackButton={handleBackButton} titleText={documentHeading || `Upload Document`} history={props.history} />
          </Grid>
          <Grid item className={props.classes.spaceGridItem}></Grid>
          <Grid item>
            {getDocumentSelect()}
          </Grid>
          <Grid item>
            <MaterialInput
              type="file"
              style={{ border: "1px solid lightgrey", height: "20px", borderRadius: "5px", padding: "27px", paddingLeft: "10px", width: "100%", paddingRight: "20px", color: "grey" }}
              variant="outlined"
              disableUnderline
              onChange={handleUploadButtonClick}
            />
          </Grid>
          <Grid item className={props.classes.CustomFormButton}>
            {errorText ?
              <FormLabel className={props.classes.errorText}>{errorText}</FormLabel>
              : ''}
            {httpCallInProgress ? <CustomLinearProgressBar /> : null}
            <CustomFormButton onClick={onClick} disabled={httpCallInProgress} buttonText="Submit" />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )

}

export default withStyles(styles)(UploadDocumentForm);
