import React, { useEffect } from 'react';
import CustomFormButton from '../../widgets/CustomFormButton';
import FormHeader from '../../widgets/FormHeader';
import { Grid, Box, FormLabel, FormControlLabel } from '@material-ui/core';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Fade from '@mui/material/Fade';
import PriceDetailForSell from 'widgets/PriceDetailForSell';
import lodash from 'lodash';
import CustomCheckBox from 'widgets/CustomCheckBox';
import PaymentService from 'lib/services/PaymentService';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  errorDiv: {
    marginBottom: "0.4em",
    display: "flex",
    justifyContent: "center"
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  textPaymentMethod: {
    fontSize: '14px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  nextStepText: {
    fontSize: '14px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  warningText: {
    fontSize: '12px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  nextStepTextHeading: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  walletText: {
    fontSize: '12px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    overflowWrap: "break-word",
    wordWrap: "break-word",
    color: theme.palette.secondary.main,
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textLabelGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  submitButton: {
    marginTop: "0em"
  },
  divider: {
    marginBottom: '10px'
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  priceDetailDiv: {
    height: '80px',
    marginTop: "15px",
    marginBottom: "36px"
  },
  nextStepsDiv: {
    height: '130px',
    marginTop: "15px",
    marginBottom: "2px"
  },
  warningDiv: {
    height: '60px',
    marginTop: "15px",
    marginBottom: "2px"
  },
  checkBoxLabel: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
});

function OrderSummaryForSell(props) {
  const [errorText, setErrorText] = useState("");
  const fiatAmount = useSelector(state => state.fiatAmountForSell);
  const cryptoAmount = useSelector(state => state.cryptoAmountToSell);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrencyToSell);
  const selectedCryptoCurrencyCode = selectedCryptoCurrency.code;
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrencyForSell);
  const paymentMethodName = lodash.get(props, 'location.state.paymentMethodName');
  const paymentMethodId = lodash.get(props, 'location.state.paymentMethodId');
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {

  });

  function generateCryptoWalletAddress() {
    const body = {
      expectedFiatAmount: fiatAmount,
      expectedCryptoAmount: cryptoAmount,
      cryptoCurrencyCode: selectedCryptoCurrencyCode.toUpperCase(),
      fiatCurrencyCode: selectedFiatCurrency.fiat_code?.toUpperCase(),
      paymentMethodId: paymentMethodId,
    };

    PaymentService.generateCryptoWalletForSell(body)
      .then((data) => {
        if (data && data.success && data.paymentMethodId === paymentMethodId && selectedCryptoCurrencyCode === data.cryptoCurrencyCode) {
          props.history.push("/destinationwalletpayment", {paymentMethodName: paymentMethodName, payinWalletAddress: data.payinWalletAddress});
        } else {
          setErrorText("We are facing some issue in generating an address for you. Please try again.");
          setHttpCallInProgress(false);
        }
      })
      .catch((err) => {
        setErrorText("We are facing some issue in generating an address for you. Please try again.");
        setHttpCallInProgress(false);
      });
  }

  const handleBackButton = () => {
    props.history.push("/walletform");
  }

  const handleSubmit = () => {
    setErrorText("");
    setHttpCallInProgress(true);
    generateCryptoWalletAddress();
  }

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };


  return (
    <Fade in={true} timeout={800}>
      <Box overflow="auto" className={props.classes.mainDiv}>
        <Grid container direction="column" style={{ width: "100%" }}>
          <Grid item>
            <FormHeader hideBackButton={true} titleText="Order Summary" history={props.history} customHandleBackButton={handleBackButton} />
          </Grid>
          <Grid item style={{ marginTop: "50px", height: "140px" }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid container>
                <Grid item xs={6} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Send:</Typography>
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{cryptoAmount.toLocaleString('en')}&nbsp;
                    {selectedCryptoCurrency.code.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '1em' }}>
                <Grid item xs={6} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Get:</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{selectedFiatCurrency.fiat_symbol}{fiatAmount.toLocaleString('en')}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '1em' }}>
                <Grid item xs={4} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>Payout In:</Typography>
                </Grid>
                <Grid item xs={8} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.textPaymentMethod}>{paymentMethodName}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item className={props.classes.divider}>
            <Divider />
          </Grid>
          <Grid item className={props.classes.priceDetailDiv}>
            <PriceDetailForSell />
          </Grid>
          <Grid item className={props.classes.divider}>
            <Divider />
          </Grid>
          <Grid item className={props.classes.warningDiv}>
            <FormControlLabel
              control={<CustomCheckBox checked={checked} onChange={handleCheckBoxChange} name="privacy" />}
              label={
                <Typography className={props.classes.checkBoxLabel}>
                  Please note that final {selectedFiatCurrency.fiat_code} amount of settlement may change due to price volatility and on the amount of crypto that we receive in our wallet.
                </Typography>
              }
            />
          </Grid>
        </Grid>
        <Box className={props.classes.submitButton}>
          <Grid container direction="column">
            <Grid item className={props.classes.errorDiv}>
              {errorText ? <FormLabel className={props.classes.errorText}>{errorText}</FormLabel> : ''}
            </Grid>
            <Grid item>
              {httpCallInProgress ? <CustomLinearProgressBar /> : null}
              <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress || !checked} buttonText={`Continue`} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(OrderSummaryForSell);
