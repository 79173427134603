import { Box, Fade, FormControlLabel, FormLabel, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import kycSuccessAnimation from 'assets/animations/success.json';
import CustomLottie from 'widgets/CustomLottie';
import CustomFormButton from 'widgets/CustomFormButton';
import UserService from 'lib/services/UserService';
import FormHeader from 'widgets/FormHeader';
import CustomCheckBox from 'widgets/CustomCheckBox';
import { Link } from 'react-router-dom';
import { setPartnerAgreementAccepted } from 'actions';
import lodash from 'lodash';

const styles = theme => ({
  text: {
    fontSize: 13
  },
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  mainText: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "50px"
  },
  checkBoxLabel: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
});

function CreatePartnerUser(props) {
  const mainText = "Your documents have been successfully submitted";
  const selectedPaymentMethod = useSelector(state => state.selectedPaymentMethod);
  const isPartnerAgreementAccepted = useSelector(state => state.partnerAgreementAccepted);
  const [showPartnerAgreement, setShowPartnerAgreement] = useState(false);
  const [partnerAgreementChecked, setPartnerAgreementChecked] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [agreementContent, setAgreementContent] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [showAgreementContent, setShowAgreementContent] = useState(false);
  const autoSubmitUserCreation = lodash.get(props, 'location.state.autoSubmitUserCreation');
  const isKycRedirected = lodash.get(props, 'location.state.isKycRedirected');

  const dispatch = useDispatch();

  useEffect(() => {
    if (autoSubmitUserCreation) {
      UserService.createFiatPartnerUser({
        cfpmId: selectedPaymentMethod.cfpm_id,
      })
        .then(() => {
          props.history.push('/checkfiatpartneruserstatus');
        })
        .catch((err) => {
          const errorMessage = lodash.get(err, 'response.data.message', null);
          setLoadingData(false);
          setErrorText(errorMessage || "Your KYC is put on hold and would be reviewed manually. Please reach out to team@payfura to approve your account.");
        });
    } else {
      if (isPartnerAgreementAccepted) {
        props.history.push('/checkfiatpartneruserstatus');
        return;
      }
      UserService.createPartnerAgreement({
        cfpmId: selectedPaymentMethod.cfpm_id,
      })
        .then((data) => {
          if (data && data.status === `success` && data.response?.agreementPreview) {
            setAgreementContent(data.response?.agreementPreview);
            setShowPartnerAgreement(true);
            setLoadingData(false);
          } else {
            setLoadingData(false);
            setErrorText("We have received your documents and reviewing them. You will be notified when you account is approved.");
          }
        })
        .catch((e) => {
          setLoadingData(false);
          setErrorText("We have received your documents and reviewing them. You will be notified when you account is approved.");
        })      
    }
  }, [autoSubmitUserCreation, isPartnerAgreementAccepted, props.history, selectedPaymentMethod.cfpm_id]);

  const handlePartnerAgreementCheckBoxChange = (event) => {
    setPartnerAgreementChecked(event.target.checked);
  };

  function getKycLottie() {
    return (<CustomLottie
      lottie={kycSuccessAnimation}
      loop={false}
      speed={2.0}
      width={120}
      height={120}
    />);
  }

  const handleSubmitButton = () => {
    if (!partnerAgreementChecked) {
      setErrorText("Please read and accept the partner agreement before proceeding.");
      return;
    }
    UserService.createFiatPartnerUser({
      cfpmId: selectedPaymentMethod.cfpm_id,
    })
      .then(() => {
        dispatch(setPartnerAgreementAccepted(true));
        props.history.push('/checkfiatpartneruserstatus');
      })
      .catch((e) => {
        setErrorText("We are not able to proceed at the moment. Please try again later.");
      });
  };

  const handleBackButton = () => {
    props.history.replace("/", {notKycRedirected: true});
  };

  const handleAgreementClick = () => {
    if (!agreementContent) {
      return;
    }
    setShowAgreementContent(true);
    return;
  }

  if (loadingData) {
    return (<Box></Box>);
  }


  return (
    <Fade in={true} timeout={800}>
      <Box className={props.classes.mainDiv}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormHeader hideBackButton={isKycRedirected} customHandleBackButton={handleBackButton} titleText="Documents Submitted" history={props.history} />
          </Grid>
          {!showAgreementContent ? <><Grid item className={props.classes.lottie}>
            {getKycLottie()}
          </Grid><Grid item>
              <span className={props.classes.mainText}>{mainText}</span>
            </Grid>
            <Grid item style={{ height: "100px" }}>
            </Grid></>
            : null}
          {showAgreementContent ? <Grid item style={{ padding: "1em", overflow: "auto", height: "23em", border: "1px solid lightgrey", borderRadius: "5px", backgroundColor: "#fafafa" }}>
            <Typography
              className={props.classes.root}
              variant="body1"
              dangerouslySetInnerHTML={{
                __html:
                  agreementContent
              }}
            ></Typography>
          </Grid> : null}
          <Grid item className={props.classes.actionButton}>
            {showPartnerAgreement && !showAgreementContent ? <Grid container style={{ height: "80px" }}></Grid> : null}
            {errorText ? <Box style={{ fontSize: 'small', textAlign: "center", }}>
              <FormLabel style={{ fontSize: 'small' }}>{errorText}</FormLabel>
            </Box> : null}
            {showPartnerAgreement ? <FormControlLabel
              control={<CustomCheckBox checked={partnerAgreementChecked} onChange={handlePartnerAgreementCheckBoxChange} />}
              label={
                <Typography className={props.classes.checkBoxLabel}>
                  I agree to &nbsp;
                  <Link href="" rel="noreferrer" onClick={handleAgreementClick} className={props.classes.linkText}>
                    Prime Trust Account Agreement
                  </Link>
                </Typography>
              }
            /> : null}
            {showPartnerAgreement ? <CustomFormButton onClick={handleSubmitButton} buttonText="Continue" disabled={!partnerAgreementChecked} /> : null}
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(CreatePartnerUser);
