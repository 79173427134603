import ApiConstant from '../ApiConstant';
import Request from '../Request';

class KycService {

  static generateSdkToken(data) {
    let opts = {
      method: ApiConstant.generateSdKToken.method,
      url: ApiConstant.generateSdKToken.url,
      data,
    }

    return Request.Fire(opts);
  }
  
  static getDocumentInfoStatus(countryCode, cfpmId) {
    let opts = {
      method: ApiConstant.getDocumentInfoStatus.method,
      url: `${ApiConstant.getDocumentInfoStatus.url}?countryCode=${countryCode}&cfpmId=${cfpmId}`,
    }

    return Request.Fire(opts);
  }

  static submitDocumentInfo(body, headers) {
    let opts = {
      method: ApiConstant.submitDocumentInfo.method,
      url: ApiConstant.submitDocumentInfo.url,
      data: body
    }

    if (headers) {
      opts.headers = headers;
    }

    return Request.Fire(opts);
  }
  
  static logKycAnalytics(body) {
    let opts = {
      method: ApiConstant.logKycAnalytics.method,
      url: ApiConstant.logKycAnalytics.url,
      data: body
    }

    return Request.Fire(opts);
  }

  static uploadKycDocument(body, qs, headers) {
    let opts = {
      method: ApiConstant.uploadKycDocument.method,
      url: ApiConstant.uploadKycDocument.url,
      data: body
    }

    if (headers) {
      opts.headers = headers;
    }

    if (qs) {
      opts.params = qs;
    }

    return Request.Fire(opts);
  }

  static checkKycStatus(checkId) {
    let opts = {
      method: ApiConstant.getKycStatus.method,
      url: `${ApiConstant.getKycStatus.url}/${checkId}`,
    }

    return Request.Fire(opts);
  }

  static checkHigherLimitStatus() {
    let opts = {
      method: ApiConstant.checkHigherLimitStatus.method,
      url: `${ApiConstant.checkHigherLimitStatus.url}`,
    }

    return Request.Fire(opts);
  }

}

export default KycService;
