import { Box, OutlinedInput, InputAdornment, Avatar, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setWalletAddress, setWalletAddressMap, setWalletAddressMemo } from '../../../actions';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { useState } from 'react';
import util from '../../../lib/util';
import { useTheme } from '@material-ui/core/styles';

function WalletField(props) {
  let errorMessage = "";
  const theme = useTheme();
  const dispatch = useDispatch();
  const walletAddressMap = useSelector(state => state.walletAddressMap);
  const walletAddress = useSelector(state => state.walletAddress);
  const walletAddressMemo = useSelector(state => state.walletAddressMemo);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const defaultErrorText = `* This is not a valid ${selectedCryptoCurrency.name} wallet address`;
  const disableWalletAddressChange = props.disableWalletAddressChange === "true";
  let displayChain = selectedCryptoCurrency.display_chain || "";
  if (displayChain) {
    displayChain = displayChain.split("-")[0];
  }

  const displayChainToShow = util.capitalizeFirstLetter(displayChain);

  if (walletAddress && !util.isValidCryptoAddress(walletAddress, selectedCryptoCurrency)) {
    errorMessage = defaultErrorText;
  }

  const [errorText, setErrorText] = useState(errorMessage);

  const handleWalletAddressChange = (event) => {
    /**
     * Delete the wallet address from redux map in case it is edited by user
     */
    if (walletAddressMap && walletAddressMap[selectedCryptoCurrency.code.toLowerCase()]) {
      delete walletAddressMap[selectedCryptoCurrency.code.toLowerCase()];
      dispatch(setWalletAddressMap(walletAddressMap || {}));
    }
    if (util.isValidCryptoAddress(event.target.value, selectedCryptoCurrency)) {
      setErrorText("");
    } else {
      setErrorText(defaultErrorText);
    }
    dispatch(setWalletAddress(event.target.value));
  };

  const handleMemoChange = (event) => {
    /**
     * Delete the wallet address from redux map in case it is edited by user
     */
    if (walletAddressMap && walletAddressMap[selectedCryptoCurrency.code.toLowerCase()]) {
      delete walletAddressMap[selectedCryptoCurrency.code.toLowerCase()];
      dispatch(setWalletAddressMap(walletAddressMap || {}));
    }
    dispatch(setWalletAddressMemo(event.target.value));
  };

  const subTextStyle = {
    fontSize: "12px",
    marginTop: "10px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.main,
  };

  return (
    <Box>
      <Grid container>
          <FormHelperText id="outlined-weight-helper-text">{(selectedCryptoCurrency.code)} {displayChainToShow ? `(${displayChainToShow})`  : null} Wallet Address</FormHelperText>
          <OutlinedInput
            id="outlined-adornment-weight"
            variant="outlined"
            value={walletAddress}
            onChange={handleWalletAddressChange}
            disabled={disableWalletAddressChange}
            aria-describedby="outlined-weight-helper-text"
            style={{
              fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
              width: '100%',
              fontSize: "14px",
              backgroundColor: theme.palette.secondary.light
            }}
            multiline
            rowsMax={2}
            startAdornment={
              <InputAdornment position="start">
                <Avatar alt="Bitcoin" src={selectedCryptoCurrency.image} />
              </InputAdornment>
            }
            inputProps={{
              'aria-label': 'weight',
            }}
            labelWidth={0}
            placeholder={`Your ${selectedCryptoCurrency.code.toUpperCase()} wallet address`}
          />
          <Typography style={subTextStyle}>* Enter a {util.capitalizeFirstLetter(selectedCryptoCurrency.name)} {displayChainToShow} wallet address that you own and have access to</Typography>
          <Typography style={errorTextStyle}>{errorText}</Typography>
        {selectedCryptoCurrency.memo_required ? <Grid item style={{marginTop: "50px"}}>
          <FormHelperText id="outlined-weight-helper-text2">Memo</FormHelperText>
          <OutlinedInput
            id="outlined-adornment-weight"
            variant="outlined"
            value={walletAddressMemo}
            onChange={handleMemoChange}
            disabled={disableWalletAddressChange}
            aria-describedby="outlined-weight-helper-text2"
            style={{
              fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
              width: '100%',
              fontSize: "14px",
              backgroundColor: theme.palette.secondary.light
            }}
            multiline
            rowsMax={2}
            inputProps={{
              'aria-label': 'weight',
            }}
            labelWidth={0}
            placeholder={`Optional`}
          />
          <Typography style={subTextStyle}>* {util.capitalizeFirstLetter(selectedCryptoCurrency.name)} {selectedCryptoCurrency.network?.toUpperCase() || ""} supports MEMO. If the deposit platform requires you to fill in MEMO, please fill it in correctly. Missing or wrong filling of MEMO may cause loss of your assets.</Typography>
          <Typography style={subTextStyle}>* Other exchanges or wallets may call it tag, memo, digital ID, label and notes.</Typography>
        </Grid> : null}
      </Grid>
    </Box>
  )

}

const errorTextStyle = {
  height: "2px",
  fontSize: "12px",
  marginTop: "15px",
  color: "#F5222D",
  fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
}

export default WalletField;
