import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useState, useEffect } from 'react';
import KycService from 'lib/services/KycService';
import lodash from 'lodash';
import ApiConstant from 'lib/ApiConstant';
import kycCheckAnimation from 'assets/animations/kyc_check.json';
import kycSuccessAnimation from 'assets/animations/success.json';
import kycRejectedAnimation from 'assets/animations/failed.json';
import CustomLottie from 'widgets/CustomLottie';
import CustomFormButton from 'widgets/CustomFormButton';

const styles = theme => ({
  header: {
    display: 'flex',
    height: '70px',
    backgroundColor: '#fbfcf8',
    marginLeft: '-1.5em',
    marginRight: '-1.5em',
    marginTop: '-1em',
  },
  text: {
    fontSize: 13
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    marginTop: "20%",
  },
  mainText: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "20px"
  }
});

function CheckHigherLimitKyc(props) {
  const [mainText, setMainText] = useState("Please complete the KYC in the separate window. You will be able to continue here once your KYC is verified.");
  const [kycStatus, setKycStatus] = useState("PENDING");

  useEffect(() => {
    let intervalId = setInterval(function checkStatusIfTabInFocus() {
      if (document.visibilityState === `visible`) {
        KycService.checkHigherLimitStatus()
          .then((response) => {
            const kycStatus = lodash.get(response, 'response.kycStatus');
            if (kycStatus === `APPROVED`) {
              setMainText("KYC Successfully Completed. Redirecting you to Order Summary...");
              setKycStatus("SUCCESS");
              clearInterval(intervalId);
              setTimeout(() => {
                props.history.push('/ordersummary');
              }, 2000);
            } else if (kycStatus === `FAILED`) {
              setMainText("Your KYC could not be verified. Please try again.");
              setKycStatus("FAILED");
              clearInterval(intervalId);
              setTimeout(() => {
                props.history.push("/", {notKycRedirected: true});
              }, 5000);
            }
          })
          .catch((e) => {

          });
      }
    }, ApiConstant.checkHigherLimitStatus.pollingIntervalInMs);
  }, [props.history]);

  function getKycLottie() {
    if (kycStatus === `SUCCESS`) {
      return (<CustomLottie
        lottie={kycSuccessAnimation}
        loop={false}
        speed={2.0}
        width={120}
        height={120}
      />);
    } else if (kycStatus === `FAILED`) {
      return (<CustomLottie
        lottie={kycRejectedAnimation}
        loop={false}
        width={120}
        height={120}
      />);
    } else {
      return (<CustomLottie
        lottie={kycCheckAnimation}
        loop={true}
        width={120}
        height={120}
      />);
    }
  }

  const handleGoHome = () => {
    props.history.replace("/");
  };

  function getKycStatusAction() {
    if (kycStatus === `FAILED`) {
      return (<CustomFormButton onClick={handleGoHome} buttonText="Go Home" />);
    } else {
      return (<div></div>);
    }
  }

  return (
    <Box style={mainDivStyle}>
      <Grid container direction="column" className={props.classes.mainGrid}>
        <Grid item className={props.classes.lottie}>
          {getKycLottie()}
        </Grid>
        <Grid item>
          <span className={props.classes.mainText}>{mainText}</span>
        </Grid>
        <Grid item className={props.classes.actionButton}>
          {getKycStatusAction()}
        </Grid>
      </Grid>
    </Box>
  )
}


const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '1em',
}

export default withStyles(styles)(CheckHigherLimitKyc);
