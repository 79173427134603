import { withStyles } from '@material-ui/styles';
import { Box, Link, List, ListItem, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import OrderService from 'lib/services/OrderService';
import CustomFormButton from 'widgets/CustomFormButton';
import FormHeader from 'widgets/FormHeader';
import lodash from 'lodash';

const styles = theme => ({
  mainDiv: {
    padding: '1em',
    paddingTop: '1em',
    paddingBottom: '0em',
    width: '100%',
  },
  listItem: {
    padding: '0.2em',
  },
  listItemText: {
  },
  footer: {
    marginTop: "20px"
  },
  emptyOrderText: {
    fontSize: "18px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.main
  },
  emptyOrderTextGrid: {
    justifyContent: "center",
    marginTop: "45%",
    display: "flex"
  },
  emptyOrderButtonGrid: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    width: "30%",
    margin: "auto"
  },
  card: {
    width: "100%"
  },
  title: {
    fontSize: "14px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  cardGrid: {
  },
  subText: {
    fontSize: "small",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    textDecoration: "underline"
  },
  detailHeading: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  detailValue: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  detailValueGrid: {
    justifyContent: "flex-end",
    display: "flex"
  },
  statusTextGrid: {
    overflowWrap: "anywhere"
  },
  orderListBox: {
    height: "27em",
    fontWeight: "bold"
  },
  linkText: {
    fontSize: "14px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    textDecoration: "underline",
  },
  txnHashHeading: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  },
  supportMessage: {
    display: "flex",
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
  }
});


function OrderList(props) {

  const [orderList, setOrderList] = useState([]);
  const [orderListFetched, setOrderListFetched] = useState(false);

  useEffect(() => {

    if (orderList && orderList.length) {
      return;
    }

    OrderService.getOrderList()
      .then((data) => {
        if (data && data.length) {
          setOrderList(data);
        }
        setOrderListFetched(true);
      })
      .catch((err) => {
        setOrderListFetched(true);
      })
  }, [orderList]);

  const handleClick = () => {
    props.history.push("/", {notKycRedirected: true});
  };

  const handleBackButton = () => {
    if (lodash.get(props, 'location.state.callingPage') === `CHECK_PAYMENT`
      || lodash.get(props, 'location.state.callingPage') === `PAYMENT_3DS_PAGE`
      || lodash.get(props, 'location.state.callingPage') === `CONVERT_FIAT_TO_CRYPTO`) {
      props.history.push("/", {notKycRedirected: true});
      return;
    } else {
      props.history.goBack();
    }
  }

  return (
    <Box className={props.classes.mainDiv}>
      <Grid container direction="column">
        <Grid item>
          <FormHeader customHandleBackButton={handleBackButton} titleText={`My Orders`} history={props.history} />
        </Grid>
        <Grid item style={{ height: "20px" }}></Grid>
        <Grid item>
          <Box className={props.classes.orderListBox} overflow="auto">
            {orderList && orderList.length ?
              <Grid container direction="column">
                <Grid item>
                  <Typography className={props.classes.supportMessage}>
                    Need support with an order?
                  </Typography>
                  <Typography className={props.classes.supportMessage} color="textSecondary">
                    Please write to us at team@payfura.com along with the Order ID.
                    We would be happy to assist you.
                  </Typography>
                </Grid>
                <Grid item>
                  <List component="nav">
                    {orderList.map((order) => (
                      <ListItem key={order.id} className={props.classes.listItem}>
                        <Card className={props.classes.card} variant="elevation" style={{ border: "0.5px solid lightgrey" }}>
                          <CardContent>
                            <Grid container direction="column" className={props.classes.cardGrid}>
                              <Grid container >
                                <Grid item xs={6} sm={6}>
                                  <Typography className={props.classes.title}>
                                    <b>Order ID:</b> {order.id}
                                  </Typography>
                                  <Typography className={props.classes.detailValue} color="textSecondary">
                                    {order.createdAt}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Grid container direction="column">
                                    <Grid item className={props.classes.detailValueGrid}>
                                      <Typography className={props.classes.title} color="textSecondary">
                                        {order.status}
                                      </Typography>
                                    </Grid>
                                    <Grid item className={props.classes.detailValueGrid}>
                                      <Typography className={props.classes.detailValue} color="textSecondary">
                                        {order.crypto_currency.code}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item style={{ height: "10px" }}></Grid>
                              <Grid container>
                                <Grid item xs={6} sm={6}>
                                  <Typography className={props.classes.detailHeading}>
                                    {order.fiat_currency.code} Amount
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} className={props.classes.detailValueGrid}>
                                  <Typography className={props.classes.detailValue} color="textSecondary">
                                    {order.fiat_currency.symbol}{Number(order.fiat_currency.amount).toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {(order.crypto_currency && order.crypto_currency.amount) ?
                                <Grid container>
                                  <Grid item xs={6} sm={6}>
                                    <Typography className={props.classes.detailHeading}>
                                      {order.crypto_currency.code.toUpperCase()} Amount
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={6} className={props.classes.detailValueGrid}>
                                    <Typography className={props.classes.detailValue} color="textSecondary">
                                      {Number(order.crypto_currency.amount).toFixed(5)} {order.crypto_currency.code}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                : null}
                              {(order.fees && order.fees.network) ?
                                <Grid container>
                                  <Grid item xs={6} sm={6}>
                                    <Typography className={props.classes.detailHeading}>
                                      Processing Fee
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={6} className={props.classes.detailValueGrid}>
                                    <Typography className={props.classes.detailValue} color="textSecondary">
                                      {order.fiat_currency.symbol} {Number(order.fees.network).toFixed(2)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                : null}
                              {(order.fees && order.fees.platform) ?
                                <Grid container>
                                  <Grid item xs={6} sm={6}>
                                    <Typography className={props.classes.detailHeading}>
                                      Platform Fee
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={6} className={props.classes.detailValueGrid}>
                                    <Typography className={props.classes.detailValue} color="textSecondary">
                                      {order.fiat_currency.symbol} {Number(order.fees.platform).toFixed(2)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                : null}
                            </Grid>
                            <Grid item style={{ height: "5px" }}></Grid>
                            <Grid container>
                              <Grid item className={props.classes.statusTextGrid}>
                                <Typography className={props.classes.detailValue} color="textSecondary">
                                  {order.statusReason}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item style={{ height: "5px" }}></Grid>
                            {getOrderTransactionLink(order) ?
                              <Grid container>
                                <Grid item className={props.classes.statusTextGrid}>
                                  <Link href={order.crypto_currency.transaction.link} target="_blank" rel="noreferrer" className={props.classes.linkText} >
                                    Check transaction on blockchain
                                  </Link>
                                </Grid>
                              </Grid>
                              : (getOrderHash(order) ?
                                <Grid container>
                                  <Grid item className={props.classes.statusTextGrid}>
                                    <Typography className={props.classes.txnHashHeading}>
                                      {order.crypto_currency.code.toUpperCase()} Txn Hash
                                    </Typography>
                                    <Typography className={props.classes.detailValue} color="textSecondary">
                                      {getOrderHash(order)}
                                    </Typography>
                                  </Grid>
                                </Grid> : null)}
                          </CardContent>
                        </Card>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              :
              orderListFetched ?
                <Grid container direction="column">
                  <Grid item className={props.classes.emptyOrderTextGrid}>
                    <Typography className={props.classes.emptyOrderText} color="textSecondary">
                      You haven't placed any order yet
                    </Typography>
                  </Grid>
                  <Grid item className={props.classes.emptyOrderButtonGrid}>
                    <CustomFormButton buttonText={"Buy Now"} onClick={handleClick} />
                  </Grid>
                </Grid>
                :
                <div></div>
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )

  function getOrderHash(order) {
    return order.crypto_currency && order.crypto_currency.transaction && order.crypto_currency.transaction.hash;
  }

  function getOrderTransactionLink(order) {
    return order.crypto_currency && order.crypto_currency.transaction && order.crypto_currency.transaction.hash && order.crypto_currency.transaction.link;
  }
}

export default withStyles(styles)(OrderList);
