const isSmallDeviceReducer = (state = false, action) => {
  switch (action.type) {
      case 'SET_IS_SMALL_DEVICE':
          return action.payload;

      default:
          return state;
  }
}


export default isSmallDeviceReducer;
